import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import {
  Modal,
  Pressable,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Keyboard,
  StyleSheet,
  ScrollView,
} from "react-native";
const data02 = [
  { name: "Jwels xxx1", value: 100 },
  { name: "Jwels xxx2", value: 300 },
  { name: "Jwels xxx3", value: 100 },
  { name: "Jwels xxx4", value: 80 },
];
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const label = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
  const RADIAN = Math.PI / 180;
  // eslint-disable-next-line
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  // eslint-disable-next-line
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // eslint-disable-next-line
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill={COLORS[index]}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      style={{ fontWeight: "bold" }}
    >
      {data02[index].name} ({value})
    </text>
  );
};

export default function App(props) {
  return (
    <View style={Styles.cardView}>
      <Text style={[Styles.cardTextTitle, props.title.fontStyle]}>
        {props.title.name.toUpperCase()}{" "}
      </Text>
      <ResponsiveContainer width={480} height={280}>
        <PieChart>
          <Pie
            data={data02}
            dataKey="value"
            cx={240}
            cy={115}
            innerRadius={55}
            outerRadius={100}
            label={label}
            fill="#82ca9d"
          >
            {data02.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </View>
  );
}

const Styles = StyleSheet.create({
  cardView: {
    backgroundColor: "white",
    minWidth: 500,
    padding: 15,
    borderRadius: 5,
    margin: 5,
  },
  cardTextTitle: {
    marginBottom: 4,
    marginTop: 4,
    fontWeight: "bold",
    fontSize: "max(2.7vmin, 14px)",
  },
  cardText: {
    width: "60%",
    paddingLeft: 10,
    marginBottom: 2,
    marginTop: 2,
    fontWeight: "bold",
    fontSize: "max(2.2vmin, 12px)",
  },
  cardTextValue: {
    width: "40%",
    paddingLeft: 10,
    marginBottom: 2,
    marginTop: 2,
    fontWeight: "bold",
    fontSize: "max(2.2vmin, 12px)",
  },
});
