import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import {
  Pressable,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Alert,
  Modal,
  Dimensions,
} from "react-native";
import Styles from "./Styles";
import {
  ThousandView,
  TotalBillList,
  DeleteFromBill,
  InsertToBillList,
  ProductNameDecode,
} from "../../Functions/Functions";
import { useSelector, useDispatch } from "react-redux";

const windowHeight = Dimensions.get("window").height;
const rightContent = <Text />;

export default function ModalView(props) {
  return (
    <Modal transparent={true} visible={true} useNativeDriver={true} onRequestClose={props.close}>
      <TouchableOpacity style={Styles.modalCenterdView} activeOpacity={1} onPress={props.close}>
        <TouchableOpacity
          style={[Styles.receiptModalView, { height: windowHeight * 0.5 }]}
          activeOpacity={1}
        >
          <ItemView items={props.items} showTotal={true} />

          <View
            style={{
              flexDirection: "row",
              marginTop: 15,
              width: "90%",
              justifyContent: "center",
            }}
          >
            <Pressable
              style={[Styles.button, { width: "50%", backgroundColor: "red" }]}
              onPress={props.close}
            >
              <Text style={[Styles.buttonText, { color: "white" }]}>CLOSE</Text>
            </Pressable>
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}

export function ItemView(props) {
  const firmInfo = useSelector((state) => state.data.firmInfo);
  function billItemView() {
    return (
      props.items &&
      props.items.map((data, index) => {
        return (
          <View
            editEnabled={true}
            key={index}
            onSwipe={() => props.onSwipe(data)}
            onPress={() => props.onPress(data)}
            style={[Styles.invItemView, index % 2 == 0 && { backgroundColor: "#EFEFF5" }]}
          >
            <View style={[Styles.invItemSplit, { width: "20%" }]}>
              <Text style={[Styles.invItemText, { marginLeft: 4, textAlign: "center" }]}>
                {ProductNameDecode(data.karad)}K
              </Text>
            </View>
            <View style={[Styles.invItemSplit, { width: "20%" }]}>
              <Text style={[Styles.invItemText, { textAlign: "center" }]}>
                {ThousandView(data.qts)}
              </Text>
            </View>
            <View style={[Styles.invItemSplit, { width: "30%", borderRightWidth: 0 }]}>
              <Text style={[Styles.invItemText, { textAlign: "right", marginRight: 5 }]}>
                {ThousandView(data.weight.toFixed(2))}
              </Text>
            </View>
            <View style={[Styles.invItemSplit, { width: "30%", borderRightWidth: 0 }]}>
              <Text style={[Styles.invItemText, { textAlign: "right", marginRight: 5 }]}>
                {ThousandView(
                  (
                    ((firmInfo["24K"] * Number(data.karad)) / 24) *
                    (data.weight.toFixed(2) / 8)
                  ).toFixed(0)
                )}
              </Text>
            </View>
          </View>
        );
      })
    );
  }

  function Total(type) {
    let total = 0;

    props.items.map(
      (data) =>
        (total = total + (type == "weight" ? Number(data.weight || 0) : Number(data.qts || 0)))
    );
    return total;
  }

  function TotalValue(type) {
    let total = 0;

    props.items.map(
      (data) =>
        (total =
          total +
          Number(
            (((firmInfo["24K"] * Number(data.karad)) / 24) * (data.weight.toFixed(2) / 8)).toFixed(
              0
            )
          ))
    );
    return total;
  }

  return (
    <View style={{ flex: 1 }}>
      <View
        style={[Styles.invLabelView, props.type == "Purchase" && { backgroundColor: "#12007E" }]}
        onPress={props.PDDrawerOpen}
        editEnabled={props.editEnabled}
      >
        <View style={[Styles.invItemSplit, { width: "20%" }]}>
          <Text style={[Styles.invItemText, { color: "white", textAlign: "center" }]}>KARAT</Text>
        </View>
        <View style={[Styles.invItemSplit, { width: "20%" }]}>
          <Text style={[Styles.invItemText, { color: "white", textAlign: "center" }]}>QUTs.</Text>
        </View>
        <View style={[Styles.invItemSplit, { width: "30%" }]}>
          <Text style={[Styles.invItemText, { color: "white", textAlign: "center" }]}>
            WEIGHT(g)
          </Text>
        </View>
        <View style={[Styles.invItemSplit, { width: "30%", borderRightWidth: 0 }]}>
          <Text style={[Styles.invItemText, { color: "white", textAlign: "center" }]}>
            VALUE(Rs)
          </Text>
        </View>
      </View>
      <ScrollView style={{}}>{billItemView()}</ScrollView>
      <View style={[Styles.invLabelView]}>
        <View style={[Styles.invItemSplit, { width: "20%" }]}>
          <Text style={[Styles.invItemText, { color: "white", textAlign: "center" }]}>TOTAL</Text>
        </View>
        <View style={[Styles.invItemSplit, { width: "20%" }]}>
          <Text style={[Styles.invItemText, { color: "white", textAlign: "center" }]}>
            {ThousandView(Total())}
          </Text>
        </View>
        <View style={[Styles.invItemSplit, { width: "30%" }]}>
          <Text style={[Styles.invItemText, { color: "white", textAlign: "center" }]}>
            {ThousandView(Total("weight").toFixed(2))}g
          </Text>
        </View>

        <View style={[Styles.invItemSplit, { width: "30%", borderRightWidth: 0 }]}>
          <Text style={[Styles.invItemText, { color: "white", textAlign: "center" }]}>
            {ThousandView(TotalValue())}
          </Text>
        </View>
      </View>
    </View>
  );
}
