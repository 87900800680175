import React, { useEffect, useState, useCallback } from "react";
import {
  Modal,
  Image,
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
  Pressable,
  TextInput,
  Keyboard,
} from "react-native";
import moment from "moment";
import { useAlert } from "react-alert";

import { useSelector, useDispatch } from "react-redux";

import { ThousandView, ProductNameDecode, StackFinder } from "../../Functions/Functions";
import Styles from "./Styles";

import ItemAdder from "../../Componets/Modals/ItemAdder";

// import { ViewImage } from "../Modals/Modals";

import { GetProductDetails, SetProductDetailsDB, SetPrintJob } from "../../Firebase/Main";
import KaradDetailModal from "./ViewDetails";

const filterColor = ["green", "green", "red", "#AD75EF"];
const filterLabel = ["UNLABELED ITEMS"];

export default function App(props) {
  const [initData, setInitData] = useState(false);
  const dispatch = useDispatch();
  const root = useSelector((state) => state.data.vendorProduct); //the tree
  const userData = useSelector((state) => state.data.userData);

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [showCats, setShowCats] = useState([]);
  const [showMakes, setShowMakes] = useState([]);
  const [showSubMakes, setSubShowMakes] = useState([]);
  const [filterIndex, setFilterIndex] = useState(0);
  const firmInfo = useSelector((state) => state.data.firmInfo);
  const [filterKarad, setFilterKarad] = useState();
  const [tempKarad, setTempKarad] = useState();
  const [karadInput, setkaradInput] = useState(false);
  const [addCat, setAddCat] = useState(false);
  const [path, setPath] = useState([]);
  const alert = useAlert();

  const identifiedkarads = useSelector((state) => state.data.firmInfo.karads) || [];

  const [viewKaradDetails, setKaradDetails] = useState(false);

  //const [initData, setInitialData] = useState(false);

  const [showCatGram, setShowCatGram] = useState("");
  const [showItemGram, setShowItemGrame] = useState("");

  const currentMoment = moment();

  const relatieveDate = (date) => {
    const timeStampMoment = moment(date, "YYYY-MM-DD HH:mm:ss_SSS");
    if (currentMoment.subtract(2, "days") < timeStampMoment) return timeStampMoment.calendar();
    return timeStampMoment.format("YYYY-MM-DD");
  };

  function countChild(obj, type, karad) {
    let tot = 0;

    if (type == "G") tot = tot + Number(obj.weight);
    else if (true) tot = tot + Number(obj.quantity);

    return tot;
  }

  function itemFilter(item, value, karad) {
    if (item == "AddedOn") return false;
    else if (filterIndex == 3) {
      let itemDetails = item.split("@");
      return itemDetails[3] >= date && itemDetails[4] == userData.userName;
    } else if (karad || filterKarad) {
      let itemDetails = item.split("@");
      let itemKarad =
        itemDetails.length == 3 ? ProductNameDecode(itemDetails[itemDetails.length - 1]) : "";
      if (
        Number(itemKarad) == Number(ProductNameDecode(karad || filterKarad)) &&
        (((Number(value) || relatieveDate(value) == "Invalid date") && filterIndex < 2) ||
          (value >= date && filterIndex != 1))
      )
        return true;

      return false;
    } else if (
      ((Number(value) || relatieveDate(value) == "Invalid date") && filterIndex < 2) ||
      (value >= date && filterIndex != 1)
    )
      return true;
    return false;
  }

  function countChildFromMake(obj, type, karad) {
    let tot = 0;
    Object.values(obj).map((value) => {
      if (typeof value == "object") tot = tot + countChild(value, type, karad);
    });
    return tot;
  }

  function countChildFromCat(obj, type, karad) {
    let tot = 0;
    Object.values(obj).map((value) => {
      if (typeof value == "object") tot = tot + countChildFromMake(value, type, karad);
    });
    return tot;
  }

  function CatToGram(obj) {
    let temp = {};
    Object.keys(obj).map((cat) => {
      Object.keys(obj[cat]).map((gram) => {
        if ((filterIndex == 1 && Number(obj[cat][gram])) || filterIndex == 0) {
          let aprox = Math.ceil(Number(ProductNameDecode(gram.split("@")[0])));
          let ceilPoint = aprox + (aprox % 2);

          let key = ("0" + Number(ceilPoint - 2)).slice(-2) + "-" + ("0" + ceilPoint).slice(-2);

          temp[key] = {
            ...temp[key],
            [gram]: cat,
          };
        }
      });
    });
    return Object.keys(temp)
      .sort()
      .reduce((obj, key) => {
        obj[key] = temp[key];
        return obj;
      }, {});
  }

  function ItemsToGram(obj) {
    let temp2 = {};
    Object.keys(obj).map((gram) => {
      if ((filterIndex == 1 && Number(obj[gram])) || filterIndex == 0) {
        let aprox = Math.ceil(Number(ProductNameDecode(gram.split("@")[0])));
        let ceilPoint = aprox + (aprox % 2);

        let key = ("0" + Number(ceilPoint - 2)).slice(-2) + "-" + ("0" + ceilPoint).slice(-2);

        temp2[key] = (temp2[key] || 0) + 1;
      }
    });

    return Object.keys(temp2)
      .sort()
      .reduce((obj, key) => {
        obj[key] = temp2[key];
        return obj;
      }, {});
  }

  function loadProductData(id) {
    GetProductDetails({ id: id }).then((details) => {
      setInitData({ ...details, todayPricePerG: firmInfo["22K"] });
    });
  }

  // const onDateChange = (event, selectedDate) => {
  //   if (event.type == "dismissed") return setShowDataPick(!showDataPick);
  //   const currentDate =
  //     selectedDate.getFullYear() +
  //       "-" +
  //       ("0" + Number(selectedDate.getMonth() + 1).toLocaleString()).slice(-2) +
  //       "-" +
  //       ("0" + selectedDate.getDate().toLocaleString()).slice(-2) || date;

  //   setDate(currentDate);
  // };

  function itemShow(path, item, value, index) {
    let tempDate = relatieveDate(value);
    let items = ProductNameDecode(item).split("@");

    let isIn = Number(value) || tempDate == "Invalid date";
    return (
      <TouchableOpacity
        style={[
          Styles.treeItem,
          {
            backgroundColor:
              items[3] >= date && items[4] == userData.userName ? "#D7BAFA" : "white",
          },
        ]}
        onLongPress={() => loadProductData(path.join("_") + "_" + item)}
        key={index}
      >
        <Text style={[Styles.treeItemText, { width: isIn ? "70%" : "40%" }]}>
          {items[0] + (items.length > 1 ? "g - " + items[1] : " g")}{" "}
          {items[3] >= date && items[4] == userData.userName && "   : Newly Added"}
        </Text>

        {isIn ? (
          <Text style={[Styles.treeItemText, { color: "green" }]}>{value}</Text>
        ) : (
          <Text
            style={[
              {
                width: "50%",
                color: "red",
                fontSize: 16,
                paddingLeft: 2,
                fontWeight: "bold",
              },
            ]}
            numberOfLines={2}
          >
            OUT {tempDate.toLocaleUpperCase()}
          </Text>
        )}
      </TouchableOpacity>
    );
  }

  function treeToPrint() {
    let printTree = {
      type: filterLabel[filterIndex],
    };

    Object.keys(root).map((cat) => {
      if (cat != "AddedOn") {
        let tempCat = {
          qts: countChildFromCat(root[cat]),
          weight: countChildFromCat(root[cat], "G").toFixed(2),
        };

        if (Number(tempCat.weight)) {
          //let temm
          Object.keys(root[cat]).map((make) => {
            if (make != "AddedOn") {
              let tempMake = {
                qts: countChildFromMake(root[cat][make]),
                weight: countChildFromMake(root[cat][make], "G").toFixed(2),
              };
              if (Number(tempMake.weight)) {
                Object.keys(root[cat][make]).map((subMake) => {
                  if (subMake != "AddedOn") {
                    let tempSubMake = {
                      qts: countChild(root[cat][make][subMake]),
                      weight: countChild(root[cat][make][subMake], "G").toFixed(2),
                    };
                    if (Number(tempSubMake.weight)) tempMake[subMake] = tempSubMake;
                  }
                });
                tempCat[make] = tempMake;
              }
            }
          });

          printTree[cat] = tempCat;
        }
      }
    });

    return printTree;
  }

  function printStock() {
    let printData = treeToPrint();
    Object.keys(printData).length > 0
      ? SetPrintJob({
          printerType: "stockPrinter",
          printReceipt: { ...printData, firmCode: firmInfo.code },
          typeUpdate: false,
        }).then(() => alert.success("Printing Job has submitted"))
      : alert.info("There is no item to print");
  }
  //console.log(treeToPrint());
  return (
    <>
      {/* {initData && initData.image && (
        <ViewImage image={initData.image} close={() => setInitData(false)} />
      )} */}

      {addCat && (
        <ItemAdder
          type={addCat}
          path={path}
          close={() => {
            setAddCat(false);
            setPath([]);
          }}
          itemAdded={(qr) => dispatch({ type: "ADDEDITEM_PUSH", payLoad: qr })}
          //initialData={itemData}
          //items={parents}
        />
      )}

      <View style={Styles.viewTag}>
        <View
          style={{
            flexDirection: "row",
            // alignItems: "center",
            paddingBottom: 5,
            paddingTop: 5,
            paddingLeft: 150,
            // justifyContent: "center",
          }}
        >
          {filterLabel.map((data, index) => (
            <TouchableOpacity
              style={[
                Styles.buttonView,
                { backgroundColor: "white", width: "30%", marginLeft: "1%" },
                {
                  backgroundColor: index == filterIndex ? filterColor[filterIndex] : "white",
                },
                index != filterIndex && {
                  borderColor: "white",
                  borderWidth: 1,
                },
              ]}
              onPress={() => setFilterIndex(index)}
            >
              <Text
                style={[Styles.buttonText, { color: index == filterIndex ? "white" : "black" }]}
              >
                {data}
              </Text>
            </TouchableOpacity>
          ))}

          <TouchableOpacity
            style={[Styles.buttonView, { backgroundColor: "red", width: "10%", marginLeft: "1%" }]}
            onPress={() => {
              dispatch({ type: "ADDEDITEM_CLEAN" });
              setFilterIndex(1);
            }}
          >
            <Text style={[Styles.buttonText, { color: "white" }]}>{"CLEAN"}</Text>
          </TouchableOpacity>
        </View>
      </View>

      {viewKaradDetails && (
        <KaradDetailModal
          items={identifiedkarads.map((karad) =>
            Object.assign({
              karad: karad,
              qts:
                viewKaradDetails.length == 1
                  ? countChildFromCat(StackFinder(root, viewKaradDetails), "Q", karad)
                  : viewKaradDetails.length == 2
                  ? countChildFromMake(StackFinder(root, viewKaradDetails), "Q", karad)
                  : countChild(StackFinder(root, viewKaradDetails), "Q", karad),
              weight:
                viewKaradDetails.length == 1
                  ? countChildFromCat(StackFinder(root, viewKaradDetails), "G", karad)
                  : viewKaradDetails.length == 2
                  ? countChildFromMake(StackFinder(root, viewKaradDetails), "G", karad)
                  : countChild(StackFinder(root, viewKaradDetails), "G", karad),
            })
          )}
          close={() => setKaradDetails(false)}
        />
      )}

      <ScrollView style={{ width: "100%" }} showsVerticalScrollIndicator={false}>
        <View
          style={{
            width: "95%",
            flexDirection: "column",
            maxWidth: "1200px",
            alignSelf: "center",
            margin: 10,
            zIndex: -1,
          }}
        >
          {root &&
            Object.keys(root).map(
              (cat, index) =>
                cat != "Goldsmith" && (
                  <View key={index} style={{ alignItems: "flex-start", width: "100%" }}>
                    <TouchableOpacity
                      onPress={() => {
                        let index = showCats.indexOf(cat);
                        if (index > -1)
                          setShowCats([...showCats.slice(0, index), ...showCats.slice(index + 1)]);
                        else setShowCats([...showCats, cat]);
                      }}
                      style={[Styles.treeHeadingMake]}
                      onLongPress={() => setKaradDetails([cat])}
                    >
                      <Text style={Styles.treeHeadingMakeText}>
                        {ProductNameDecode(cat).toUpperCase()}
                      </Text>

                      <Text
                        style={[
                          Styles.treeHeadingItemText,
                          {
                            borderLeftWidth: 1,
                            borderColor: "white",
                            paddingLeft: 10,
                            marginLeft: 20,
                            minWidth: 40,
                            // fontSize: 14,
                          },
                        ]}
                      >
                        {ThousandView(countChildFromCat(root[cat]))}
                      </Text>
                      <Text
                        style={[
                          Styles.treeHeadingItemText,
                          {
                            borderLeftWidth: 1,
                            borderColor: "white",
                            paddingLeft: 10,
                            // fontSize: 14,
                          },
                        ]}
                      >
                        W: {ThousandView(countChildFromCat(root[cat], "G").toFixed(2))}g
                      </Text>
                    </TouchableOpacity>

                    {Object.keys(root[cat]).map((make, index) => {
                      return (
                        showCats.includes(cat) &&
                        cat != "AddedOn" && (
                          <View key={index}>
                            {make.length > 0 && make != "AddedOn" && (
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <TouchableOpacity
                                  onPress={() => {
                                    let index = showMakes.indexOf(make);
                                    if (index > -1)
                                      setShowMakes([
                                        ...showMakes.slice(0, index),
                                        ...showMakes.slice(index + 1),
                                      ]);
                                    else setShowMakes([...showMakes, make]);
                                  }}
                                  style={[
                                    Styles.treeHeadingItem,
                                    {
                                      paddingTop: 5,
                                      paddingBottom: 5,
                                      backgroundColor: "#0000AF",
                                      flexDirection: "row",
                                      marginLeft: 50,
                                      // width: "60%",
                                    },
                                  ]}
                                  onLongPress={() => setKaradDetails([cat, make])}
                                >
                                  <Text style={[Styles.treeMakeText]} numberOfLines={2}>
                                    {ProductNameDecode(make).toUpperCase()}
                                  </Text>
                                  {/* <TouchableOpacity
                                    onPress={() => {
                                      setAddCat("CAT");
                                      setPath([cat, make]);
                                    }}
                                  >
                                    <Text style={[Styles.treeHeadingItemText, ,]}>+ CAT</Text>
                                  </TouchableOpacity> */}
                                  <Text style={[Styles.treeHeadingItemText, ,]}>
                                    {countChildFromMake(root[cat][make])}
                                  </Text>
                                  <Text
                                    style={[
                                      Styles.treeHeadingItemText,
                                      {
                                        borderLeftWidth: 1,
                                        borderColor: "white",
                                        paddingLeft: 10,

                                        paddingRight: 10,
                                      },
                                    ]}
                                  >
                                    W: {countChildFromMake(root[cat][make], "G").toFixed(2)}g
                                  </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  onPress={() => {
                                    setShowCatGram(
                                      showCatGram == cat + "_" + make ? false : cat + "_" + make
                                    );
                                    setShowMakes([make]);
                                  }}
                                >
                                  <Text
                                    style={[
                                      Styles.treeHeadingItemText,
                                      {
                                        borderWidth: 1,
                                        borderColor: "#0000AF",
                                        color: "#0000AF",
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        borderRadius: 4,
                                      },
                                    ]}
                                  >
                                    {showCatGram == cat + "_" + make ? "Gram" : "Cat"}
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            )}
                            {Object.keys(
                              showCatGram == cat + "_" + make
                                ? CatToGram(root[cat][make])
                                : root[cat][make]
                            ).map((subMake, index) => {
                              //CatToGram(root[cat][make]);

                              let childObj =
                                showCatGram == cat + "_" + make
                                  ? CatToGram(root[cat][make])
                                  : root[cat][make];

                              return (
                                showMakes.includes(make) &&
                                subMake != "AddedOn" && (
                                  <View key={index}>
                                    {make.length > 0 && (
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <View
                                          onPress={() => {
                                            let index = showSubMakes.indexOf(subMake);
                                            if (index > -1)
                                              setSubShowMakes([
                                                ...showSubMakes.slice(0, index),
                                                ...showSubMakes.slice(index + 1),
                                              ]);
                                            else setSubShowMakes([...showSubMakes, subMake]);
                                          }}
                                          onLongPress={() => setKaradDetails([cat, make, subMake])}
                                          style={[
                                            Styles.treeHeadingItem,
                                            {
                                              paddingTop: 3,
                                              paddingBottom: 3,
                                              marginLeft: 100,
                                              // width: "60%",
                                            },
                                          ]}
                                        >
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Text
                                              style={[Styles.treeMakeTextSub]}
                                              numberOfLines={2}
                                            >
                                              {ProductNameDecode(subMake).toUpperCase()}
                                            </Text>
                                            {/* <View
                                              onPress={() => {
                                                setAddCat("ITEM");
                                                setPath([cat, make, subMake]);
                                              }}
                                            >
                                              <Text
                                                style={[
                                                  Styles.treeHeadingItemText,
                                                  {
                                                    borderLeftWidth: 1,
                                                    borderColor: "white",

                                                    minWidth: 25,
                                                    textAlign: "right",
                                                  },
                                                ]}
                                              >
                                                + ITEM
                                              </Text>
                                            </View> */}
                                            <Text
                                              style={[
                                                Styles.treeHeadingItemText,
                                                {
                                                  borderLeftWidth: 1,
                                                  borderColor: "white",

                                                  minWidth: 25,
                                                  textAlign: "right",
                                                },
                                              ]}
                                            >
                                              {countChild(childObj[subMake])}
                                            </Text>
                                            <Text
                                              style={[
                                                Styles.treeHeadingItemText,
                                                {
                                                  borderLeftWidth: 1,
                                                  borderColor: "white",
                                                  paddingLeft: 10,

                                                  paddingRight: 10,
                                                  minWidth: 80,
                                                },
                                              ]}
                                            >
                                              {ThousandView(
                                                countChild(childObj[subMake], "G").toFixed(2)
                                              )}
                                              g
                                            </Text>
                                          </View>
                                        </View>

                                        {/* {showCatGram != cat + "_" + make && (
                                      <TouchableOpacity
                                        onPress={() => {
                                          setShowItemGrame(
                                            showItemGram ==
                                              cat + "_" + make + "_" + subMake
                                              ? false
                                              : cat + "_" + make + "_" + subMake
                                          );
                                          //setShowMakes([make]);
                                        }}
                                      >
                                        <Text
                                          style={[
                                            Styles.treeHeadingItemText,
                                            {
                                              borderWidth: 1,
                                              borderColor: "#2196F3",
                                              color: "#2196F3",
                                              paddingLeft: 4,
                                              paddingRight: 4,
                                              borderRadius: 4,
                                            },
                                          ]}
                                        >
                                          {showItemGram ==
                                          cat + "_" + make + "_" + subMake
                                            ? "Gram"
                                            : "Cat"}
                                        </Text>
                                      </TouchableOpacity>
                                    )} */}
                                      </View>
                                    )}
                                    <ScrollView>
                                      {Object.keys(
                                        showItemGram == cat + "_" + make + "_" + subMake
                                          ? ItemsToGram(root[cat][make][subMake])
                                          : childObj[subMake]
                                      ).map((item, index) => {
                                        let value =
                                          showItemGram == cat + "_" + make + "_" + subMake
                                            ? ItemsToGram(root[cat][make][subMake])[item]
                                            : childObj[subMake][item];

                                        return (
                                          itemFilter(item, value) && // (((Number(value) || relatieveDate(value) == "Invalid date") && filterIndex < 2) ||(value >= date && filterIndex != 1))
                                          showSubMakes.includes(subMake) &&
                                          itemShow([cat, make, subMake], item, value, index)
                                        );
                                      })}
                                    </ScrollView>
                                  </View>
                                )
                              );
                            })}
                          </View>
                        )
                      );
                    })}
                  </View>
                )
            )}
        </View>
      </ScrollView>

      {/* <StatusBar style="auto" /> */}
      {karadInput ? (
        <View
          style={[
            Styles.PDbutton,
            {
              backgroundColor: "#0000AF",
              width: "100%",
              position: "relative",
              bottom: 0,
            },
          ]}
        >
          <View style={{ width: "70%" }}>
            <TextInput
              autoFocus={true}
              value={tempKarad}
              onChangeText={(e) => {
                if (Number(e) >= 0) setTempKarad(e);
                if (Number(e) == 0) setFilterKarad();
              }}
              paddingLeft={12}
              placeholderTextColor="#706767"
              style={Styles.textInput}
              placeholder="Set View Karat"
              keyboardType="numeric"
            />
          </View>

          <TouchableOpacity
            style={[Styles.button, Styles.pinChildAddButton]}
            onPress={() => {
              setkaradInput(false);
              setFilterKarad(tempKarad);
            }}
          >
            <Text style={[Styles.buttonText]}>SET</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <TouchableOpacity
          style={[
            {
              minWidth: 50,
              height: 50,
              borderRadius: 25,
              backgroundColor: filterKarad ? "green" : "#B90505", //"#FFAC30",
              //alignSelf: "flex-end",
              marginLeft: 10,
              position: "absolute",

              bottom: 0,
              right: 10,
            },
          ]}
          onPress={() =>
            window.confirm("Do you wish to print the stock?") ? printStock() : console.log("cancel")
          } //setkaradInput(true)
        >
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={[
                Styles.treeHeadingCatText,
                {
                  marginLeft: 20,
                  color: "white",
                  marginRight: 20,
                  textAlign: "center",
                },
              ]}
            >
              PRINT
              {/* {filterKarad ? filterKarad : "All"} K */}
            </Text>
          </View>
        </TouchableOpacity>
      )}
    </>
  );
}
