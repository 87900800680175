import React from "react";
import { Text, View, StyleSheet } from "react-native";

export default function App(props) {
  return (
    <>
      <View style={[Styles.cardView, props.style]}>
        <Text style={[Styles.cardTextTitle, props.title.fontStyle]}>
          {props.title.name.toUpperCase()}
        </Text>
        {props.items &&
          props.items.map((data, index) => {
            return (
              <View key={index} style={{ flexDirection: "row" }}>
                <Text style={[Styles.cardText, data.fontStyle]}>{data.name}</Text>
                <Text style={[Styles.cardTextValue, data.fontStyle]}>{data.value}</Text>
              </View>
            );
          })}
      </View>
    </>
  );
}

const Styles = StyleSheet.create({
  cardView: {
    backgroundColor: "white",
    minWidth: 500,
    padding: 15,
    borderRadius: 5,
    margin: 5,
    
  },
  cardTextTitle: {
    marginBottom: 4,
    marginLeft: 40,
    marginTop: 4,
    fontWeight: "bold",
    fontSize: "max(2.7vmin, 14px)",
  },
  cardText: {
    width: "60%",
    paddingLeft: 10,
    marginBottom: 2,
    marginTop: 2,
    fontWeight: "bold",
    fontSize: "max(2.2vmin, 12px)",
  },
  cardTextValue: {
    width: "40%",
    paddingLeft: 10,
    marginBottom: 2,
    marginTop: 2,
    fontWeight: "bold",
    fontSize: "max(2.2vmin, 12px)",
  },
});
