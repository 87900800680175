import moment from "moment";
import {
  StackFinder,
  SearchInList,
  ProductNameEncode,
  LebelingFromID,
  ProductNameDecode,
  RandomNumberInRange,
} from "../Functions/Functions";

import { db, dbTree, dbStorage } from "./Config";
import Firebase from "firebase";
import { SetPrintJob } from "./Print";
import { bulkInventoryReducer } from "./Inventory";

var userDataGlobal;
var root = {};
var productSearchList = [];
var firmInfo;

var ProductTreeRef;
var ProductSerachRef;
var ProductRecordRef;
var FirmInfoRef;

const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export function UpdaeVariable_Products({ temRoot, userData, tempProductSearch, tempFirmInfo }) {
  userDataGlobal = userData;
  root = temRoot;
  productSearchList = Object.keys(tempProductSearch);
  firmInfo = tempFirmInfo;
  ProductTreeRef = dbTree.ref(`/${userData.firmID}/ProductTree/Root`);
  ProductSerachRef = db.collection(`/${userData.firmID}/Products/ProductTree/`).doc("SearchList");
  ProductRecordRef = db.collection(`/${userData.firmID}/Products/ProductTree/`);
  FirmInfoRef = db.collection(`/${userData.firmID}/`);
}

export async function AddtoProductTree(data) {
  const pathList = data.path.split("/");
  const addOnPath = pathList.slice(0, -1).join("/") + "/" + "AddedOn";

  const qr =
    data.qr ||
    (firmInfo.code || "") +
      ProductNameEncode(pathList[1][0] + pathList[2][0]).toUpperCase() +
      ("00000" + firmInfo.productSerial).slice(-5);
  const idWithQr = pathList.join("_") + "#" + qr;
  const addingItem = pathList[pathList.length - 1] + "g - " + qr;

  ProductTreeRef.child(addOnPath).remove();

  switch (data.type) {
    case "ITEM":
      FirmInfoRef.doc("GeneralInfo").set(
        {
          productSerial: firmInfo.productSerial + 1,
          ...((firmInfo.karads || []).includes(ProductNameEncode(data.karad))
            ? {}
            : { karads: [...(firmInfo.karads || []), ProductNameEncode(data.karad)] }),
        },
        { merge: true }
      );
      const id =
        pathList.join("_") +
        "@" +
        qr +
        "@" +
        ProductNameEncode(data.karad) +
        "@" +
        moment().format("YYYY-MM-DD") +
        "@" +
        userDataGlobal.userName +
        (data.size ? "@S" + ProductNameEncode(data.size) : "");

      ProductTreeRef.child(
        data.path +
          "@" +
          qr +
          "@" +
          ProductNameEncode(data.karad) +
          "@" +
          moment().format("YYYY-MM-DD") +
          "@" +
          userDataGlobal.userName +
          (data.size ? "@S" + ProductNameEncode(data.size) : "")
      ).set(data.quantity);

      ProductSerachRef.set(
        {
          [id]: {
            item: addingItem.toUpperCase(),
            id: id,
          },
        },
        { merge: true }
      );

      ProductRecordRef.doc(id).set(
        {
          id: id,
          idWithQr: idWithQr,
          ...data,
          qr: qr,
          productAdderID: userDataGlobal.uid,
          timeStamp: moment().format("YYYY-MM-DD HH:mm:ss_SSS"),
          status: "Active",
          item: addingItem,
          productAdderName: userDataGlobal.userName,
        },
        { merge: true }
      );

      firmInfo.bulkHandler &&
        bulkInventoryReducer({
          id: pathList.slice(0, pathList.length - 1).join("_"),
          increment: false,
          quantity: 1,
          weight: data.weight,
        });

      data.printEnabled &&
        SetPrintJob({
          printReceipt: {
            id: id,
            idWithQr: idWithQr,
            ...data,
            qr: qr,
            productAdderID: userDataGlobal.uid,
            status: "Active",
            item: addingItem,
            label: LebelingFromID(id),
            type: "label",
          },
          printerType: "labelPrinter",
        });

      return qr;

    case "CAT":
      if (!data.isAvailableOnTree) {
        ProductTreeRef.child(data.path).update({
          AddedOn: moment().format("YYYY-MM-DD HH:mm:ss_SSS"),
        });
      }
      return true;
  }
}

export async function GetProductDetails({ id }) {
  const productdata = await db
    .collection(`/${userDataGlobal.firmID}/Products/ProductTree/`)
    .doc(id)
    .get();
  if (productdata.data()) return productdata.data();
  else return false;
}

export async function SetProductDetailsDB({ data }) {
  const productdata = db.collection(`/${userDataGlobal.firmID}/Products/ProductTree/`);
  productdata.doc(data.id).set(data, { merge: true });
}

export async function DeleteFromProductTree({ id }) {
  let pathList = id.split("_");
  ProductSerachRef.set(
    {
      [SearchInList(id, productSearchList)[0][0]]: Firebase.firestore.FieldValue.delete(), //search the id with barcode and the delete
    },
    { merge: true }
  );

  ProductRecordRef.doc(id).set({ status: "Trash" }, { merge: true });

  firmInfo.bulkHandler &&
    bulkInventoryReducer({
      id: pathList.slice(0, pathList.length - 1).join("_"),
      increment: true,
      quantity: 1,
      weight: ProductNameDecode(pathList[pathList.length - 1].split("@")[0]),
    });

  ProductTreeRef.child(id.split("_").join("/")).set(
    moment().format("YYYY-MM-DD HH:mm:ss_SSS") + "@Deleted"
  );
}

export async function UploadProductPics(data, type) {
  let reference = dbStorage.ref().child(`/${userDataGlobal.firmID}/${data.dirWithoutFirmID}`);
  if (type == "Delete") {
    const res = await reference.delete();
    return res;
  } else {
    const response = await fetch(data.uri);
    const blob = await response.blob();

    const task = await reference.put(blob);

    const url = task && (await reference.getDownloadURL());

    return { ...data, url: url };
  }
}

export function DeleteCatFromTree(path) {
  ProductTreeRef.child(path.join("/")).remove();
}

export async function UpdateFirmData(data) {
  FirmInfoRef.doc("GeneralInfo").set(data, { merge: true });
}
