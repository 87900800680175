import { db, dbTree, dbStorage } from "./Config";

import {
  UpdateUserData_Stakeholder,
  StakeholderDetailsLoad,
  AddStakeholders,
  DeleteStakeholder,
  UpdateStakeholderPayment,
} from "./Stakeholders";

import {
  LoadReceiptsMetaData,
  UpdateReceipt,
  DeleteReceipt,
  RetrieveReceipt,
  UserDataUpdate_Receipts,
  DeleteTrashReceipt,
  LoadReceiptData,
} from "./Receipts";

import {
  AddtoProductTree,
  DeleteFromProductTree,
  UpdaeVariable_Products,
  GetProductDetails,
  SetProductDetailsDB,
  UploadProductPics,
  UpdateFirmData,
  DeleteCatFromTree,
} from "./Products";

import {
  UpdaeVariable_Inventory,
  inventoryItemSet,
  inventoryUpdate,
  ItemsPurchaseAvgSet,
} from "./Inventory";

import { UpdaeVariable_Print, SetPrintJob } from "./Print";

export function updateVeriableFuntions({
  userData,
  tempProductSearch,
  temproot,
  tempFirmInfo,
  tempVendorProduct,
}) {
  UpdateUserData_Stakeholder(userData);
  UserDataUpdate_Receipts(userData);
  UpdaeVariable_Products({
    temRoot: temproot,
    userData: userData,
    tempProductSearch: tempProductSearch,
    tempFirmInfo: tempFirmInfo,
  });
  UpdaeVariable_Print({
    userData: userData,
  });
  UpdaeVariable_Inventory({
    temRoot: temproot,
    userData: userData,
    tempProductSearch: tempProductSearch,
    tempVendorProduct: tempVendorProduct,
  });

  return true;
}

// DB export
export { db };
export { dbTree };
export { dbStorage };

//Stakeholders functions

export { StakeholderDetailsLoad, AddStakeholders, DeleteStakeholder, UpdateStakeholderPayment };

//Receipts Functions
export {
  LoadReceiptsMetaData,
  UpdateReceipt,
  DeleteReceipt,
  RetrieveReceipt,
  DeleteTrashReceipt,
  LoadReceiptData,
};

//Product Functions
export {
  AddtoProductTree,
  DeleteCatFromTree,
  DeleteFromProductTree,
  GetProductDetails,
  SetProductDetailsDB,
  UploadProductPics,
  UpdateFirmData,
};

export { inventoryItemSet, inventoryUpdate, ItemsPurchaseAvgSet };

export { SetPrintJob };
//--------------Inital para loading LOADINGS----------------
