import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { db } from "../../Firebase/Main";

export default function Apps() {
  const userData = useSelector((state) => state.data.userData);

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubSuppier = db
      .collection(`${userData.firmID}/Stakeholders/Suppliers/`)
      .doc("SuppliersSearch")
      .onSnapshot(
        (docSnapshot) => {
          let data = docSnapshot.data();
          if (data) {
            dispatch({
              type: "SUPPLIERS_LIST_LOADING",
              payLoad: Object.values(data),
            });
            return;
          } else {
            dispatch({ type: "SUPPLIERS_LIST_LOADING", payLoad: [] });
            return;
          }
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
          return false;
        }
      );

    return () => {
      unsubSuppier();
    };
  }, []);

  return <></>;
}
