import React, { useState, useEffect, useRef, useCallback } from "react";
import { View, TouchableOpacity, TextInput, Text } from "react-native";
import { ThousandView, ProductNameDecode } from "../../Functions/Functions";
import { MinusCircleOutlined, FileImageFilled } from "@ant-design/icons";
import { useAlert } from "react-alert";
import Icon, { FontAwesome, Feather, MaterialIcons } from "react-web-vector-icons"; //https://oblador.github.io/react-native-vector-icons/

import Styles from "./Styles";

function Greating(props) {
  const [itemObj, setItemObj] = useState(props.item);
  const firstUpdate = useRef(true);
  const alert = useAlert();
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    props.onUpdate(itemObj);
  }, [itemObj]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setItemObj(props.item);
  }, [props.item]);

  function Discount(discount) {
    if (discount && (discount + "").includes("+") && Number(discount.slice(1)))
      return Number(props.item.sellingPrice) + Number(discount.slice(1));
    if (!Number(discount) || !discount) return props.item.sellingPrice;
    else if (Number(discount) > 0)
      return Math.ceil((Number(props.item.sellingPrice) * (100 - discount)) / 100);
    else return Number(props.item.sellingPrice) + discount;
  }

  return (
    <>
      <View style={[Styles.billItem, props.index % 2 == 0 && { backgroundColor: "#E1E1E1" }]}>
        <TouchableOpacity
          style={[
            Styles.billItemText,
            {
              width: "49%",
              textAlign: "left",
              paddingLeft: "2%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
          onPress={() => props.onShowImage(itemObj.image)}
        >
          {itemObj.image && <FileImageFilled style={{ color: "green", fontWeight: "bold" }} />}
          <Text style={{ width: "60%", fontSize: "max(2vmin, 14px)" }}>
            {" " + itemObj.label || ProductNameDecode(itemObj.make + " " + itemObj.item)}
          </Text>
          {!props.submitted && props.viewStatus != "ViewOnly" && (
            <TouchableOpacity
              style={[
                {
                  width: "6%",
                  justifyContent: "center",
                  alignItems: "center",
                },
              ]}
              onPress={() => props.onDelete(itemObj)}
            >
              <MinusCircleOutlined style={{ color: "red", fontWeight: "bold" }} />
            </TouchableOpacity>
          )}

          {itemObj.sellingPrice && (
            <Text style={[Styles.billItemText, { width: "40%", borderRightWidth: 0 }]}>
              {ThousandView(itemObj.sellingPrice)}
            </Text>
          )}
        </TouchableOpacity>

        <Text style={[Styles.billItemText, { width: "12%" }]}>
          {ThousandView(itemObj.quantity)}
        </Text>

        <Text style={[Styles.billItemText, { width: "12%" }]}>{ThousandView(itemObj.weight)}</Text>

        <Text style={[Styles.billItemText, { width: "12%" }]}>{ThousandView(itemObj.karad)}</Text>

        {/* <Text style={[Styles.billItemText, { width: "2%" }]}>
          {Number(itemObj.discount) > 0 && !(itemObj.discount + "").includes("+") ? "%" : ""}
        </Text>

        <Text style={[Styles.billItemText, { width: "12%" }]}>
          {ThousandView(itemObj.purPercentage)}
        </Text> */}

        {/* <TextInput
          value={itemObj.discount}
          editable={!props.submitted && props.viewStatus != "ViewOnly"}
          onChangeText={(e) => {
            if (e.slice(-1) == "-")
              return setItemObj({
                ...itemObj,
                discount: -1 * itemObj.discount,
                unitPrice: Discount(-1 * itemObj.discount),
              });
            else if (e.slice(-1) == "+") {
              if ((itemObj.discount + "").includes("+"))
                return setItemObj({
                  ...itemObj,
                  discount: itemObj.discount.slice(1),
                  unitPrice: Discount(itemObj.discount.slice(1)),
                });
              return setItemObj({
                ...itemObj,
                discount: "+" + Math.abs(itemObj.discount),
                unitPrice: Discount("+" + Math.abs(itemObj.discount)),
              });
            } else if (Number(e) || !e)
              setItemObj({
                ...itemObj,
                discount: Number(e),
                unitPrice: Discount(Number(e)),
              });
          }}
          paddingLeft={12}
          placeholderTextColor="black"
          style={[Styles.billItemText, { width: "12%" }]}
          placeholder="0"
        /> */}

        <Text
          style={[Styles.billItemText, { width: "15%", textAlign: "right", paddingRight: "2%" }]}
        >
          {ThousandView(itemObj.unitPrice)}
        </Text>
      </View>
    </>
  );
}

export default Greating;
