import moment from "moment";
import { db, dbTree } from "../../Firebase/Config";
import { NameWithInitial, ProductNameEncode } from "../../Functions/Functions";

function onDateChange(date) {
  const selectDate =
    date.getFullYear() +
      "-" +
      ("0" + Number(date.getMonth() + 1).toLocaleString()).slice(-2) +
      "-" +
      ("0" + date.getDate().toLocaleString()).slice(-2) || null;

  return selectDate;
}

function sumObjectsByKey(...objs) {
  return objs.reduce((a, b) => {
    for (let k in b) {
      if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
    }
    return a;
  }, {});
}

export async function CustomerLoadDB(firmID) {
  const snapshot = await db.collection(`/${firmID}/Stakeholders/Customers`).get();

  db.collection(`/${firmID}`)
    .doc("GeneralInfo")
    .set({ customerReport: moment().format("YYYY-MM-DD") }, { merge: true });

  let i = 1;

  let searchObj = {};
  let printObj = {};

  snapshot.forEach((doc) => {
    let data = doc.data();

    let filterdObj = { ...data };

    function calOutstanding(list) {
      let tot = 0;
      (list || []).map((inv) => (tot = tot + Number(inv.balance)));
      return tot;
    }

    if (data.primary) {
      let cusDetails = data.primary;
      let filteredID = cusDetails.id.replace(".", "");

      searchObj[filteredID] = {
        name: cusDetails.name,
        id: cusDetails.id,
        searchID: (cusDetails.name + "_" + cusDetails.phone + "_" + cusDetails.NIC).toUpperCase(),
      };

      filterdObj["salesPending"] = (data.salesPending || []).filter((inv) => inv.balance > 0);
      filterdObj["orderBalances"] = (data.orderBalances || []).filter((inv) => inv.totalCash > 0);

      filterdObj["purchaseBalances"] = (data.purchaseBalances || []).filter(
        (inv) => inv.balance > 0
      );

      // console.log(filterdObj);

      printObj[cusDetails.id] = {
        name: cusDetails.name,
        id: cusDetails.id,
        createdat: cusDetails.id.split("_")[0],

        phone: cusDetails.phone,
        address: cusDetails.address,

        NIC: cusDetails.NIC,
        NICimages: data.image && data.image2 ? 2 : data.image || data.image2 ? 1 : 0,

        outstanding: calOutstanding(filterdObj["salesPending"]),
        salesPending: filterdObj["salesPending"],
        orderBalances: filterdObj["orderBalances"],
        purchaseBalances: filterdObj["purchaseBalances"],
      };

      // printObj[cusDetails.id]

      i++;
    }
    return;
  });

  const refCusSearch = dbTree.ref(`/${firmID}/Stakeholders/CustomersSearch`);
  refCusSearch.update(searchObj);

  return printObj;
}

export async function DayRegenareteReort(date, firmID) {
  var day = new Date(date);
  var nextDay = new Date(day);
  nextDay.setDate(day.getDate() + 1);

  const snapshot = await db
    .collection(`/${firmID}/Receipts/AllReceipts/`)
    .orderBy("timeStamp", "asc")
    .startAt(date)
    .endAt(onDateChange(nextDay))
    .get();

  if (snapshot.empty) {
    return false;
  }

  let output = {
    date: date,
    InitialCashBalance: 0,
    InitialChequeBalance: 0,

    otherIncome: {
      total: 0,
      payMethod: { nothing: 0 },
      list: {},
    },

    pays: {
      total: 0,
      payMethod: { nothing: 0 },
      list: {},
    },

    sellers: {},

    cashSales: {
      total: 0,
      advance: 0,
      customers: {},
      payMethod: { nothing: 0 },
    },

    ["A_C"]: {
      total: 0,
      advance: { nothing: 0 },
      outstanding: 0,
      customers: {},
    },

    salesItems: {},
    salesKarats: {},
    purchaseItems: {},
    purchaseKarats: {},
    returnItems: {},

    purchase: {
      total: 0,
      advance: { nothing: 0 },
      outstanding: 0,
      suppliers: {},
    },

    order: {
      total: 0,
      advance: { nothing: 0 },
      outstanding: 0,
      suppliers: {},
    },

    orderItems: {},

    stockReductionValues: {
      total: 0,
    },
    paidSales: {
      total: 0,
      payMethod: { nothing: 0 },
    },
    paidOrder: {
      total: 0,
      payMethod: { nothing: 0 },
    },
    paidSMS: {
      total: 0,
      payMethod: { nothing: 0 },
    },

    payout: 0,

    return: {
      total: 0,
      items: {},
    },

    savings: {
      total: 0,
      payMethod: { nothing: 0 },
    },

    bank: {
      total: 0,
      items: {},
      payMethod: { nothing: 0 },
    },

    expense: {
      total: 0,
      payMethod: { nothing: 0 },
      supTypes: {},
      DamageList: {},
      list: {},
    },
  };

  let i = 0;

  snapshot.forEach((doc) => {
    let data = doc.data();
    i = i + 1;
    if (!data.timeStamp.includes(date)) return;

    switch (data.type) {
      case "InitialBalance":
        output.InitialCashBalance =
          output.InitialCashBalance + (data.payMethod == "Cash" ? data.total : 0);
        output.InitialChequeBalance =
          output.InitialChequeBalance + (data.payMethod == "Cheque" ? data.total : 0);
        return;

      case "OtherIncome":
        output.otherIncome.total = output.otherIncome.total + (data.total || 0);

        output.otherIncome.payMethod = {
          ...output.otherIncome.payMethod,
          [data.payMethod]: (output.otherIncome.payMethod[data.payMethod] || 0) + data.total,
        };
        output.otherIncome.list[ProductNameEncode(data.name)] =
          (output.otherIncome.list[ProductNameEncode(data.name)] || 0) + Number(data.total);
        return;

      case "Pays":
        output.pays.total = output.pays.total + (data.total || 0);

        output.pays.payMethod = {
          ...output.pays.payMethod,
          [data.payMethod]: (output.pays.payMethod[data.payMethod] || 0) + data.total,
        };
        output.pays.list[ProductNameEncode(data.name)] =
          (output.pays.list[ProductNameEncode(data.name)] || 0) + Number(data.total);
        return;

      case "Purchase":
        output.purchase.total = output.purchase.total + data.total;
        output.purchase.advance = {
          ...output.purchase.advance,
          [data.payMethod]: (output.purchase.advance[data.payMethod] || 0) + data.payAmount,
        };
        output.purchase.outstanding = output.purchase.outstanding + data.balance;
        let itemList2 = {};
        Object.values(data.itemList).map((item) => {
          itemList2 = {
            ...itemList2,
            [item.id]: {
              invoiceSN: i + data.invoiceSN,
              label: item.label,
              unitPrice: item.unitPrice || 0,
              weight: Number(item.weight) ? item.weight : "0.000",
              actWeight: Number(item.netWeight) ? item.netWeight : "0.000",

              karad: item.karad || false,
            },
          };
        });

        output.purchaseItems[i + data.invoiceSN] = {
          invoiceSN: data.invoiceSN,
          itemList: itemList2,
          total: data.total,
          payAmount: data.payAmount,
          issuedby: data.issuedby,
          name: NameWithInitial(data.name),
          namePhone: data.namePhone,
        };

        // output.purchase.suppliers[data.nameID + "_" + data.name] =
        //   (output.purchase.suppliers[data.nameID + "_" + data.name] || 0) + data.total;
        return;

      case "Order":
        output.order.total = output.order.total + data.total;

        output["order"].advance = sumObjectsByKey(output["order"].advance, data.payMethods);

        output.order.outstanding = output.order.outstanding + data.balance;

        let itemList3 = {};
        Object.values(data.itemList).map((item) => {
          itemList3 = {
            ...itemList3,
            [item.id]: {
              label: item.label,
              unitPrice: item.unitPrice,
              weight: Number(item.weight) ? item.weight : "0.000",
              karad: item.karad || false,
              invoiceSN: i + data.invoiceSN,
            },
          };
        });

        output.orderItems[i + data.invoiceSN] = {
          invoiceSN: data.invoiceSN,
          itemList: itemList3,
          total: data.total,
          payAmount: data.payAmount,
          issuedby: data.issuedby,
          name: NameWithInitial(data.name),
          namePhone: data.namePhone,
        };

        // output.purchase.suppliers[data.nameID + "_" + data.name] =
        //   (output.purchase.suppliers[data.nameID + "_" + data.name] || 0) + data.total;
        return;

      case "Sales_Saved":
        output.stockReductionValues.total =
          output.stockReductionValues.total + (Number(data.stockValueReduction) || 0);

        if (data.purchase == "Exchange" && (data.payMethods || {})["Purchase"]) {
          output.purchaseItems[data.invoiceSN] = {
            invoiceSN: data.invoiceSN,
            itemList: {
              [data.invoiceSN]: {
                label: data.payMethod1,
                unitPrice: data.payMethods["Purchase"] || 0,

                invoiceSN: data.invoiceSN,
                weight: "0.000",
                karad: "Instant..",
              },
            },
            total: data.payMethods["Purchase"] || 0,
            payAmount: data.payMethods["Purchase"] || 0,
            issuedby: data.issuedby,
            name: "Instant Purchase",
            namePhone: data.namePhone,
          };
        }
        let itemList = {};
        Object.values(data.itemList).map((item) => {
          itemList = {
            ...itemList,
            [item.id]: {
              label: item.label,
              unitPrice: item.unitPrice,
              purchaseAvg: item.purchaseAvg,
              invoiceSN: i + data.invoiceSN,
              weight: Number(item.weight) ? item.weight : "0.000",
              karad: item.karad || false,
            },
          };
          // output.salesItems = {
          //   ...output.salesItems,
          //   [data.invoiceSN + "_" + item.label + "_" + item.id]:
          //     item.weight + "_" + item.unitPrice,
          // };
        });

        output.salesItems[i + data.invoiceSN] = {
          invoiceSN: data.invoiceSN,
          itemList: itemList,
          total: data.total,
          payAmount: data.payAmount,
          issuedby: data.issuedby,
          name: NameWithInitial(data.name),
          namePhone: data.namePhone,
        };
        output.sellers[data.issuedby] = (output.sellers[data.issuedby] || 0) + data.total;
        // if (Number(data.total) - (Number(data.stockValueReduction) || 0) < 0) {
        // }

        if (data.balance > 0) {
          output["A_C"].total = output["A_C"].total + data.total;

          output["A_C"].advance = sumObjectsByKey(output["A_C"].advance, data.payMethods);

          // output["A_C"].customers[data.nameID + "_" + data.name] =
          //   (output["A_C"].customers[data.nameID + "_" + data.name] || 0) + data.total;

          output["A_C"].outstanding = output["A_C"].outstanding + data.balance;
          //
        } else {
          //
          output.cashSales.total = output.cashSales.total + data.total;

          // output.payout = output.payout - data.purchase ? data.balance : 0;

          output["cashSales"].payMethod = sumObjectsByKey(
            output["cashSales"].payMethod,
            data.payMethods,
            { Cash: data.balance }
          );
        }
        return;

      case "Return":
        output.return.total = output["return"].total + data.total;
        //   Object.values(data.itemList).map((item) => {
        //     output.return.items[item.label] =
        //       (output.return.items[item.label] || 0) + item.quantity;
        //   });
        //   return;
        let itemList4 = {};
        Object.values(data.itemList).map((item) => {
          itemList4 = {
            ...itemList4,
            [item.id]: {
              label: item.label,
              unitPrice: item.unitPrice,
              purchaseAvg: item.purchaseAvg,
              invoiceSN: i + data.invoiceSN,
              weight: Number(item.weight) ? item.weight : "0.000",
              karad: item.karad || false,
            },
          };
          // output.salesItems = {
          //   ...output.salesItems,
          //   [data.invoiceSN + "_" + item.label + "_" + item.id]:
          //     item.weight + "_" + item.unitPrice,
          // };
        });

        output.returnItems[i + data.invoiceSN] = {
          invoiceSN: data.invoiceSN,
          itemList: itemList4,
          total: data.total,
          payAmount: data.payAmount,
          issuedby: data.issuedby,
          name: NameWithInitial(data.name),
          namePhone: data.namePhone,
        };

        return;

      case "BankDeposit":
        output.bank.total = output["bank"].total + data.total;

        output.bank.payMethod = {
          ...output.bank.payMethod,
          [data.payMethod]: (output.bank.payMethod[data.payMethod] || 0) + data.total,
        };
        return;

      case "Paid_Sales":
        output.paidSales.total = output.paidSales.total + Number(data.total);

        // output.payout = output.payout - data.purchase ? data.balance : 0;

        output["paidSales"].payMethod = sumObjectsByKey(
          output["paidSales"].payMethod,
          data.payMethods
        );

        return;
      case "Paid_Order":
        output.paidOrder.total = output.paidOrder.total + Number(data.total);

        // output.payout = output.payout - data.purchase ? data.balance : 0;
        output["paidOrder"].payMethod = sumObjectsByKey(
          output["paidOrder"].payMethod,
          data.payMethods
        );

        return;

      case "Paid_SMS":
        output.paidSMS.total = output.paidSMS.total + Number(data.total);

        // output.payout = output.payout - data.purchase ? data.balance : 0;

        output["paidSMS"].payMethod = sumObjectsByKey(output["paidSMS"].payMethod, data.payMethods);

        return;

      case "Expense":
        output.expense.total = (output.expense.total || 0) + Number(data.total);

        output.expense.payMethod[data.payMethod] =
          (output.expense.payMethod[data.payMethod] || 0) + Number(data.total);

        // output.expense.supTypes[data.name] =
        //   (output.expense.supTypes[data.name] || 0) + Number(data.total);

        output.expense.list[data.name] = (output.expense.list[data.name] || 0) + Number(data.total);

        return;
    }

    return;
  });

  return output;
}
