import React, { useEffect, useState, useRef } from "react";
import { Modal, Pressable, Text, View, TouchableOpacity, TextInput, Keyboard } from "react-native";
import moment from "moment";
import {
  ThousandView,
  StackFinder,
  CapitalizeWords,
  ProductNameDecode,
  ProductNameEncode,
} from "../../Functions/Functions";
import { AddtoProductTree } from "../../Firebase/Main";
import Shortcutkey from "../../Shortcutkey/shortcutkey";

import { UserUpdate } from "../../Functions/ApiFunctions";
import { Product, VariableClass } from "../../Functions/Classes";

import { useSelector } from "react-redux";
import Styles from "./Styles";
import { useAlert } from "react-alert";

export default function NewStakeholderModal(props) {
  const [cat, setCat] = useState();
  const firmInfo = useSelector((state) => state.data.firmInfo);

  const weightRef = useRef();

  const phoneRef = useRef();
  const outstandingRef = useRef();
  const addressRef = useRef();
  const commentsRef = useRef();
  const alert = useAlert();
  const weightRecheckRef = useRef();
  const noteRef = useRef();
  const sizeRef = useRef();

  const variables = useRef(new VariableClass());
  const product = useRef(new Product(props.initialData));
  const [refresh, setRefresh] = useState(false);

  function UpdateToServer(printEnabled) {
    if (Number(product.current.weight) != Number(product.current.reEnterWeight) && !cat) {
      alert.error("The weights are not matched, Check the product weight again");

      return weightRecheckRef.current.focus();
    } else if (cat || product.current.getVeriableSatisfy()) {
      AddtoProductTree({
        path:
          props.path.join("/") +
          "/" +
          ProductNameEncode(cat || product.current.getAllData().weight),
        type: cat ? "CAT" : "ITEM",
        isAvailableOnTree: false,
        ...(product.current.getAllData() || {}),

        ...(firmInfo.purchaseCost
          ? {
              pPercentage: (Number(product.current.pPercentage) * 100) / firmInfo["24K"],
              pCost: product.current.pPercentage,
            }
          : {}),

        printEnabled: printEnabled,
      }).then((qr) => {
        resetItem();
        qr && props.itemAdded(qr);
        alert.success("Added Successfully");
      });
      return cat && props.close();
    }
    alert.error("Some essential feild is missing");
  }

  function resetItem() {
    product.current.clear(["karad", "pPercentage", "profitMargin", "maxDiscount", "note"]);
    weightRef.current && weightRef.current.focus();
    variables.current.setProp({ weightCheck: false });
    // product.current.clear();
    setTimeout(() => {
      weightRef.current && weightRef.current.clear();
      weightRef.current && weightRef.current.focus();
    }, 100);
    setRefresh(!refresh);
  }

  function pageChange() {
    variables.current.setProp({ weightCheck: true });
    product.current.setProp({ reEnterWeight: null });
    setTimeout(() => {
      weightRecheckRef.current.clear();
      sizeRef.current.clear();

      weightRecheckRef.current.focus();
    }, 100);
  }
  //console.log(weightRef);
  return (
    <Modal transparent={true} visible={true} useNativeDriver={true} onRequestClose={props.close}>
      <Shortcutkey
        type="AddNewStakeHolder"
        onPopUpModal={(type) => {
          if (type == "AddNewStakeHolder") {
            props.close();
          }
        }}
      />
      <TouchableOpacity style={Styles.modalCenterdView} activeOpacity={1} onPress={props.close}>
        <TouchableOpacity style={Styles.modalView} activeOpacity={1}>
          <Text style={[Styles.buttonText, { color: "green" }]}>
            "{firmInfo.code || ""}" SERIAL ID : {firmInfo.productSerial}
          </Text>
          <Text style={[Styles.buttonText, { color: "red" }]}>
            PATH : {ProductNameDecode(props.path.join("/")).toUpperCase()}
          </Text>

          {(props.type || "CAT").includes("CAT") ? (
            <>
              <TextInput
                autoFocus={true}
                value={cat}
                onChangeText={setCat}
                paddingLeft={12}
                placeholderTextColor="black"
                style={Styles.modelTextInput}
                placeholder={"Category"}
                onSubmitEditing={() => UpdateToServer()}
              />

              <View
                style={{
                  flexDirection: "row",
                  width: "90%",
                  justifyContent: "center",
                }}
              >
                <Pressable
                  style={[Styles.button, { width: "50%", backgroundColor: "green" }]}
                  onPress={UpdateToServer}
                >
                  <Text style={[Styles.buttonText, { color: "white" }]}>ADD</Text>
                </Pressable>
                <Pressable
                  style={[
                    Styles.button,
                    { width: "50%", backgroundColor: "red", marginLeft: "5%" },
                  ]}
                  onPress={props.close}
                >
                  <Text style={[Styles.buttonText, { color: "white" }]}>CLOSE</Text>
                </Pressable>
              </View>
            </>
          ) : variables.current.weightCheck ? (
            <>
              <Text
                style={{
                  textAlign: "left",
                  zIndex: 9999,
                  marginBottom: -10,
                  marginLeft: 20,

                  width: "100%",
                }}
              >
                {product.current.reEnterWeight ? "Re-Enter Weight (g)" : ""}
              </Text>
              <TextInput
                value={ThousandView(product.current.reEnterWeight)}
                autoFocus={variables.current.weightCheck}
                paddingLeft={12}
                style={Styles.modelTextInput}
                placeholder={"Re-Enter Weight (g) *"}
                onChangeText={(k) => {
                  let e = k.replace(/,/g, "");
                  if (Number(e) >= 0) product.current.setProp({ reEnterWeight: e });
                  else alert.error("Input should be a number");
                  setRefresh(!refresh);
                }}
                onSubmitEditing={() => sizeRef.current.focus()}
                ref={weightRecheckRef}
                // onKeyPress={(data) => !name && data.key == "Backspace" && props.close()}
              />
              <TextInput
                value={product.current.size}
                autoFocus={variables.current.weightCheck}
                paddingLeft={12}
                style={Styles.modelTextInput}
                placeholder={"Size"}
                onChangeText={(k) => {
                  product.current.setProp({ size: k });
                  setRefresh(!refresh);
                }}
                onSubmitEditing={() => UpdateToServer(true)}
                ref={sizeRef}
                // onKeyPress={(data) => !name && data.key == "Backspace" && props.close()}
              />
              <View
                style={{
                  flexDirection: "row",
                  width: "90%",
                  justifyContent: "center",
                }}
              >
                <Pressable
                  style={[Styles.button, { width: "50%", backgroundColor: "green" }]}
                  onPress={() => UpdateToServer(true)}
                >
                  <Text style={[Styles.buttonText, { color: "white" }]}>ADD</Text>
                </Pressable>
                <Pressable
                  style={[
                    Styles.button,
                    { width: "50%", backgroundColor: "red", marginLeft: "5%" },
                  ]}
                  onPress={() => {
                    variables.current.setProp({ weightCheck: false });
                    setRefresh(!refresh);
                  }}
                >
                  <Text style={[Styles.buttonText, { color: "white" }]}>BACK</Text>
                </Pressable>
              </View>
            </>
          ) : (
            <>
              <Text
                style={{
                  textAlign: "left",
                  zIndex: 9999,
                  marginBottom: -10,
                  marginLeft: 20,
                  width: "100%",
                }}
              >
                {product.current.weight ? "Weight (g)" : ""}
              </Text>
              <TextInput
                value={ThousandView(product.current.weight)}
                autoFocus={true}
                paddingLeft={12}
                style={Styles.modelTextInput}
                placeholder={"Weight (g) *"}
                onChangeText={(k) => {
                  let e = k.replace(/,/g, "");
                  if (Number(e) >= 0) product.current.setProp({ weight: e });
                  else alert.error("Input should be a number");
                  setRefresh(!refresh);
                }}
                onSubmitEditing={() => {
                  phoneRef.current.focus();
                }}
                ref={weightRef}
              />
              <Text
                style={{
                  textAlign: "left",
                  zIndex: 9999,
                  marginBottom: -10,
                  marginLeft: 20,
                  width: "100%",
                  marginTop: product.current.karad ? -10 : 0,
                }}
              >
                {product.current.karad ? "Karad" : ""}
              </Text>
              <TextInput
                value={ThousandView(product.current.karad)}
                onChangeText={(k) => {
                  let e = k.replace(/,/g, "");
                  if (Number(e) >= 0) product.current.setProp({ karad: e });
                  else alert.error("Input should be a non zero - number");
                  setRefresh(!refresh);
                }}
                style={Styles.modelTextInput}
                paddingLeft={12}
                keyboardType={"decimal-pad"}
                placeholder="Karad *"
                ref={phoneRef}
                onSubmitEditing={() => {
                  outstandingRef.current.focus();
                }}
                // onKeyPress={(data) => data.key == "Escape" && props.close()}
              />
              <Text
                style={{
                  textAlign: "left",
                  zIndex: 9999,
                  marginBottom: -10,
                  marginLeft: 20,
                  width: "100%",
                  marginTop: product.current.pPercentage ? -10 : 0,
                }}
              >
                {product.current.pPercentage ? "Purchase" : ""}
              </Text>
              <TextInput
                value={ThousandView(product.current.pPercentage)}
                onChangeText={(k) => {
                  let e = k.replace(/,/g, "");
                  if (Number(e) >= 0) product.current.setProp({ pPercentage: e });
                  else alert.error("Input should be a number");
                  setRefresh(!refresh);
                }}
                style={Styles.modelTextInput}
                paddingLeft={12}
                keyboardType={"decimal-pad"}
                placeholder={"Purchase " + (firmInfo.purchaseCost ? "Cost" : "%") + " *"}
                ref={outstandingRef}
                onSubmitEditing={() => {
                  addressRef.current.focus();
                }}
                // onKeyPress={(data) => data.key == "Escape" && props.close()}
              />
              <Text
                style={{
                  textAlign: "left",
                  zIndex: 9999,
                  marginBottom: -10,
                  marginLeft: 20,
                  width: "100%",
                  marginTop: product.current.profitMargin ? -10 : 0,
                }}
              >
                {product.current.profitMargin ? "Profit % " : ""}
              </Text>
              <TextInput
                value={ThousandView(product.current.profitMargin)}
                onChangeText={(k) => {
                  let e = k.replace(/,/g, "");
                  if (Number(e) >= 0) product.current.setProp({ profitMargin: e });
                  else alert.error("Input should be a number");
                  setRefresh(!refresh);
                }}
                style={Styles.modelTextInput}
                paddingLeft={12}
                placeholder="Profit % *"
                ref={addressRef}
                onSubmitEditing={() => {
                  commentsRef.current.focus();
                }}
                // onKeyPress={(data) => data.key == "Escape" && props.close()}
              />
              <Text
                style={{
                  textAlign: "left",
                  zIndex: 9999,
                  marginBottom: -10,
                  marginLeft: 20,
                  width: "100%",
                  marginTop: product.current.maxDiscount ? -10 : 0,
                }}
              >
                {product.current.maxDiscount ? "Max Discount %" : ""}
              </Text>
              <TextInput
                value={ThousandView(product.current.maxDiscount)}
                onChangeText={(k) => {
                  let e = k.replace(/,/g, "");
                  if (Number(e) >= 0) product.current.setProp({ maxDiscount: e });
                  else alert.error("Input should be a number");
                  setRefresh(!refresh);
                }}
                style={Styles.modelTextInput}
                placeholder="Max Discount % *"
                ref={commentsRef}
                onSubmitEditing={() => {
                  noteRef.current.focus();
                }}
                // onKeyPress={(data) => data.key == "Escape" && props.close()}
              />
              <Text
                style={{
                  textAlign: "left",
                  zIndex: 9999,
                  marginBottom: -10,
                  marginLeft: 20,
                  width: "100%",
                  marginTop: product.current.note ? -10 : 0,
                }}
              >
                {product.current.note ? "Any Note" : ""}
              </Text>
              <TextInput
                value={product.current.note}
                onChangeText={(k) => {
                  product.current.setProp({ note: k });

                  setRefresh(!refresh);
                }}
                style={Styles.modelTextInput}
                placeholder="Any Note"
                ref={noteRef}
                onSubmitEditing={() => {
                  product.current.getVeriableSatisfy()
                    ? pageChange()
                    : alert.info("Please fill all mandatory field *");
                  setRefresh(!refresh);
                }}
                // onKeyPress={(data) => data.key == "Escape" && props.close()}
              />
              <View
                style={{
                  flexDirection: "row",
                  width: "90%",
                  justifyContent: "center",
                }}
              >
                <Pressable
                  style={[Styles.button, { width: "50%", backgroundColor: "green" }]}
                  onPress={() => {
                    product.current.getVeriableSatisfy()
                      ? pageChange()
                      : alert.info("Please fill all mandatory field *");
                    setRefresh(!refresh);
                  }}
                >
                  <Text style={[Styles.buttonText, { color: "white" }]}>ADD</Text>
                </Pressable>
                <Pressable
                  style={[
                    Styles.button,
                    { width: "50%", backgroundColor: "red", marginLeft: "5%" },
                  ]}
                  onPress={props.close}
                >
                  <Text style={[Styles.buttonText, { color: "white" }]}>CLOSE (Esc)</Text>
                </Pressable>
              </View>
            </>
          )}
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}
