var moment = require("moment");

export class Product {
  //timeStamp = moment().format("YYYY-MM-DD HH:mm:ss_SSS");
  unit = "g";

  quantity = 1;

  constructor(obj) {
    obj &&
      Object.keys(obj).map((key) => {
        this[key] = obj[key];
      });
  }

  setProp(obj) {
    Object.keys(obj).map((key) => {
      this[key] = obj[key];
    });
  }

  getMaxDiscount() {
    let basePrice = (Number(this.weight) * this.baseRate) / 8;
    return Math.ceil(basePrice * this.maxDiscount * 0.01);
  }

  getMinimumPrice() {
    let basePrice = (Number(this.weight) * this.baseRate) / 8;
    return Math.ceil(
      basePrice * (Number(this.pPercentage) + Number(this.profitMargin - this.maxDiscount)) * 0.01
    );
  }

  getSellingPrice() {
    if (this.baseRate) {
      let basePrice = (Number(this.weight) * this.baseRate) / 8;
      this.purchaseAvg = Math.ceil(basePrice * Number(this.pPercentage) * 0.01);
      this.sellingPrice = Math.ceil(
        basePrice * (Number(this.pPercentage) + Number(this.profitMargin)) * 0.01
      );
      return this.sellingPrice;
    } else return 0;
  }

  getDiscountedPrice(discount) {
    if (!Number(discount) || !discount) return this.sellingPrice;
    else if (Number(discount) > 0) {
      let basePrice = (Number(this.weight) * this.baseRate) / 8;
      return Math.ceil(
        basePrice * (Number(this.pPercentage) + Number(this.profitMargin - discount)) * 0.01
      );
    } else return Number(this.sellingPrice) + discount;
  }

  getVeriableSatisfy(page) {
    if (!page)
      return this.weight && this.pPercentage && this.profitMargin && this.maxDiscount && this.karad;
    else if (page == 1)
      return this.itemType && this.karad && this.item && this.label && this.stoneWeight;
    else
      return (
        this.stonePrice && this.weight && this.baseGoldPrice && this.pPercentage && this.cPercentage
      );
  }

  getAllData() {
    let weightItems = (this.weight || "").split(".");
    let weightTemp =
      weightItems.length == 1
        ? weightItems[0] + ".000"
        : weightItems[0] + ("." + weightItems[1] + "000").slice(0, 4);
    return { ...this, weight: weightTemp };
  }

  clear(list) {
    for (const key in { ...this }) {
      if (!["unit", "quantity", ...list].includes(key)) this[key] = null;
    }
    return;
  }

  getPurchaseAvg() {
    return 1000;
  }
  isAnyTrue() {
    for (const key in { ...this }) {
      if (this[key]) return true;
    }
    return false;
  }
}

export class OrderItem {
  id = moment().format("YYYY@MM@DD@HH@mm@ss@SSS");
  quantity = 1;
  unit = "g";

  constructor(obj) {
    obj &&
      Object.keys(obj).map((key) => {
        this[key] = obj[key];
      });
  }
  setProp(obj) {
    Object.keys(obj).map((key) => {
      this[key] = obj[key];
    });
  }

  getVeriableSatisfy() {
    return this.des && this.karad && this.weight && this.unitPrice && this.karad;
  }

  getAllData() {
    return { ...this };
  }
}

export class Stakeholder {
  primary = {};
  balances = {};
  salesPending = [];
  orderPending = [];

  constructor(obj) {
    obj &&
      Object.keys(obj).map((key) => {
        this[key] = obj[key];
      });
  }
  setProp(obj) {
    Object.keys(obj).map((key) => {
      this[key] = obj[key];
    });
  }

  setPrimary(obj) {
    Object.keys(obj).map((key) => {
      this.primary[key] = obj[key];
    });
  }

  setBalances(obj) {
    Object.keys(obj).map((key) => {
      this.balances[key] = obj[key];
    });
  }

  getVeriableSatisfy() {
    return Object.keys(this.primary).length > 4;
  }

  getAllData() {
    this.primary["id"] =
      this.primary["id"] ||
      moment().format("YYYY-MM-DD_HH-mm_") +
        (this.primary.phone && this.primary.phone.replace(" ", ""));
    return { ...this };
  }
}

export class FormClass {
  constructor(obj) {
    obj &&
      Object.keys(obj).map((key) => {
        this[key] = obj[key];
      });
  }
  setProp(obj) {
    Object.keys(obj).map((key) => {
      this[key] = obj[key];
    });
  }

  setPayment(amount) {}

  getVeriableSatisfy(variableList) {
    let statusTrue = variableList.filter((data) => this[data]);
    return statusTrue.length == variableList.length;
  }

  getAllData() {
    return { ...this };
  }
}

export class VariableClass {
  constructor(obj) {
    obj &&
      Object.keys(obj).map((key) => {
        this[key] = obj[key];
      });
  }
  setProp(obj) {
    Object.keys(obj).map((key) => {
      this[key] = obj[key];
    });
  }

  isAnyTrue() {
    for (const key in { ...this }) {
      if (this[key]) return true;
    }
    return false;
  }

  isAllTrue() {
    for (const key in { ...this }) {
      if (!this[key]) return false;
    }
    return true;
  }

  getAllData() {
    return { ...this };
  }
}
