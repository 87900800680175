import React, { Component, useState, useEffect, useRef, useCallback } from "react";
import SwipeableViews from "react-swipeable-views";
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  TextInput,
} from "react-native";

import { useSelector, useDispatch } from "react-redux";
import Shortcutkey from "../../Shortcutkey/shortcutkey";

import { useAlert } from "react-alert";
import { StakeholderDetailsLoad, AddStakeholders, UpdateReceipt } from "../../Firebase/Main";

import { NewStakeholderModal } from "../Modals/AddNewStakeholder";

import { AddPaymentModal } from "../Modals/AddPayment";

import Select from "react-select"; //https://medium.com/@lahiru0561/react-select-with-custom-label-and-custom-search-122bfe06b6d7

const expenseTypes = [
  { label: "Damages", value: "Damage" },
  { label: "Salary", value: "Salary" },
  { label: "Wages", value: "Wages" },
  { label: "Maintance", value: "Maintance" },
  { label: "Other", value: "Other" },
];

const inveTypeStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles) => ({ ...styles, padding: "1px" }), // padding: "max(0.2vmin, 1px)"
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "green",

    zIndex: 10,
  }),
  container: () => ({
    fontSize: "max(2vmin, 15px)",
  }),
};

function App(props, ref) {
  const customersList = useSelector((state) => state.data.customersList);
  const suppilersList = useSelector((state) => state.data.suppilersList);
  const preInfo = useSelector((state) => state.data.preInfo);

  const [stakeHolderModal, setAddNewStakeHolderModal] = useState(false);
  const [addPayModal, setAddPayView] = useState(false); //payment
  const stakeholderStatus = useRef(false);

  const customerNameRef1 = useRef();
  const customerNameRef2 = useRef();
  const expenseRef = useRef(true);
  const alert = useAlert();

  useEffect(() => {
    props.setStakeholderDetails();
    stakeholderStatus.current = false;
    setAddPayView(false);
    customerNameRef2.current &&
      customerNameRef2.current.clearValue &&
      customerNameRef2.current.clearValue();
    customerNameRef1.current && customerNameRef1.current.clear && customerNameRef1.current.clear();
  }, [props.reset]);

  useEffect(() => {
    !props.isEditEnabled &&
      setTimeout(() => {
        ["Sales_Saved", "VendorPurchase", "Return"].includes(props.invType)
          ? customerNameRef2.current && customerNameRef2.current.focus()
          : props.invType == "Expense"
          ? expenseRef.current && expenseRef.current.focus()
          : customerNameRef1.current && customerNameRef1.current.focus();
      }, 10);
    stakeholderStatus.current = false;
  }, [props.invType, props.forcusStakholder]);
  console.log(props.invType);
  return (
    <>
      {stakeHolderModal && (
        <NewStakeholderModal
          close={() => setAddNewStakeHolderModal(false)}
          onSubmit={(data) =>
            AddStakeholders({
              data: data,
              type: props.invType == "VendorPurchase" ? "Suppliers" : "Customers",
            })
          }
          type={props.invType == "VendorPurchase" ? "Supplier" : "Customer"}
        />
      )}
      {addPayModal && props.stakeholderDetails && (
        <AddPaymentModal
          stakeholderDetails={props.stakeholderDetails}
          close={() => setAddPayView(false)}
          preInfo={preInfo}
          onSubmit={(data) => {
            props.setStakeholderDetails({
              ...props.stakeholderDetails,
              outstanding: props.stakeholderDetails.outstanding - data.total,
            });
            //setFormattedRecipient(data);
            UpdateReceipt(data).then(() => {
              alert.success("PAYMENT ADDED SUCCESSFULLY");
            });
          }}
          onPrint={() => null}
        />
      )}
      <Shortcutkey
        type={["Bill", "Stakeholders"]}
        onPopUpModal={(type) => {
          if (type.includes("Bill")) {
            if (stakeholderStatus.current == "PAY") setAddPayView(true);
            else if (stakeholderStatus.current == "ADD") setAddNewStakeHolderModal(true);
            else {
              setAddPayView(false);
              setAddNewStakeHolderModal(false);
            }
          }
        }}
        onClean={(type) => {
          if (type.includes("Bill")) {
            setAddPayView(false);
            setAddNewStakeHolderModal(false);
          }
        }}
      />

      {["VendorPurchase", "Sales_Saved", "Return", "Paid_A/C_Paid"].includes(props.invType) &&
      !props.isEditEnabled ? (
        <>
          <View
            style={{ width: "25%", marginLeft: "1%", margin: "1vmin" }}
            onkeydown={(d) => console.log(d)}
          >
            <Select
              menuPortalTarget={document.body}
              styles={inveTypeStyles}
              classNamePrefix="select"
              placeholder="Name"
              isLoading={false}
              isClearable={true}
              style={{ width: 100 }}
              isRtl={false}
              isSearchable={true}
              name="color"
              noOptionsMessage={() => {
                stakeholderStatus.current = "ADD";
              }}
              options={props.invType == "VendorPurchase" ? suppilersList : customersList}
              onInputChange={(data) => {
                data && (stakeholderStatus.current = false);
                data && props.setStakeholderDetails();
              }}
              onChange={(item) => {
                item &&
                  StakeholderDetailsLoad({
                    id: item.id,
                    type: props.invType == "VendorPurchase" ? "Suppliers" : "Customers",
                  }).then((data) => {
                    props.setStakeholderDetails(data);
                    props.nextForcus && props.nextForcus();
                    stakeholderStatus.current = "PAY";
                  });
              }}
              isDisabled={props.submitted || props.isEditEnabled}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => `${option.id}`}
              returnKeyType="next"
              onKeyDown={(data) => {
                data.key == "ArrowLeft" && props.onPreviousFocus && props.onPreviousFocus();
                data.key == "ArrowRight" &&
                  props.stakeholderDetails &&
                  props.nextForcus &&
                  props.nextForcus();
              }}
              ref={customerNameRef2}
            />
          </View>

          {stakeholderStatus.current && !props.itemDetails && !props.isPayDisabled && (
            <View style={{ width: "13%", marginRight: "1%" }}>
              <TouchableOpacity
                style={Styles.buttonView}
                onPress={() => {
                  if (stakeholderStatus.current == "PAY") setAddPayView(true);
                  else setAddNewStakeHolderModal(true);
                }}
              >
                <Text style={Styles.buttonText}>{stakeholderStatus.current + " (F1)"}</Text>
              </TouchableOpacity>
            </View>
          )}
        </>
      ) : props.invType == "Expense" ? (
        <View style={{ width: "25%", marginLeft: "1%", margin: "1vmin" }}>
          <Select
            menuPortalTarget={document.body}
            styles={inveTypeStyles}
            classNamePrefix="select"
            placeholder="Expence Type"
            isLoading={false}
            isClearable={true}
            style={{ width: 100 }}
            isRtl={false}
            isSearchable={true}
            name="color"
            options={expenseTypes}
            onChange={(item) => {
              item && props.setExpenseSubtype(item.value);
              item && props.nextForcus && props.nextForcus();
            }}
            isDisabled={props.submitted || props.isEditEnabled}
            returnKeyType="next"
            onSubmitEditing={() => null}
            ref={expenseRef}
            onKeyDown={(data) => {
              data.key == "ArrowLeft" && props.onPreviousFocus && props.onPreviousFocus();
            }}
          />
        </View>
      ) : (
        <View style={{ width: "25%", marginLeft: "1%" }}>
          <TextInput
            onChangeText={props.setStakeholderDetails}
            paddingLeft={12}
            placeholderTextColor="black"
            style={Styles.modelTextInput}
            editable={!props.submitted && !props.isEditEnabled}
            placeholder={props.isEditEnabled ? props.editReceipt.name : "Customer Name"}
            returnKeyType="next"
            onSubmitEditing={() => props.nextForcus()} //console.log(customerNameRef1.current.value)
            ref={customerNameRef1}
            onKeyPress={(data) => {
              data.key == "ArrowLeft" && props.onPreviousFocus && props.onPreviousFocus();
            }}
          />
        </View>
      )}
    </>
  );
}

const Styles = StyleSheet.create({
  viewTag: {
    width: "95%",
    marginTop: "1%",
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
    maxWidth: "1400px",
    borderRadius: "1vmin",
    backgroundColor: "#6AC0FF",
  },
  modelTextInput: {
    margin: "1vmin",
    width: "100%",
    fontSize: "max(2vmin, 16px)",
    padding: "8px",
    backgroundColor: "white",
    borderRadius: 5,
    borderColor: "#757474",
    borderWidth: 0,
    marginLeft: 0,
  },
  buttonView: {
    width: "100%",
    margin: "1vmin",
    fontSize: "max(2vmin, 16px)",
    padding: "8px",
    borderRadius: 10,
    backgroundColor: "#FFAC30",
    textAlign: "center",
    fontWeight: "bold",
  },
  buttonText: {
    fontSize: "max(2vmin, 16px)",
    fontWeight: "bold",
    textAlign: "center",
  },
});

export default App;
