import React from "react";
import {
  ThousandView,
  LebelingFromID,
  CapitalizeWords,
  ProductNameDecode,
} from "../Functions/Functions";
import { jsPDF } from "jspdf";
import moment, { max } from "moment";

const sortObject = (o) =>
  Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});

const ObjtoArraySortInvoiceSN = (obj) => {
  return Object.values(obj).sort(function (a, b) {
    if (a.invoiceSN && b.invoiceSN) return a.invoiceSN - b.invoiceSN;
    else return -1;
  });
};
// Object.keys(obj)
//   .sort()
//   .reduce((res, key) => ((res[key] = obj[key]), res), {});

export default function GeneratePDF(data, metadata) {
  const doc = new jsPDF();

  const y = 27;
  let l = 0;

  const lSpace = 6;
  let page = 1;

  doc.setFont("helvetica", "bold");
  // doc.setFontType("bold");
  doc.setFontSize(14);
  doc.text(metadata.type, 30, 18, "left");
  doc.text("FIRM CODE : " + metadata.firmCode, 100, 18, "left");
  doc.text("ON :" + metadata.date, 150, 18, "left");

  doc.setFontSize(12);

  doc.text("ITEMS", 30, y + l * lSpace, "center");
  doc.text("INITAL", 75, y + l * lSpace, "center");
  doc.text("SOLD (-)", 113, y + l * lSpace, "center");
  doc.text("ADDED (+)", 146, y + l * lSpace, "center");
  doc.text("FINAL", 185, y + l * lSpace, "center");

  // doc.setFontType("normal");

  for (const cat in data) {
    for (const cat2 in data[cat]) {
      l += 1;
      let startWeight = (
        Number(data[cat][cat2].final.weight) +
        Number(data[cat][cat2].sold.weight) -
        Number(data[cat][cat2].added.weight)
      ).toFixed(2);
      let startQts =
        data[cat][cat2].final.qts + data[cat][cat2].sold.qts - data[cat][cat2].added.qts;

      if (cat2 == "count") {
        doc.setFont("helvetica", "bold");
        // doc.setFontType("bold");
        doc.setFontSize(12);
        doc.text(CapitalizeWords(ProductNameDecode(cat)), 15, y + l * lSpace);
      } else {
        doc.setFont("helvetica", "normal");
        doc.setFontSize(11);
        doc.text(CapitalizeWords(ProductNameDecode(cat2)), 17, y + l * lSpace);
      }

      doc.text(ThousandView(startQts + "") + " | ", 70, y + l * lSpace, "right");
      doc.text(ThousandView(startWeight + "") + "g", 70, y + l * lSpace, "left");

      doc.text(ThousandView(data[cat][cat2].sold.qts + "") + " | ", 108, y + l * lSpace, "right");
      doc.text(ThousandView(data[cat][cat2].sold.weight + "") + "g", 108, y + l * lSpace, "left");

      doc.text(ThousandView(data[cat][cat2].added.qts + "") + " | ", 141, y + l * lSpace, "right");
      doc.text(ThousandView(data[cat][cat2].added.weight + "") + "g", 141, y + l * lSpace, "left");

      doc.text(ThousandView(data[cat][cat2].final.qts + "") + " | ", 180, y + l * lSpace, "right");
      doc.text(ThousandView(data[cat][cat2].final.weight + "") + "g", 180, y + l * lSpace, "left");
      // doc.text("SOLD (-)", 113, y + l * lSpace, "center");
      // doc.text("ADDED (+)", 146, y + l * lSpace, "center");
      // doc.text("FINAL", 180, y + l * lSpace, "center");
    }
    l += 1;
  }
  doc.setFont("helvetica", "bold");
  doc.setFontSize(8);
  doc.text("GENERATED AT " + moment().format("YYYY-MM-DD hh:mm:ss a"), 180, 285, "right");
  doc.setFontSize(9);
  doc.setTextColor("#0000AF");
  doc.text("SOFTWARED BY WWW.INSTANTDIGITS.COM || +9471 999 2075", 100, 293, "center");
  doc.save(`DayStockStatement_${metadata.date}.pdf`);
}

export function GenerateFlowStatementPDF(balance) {
  const doc = new jsPDF();

  const y = 19;
  let l = 0;

  const lSpace = 6;
  let page = 1;
  doc.setFont("helvetica", "bold");
  doc.setFontSize(8);
  doc.text("DATE     :" + balance.date, 180, y + l * lSpace, "right");
  l++;
  doc.setFont("helvetica", "bold");
  // doc.setFontType("bold");
  doc.setFontSize(16);
  doc.setTextColor(255, 0, 0);
  doc.text("STATEMENT: CASHFLOW AND NETFLOW", 50, y + l * lSpace, "left");
  l++;
  l++;
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("NO.", 20, y + l * lSpace, "left");
  doc.text("Description", 40, y + l * lSpace, "left");
  doc.text("Cashflow", 130, y + l * lSpace, "left");
  doc.text("Netflow", 170, y + l * lSpace, "left");
  l++;
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");

  let no = 1;
  doc.text(no + "", 24, y + l * lSpace, "center");
  doc.text("INITIAL BALANCES", 40, y + l * lSpace, "left");
  doc.text(ThousandView(balance["initialCashBalance"] || "0.00"), 130, y + l * lSpace, "left");
  doc.text(ThousandView(balance["initialBalance"] || "0.00"), 170, y + l * lSpace, "left");
  l++;
  no++;
  doc.setFont("helvetica", "bold");
  doc.text("INFLOW DETAILS", 45, y + l * lSpace, "left");
  doc.setFont("helvetica", "normal");
  l++;
  let totalInflow = 0;
  for (const flowIn in balance["cashInFlows"]) {
    let flowDet = balance["cashInFlows"][flowIn];
    doc.text(no + "", 24, y + l * lSpace, "center");
    doc.text(flowDet["label"], 40, y + l * lSpace, "left");
    doc.text(ThousandView(flowDet["cash"] || "0.00"), 130, y + l * lSpace, "left");
    doc.text(ThousandView(flowDet["total"] || "0.00"), 170, y + l * lSpace, "left");
    totalInflow = totalInflow + flowDet["total"];
    l++;
    no++;
  }

  doc.text("_____________", 130, y + l * lSpace, "left");
  doc.text("_____________", 170, y + l * lSpace, "left");
  l++;

  doc.setFont("helvetica", "bold");
  doc.text("TOTAL INFLOW", 40, y + l * lSpace, "left");
  doc.text(ThousandView(balance["cashIn"] || "0.00"), 130, y + l * lSpace, "left");
  doc.text(ThousandView(totalInflow || "0.00"), 170, y + l * lSpace, "left");
  l++;
  doc.text("OUTFLOW (PAID) DETAILS", 45, y + l * lSpace, "left");
  doc.setFont("helvetica", "normal");
  l++;
  let totalOutflow = 0;
  for (const flowIn in balance["cashOutFlows"]) {
    let flowDet = balance["cashOutFlows"][flowIn];
    doc.text(no + "", 24, y + l * lSpace, "center");
    doc.text(flowDet["label"], 40, y + l * lSpace, "left");
    doc.text(ThousandView(flowDet["cash"] || "0.00"), 130, y + l * lSpace, "left");
    doc.text(ThousandView(flowDet["total"] || "0.00"), 170, y + l * lSpace, "left");
    totalOutflow = totalOutflow + flowDet["total"];
    l++;
    no++;
  }

  doc.text("_____________", 130, y + l * lSpace, "left");
  doc.text("_____________", 170, y + l * lSpace, "left");
  l++;

  doc.setFont("helvetica", "bold");
  doc.text("TOTAL OUTFLOW", 40, y + l * lSpace, "left");
  doc.text(ThousandView(balance["cashOut"] || "0.00"), 130, y + l * lSpace, "left");
  doc.text(ThousandView(totalOutflow || "0.00"), 170, y + l * lSpace, "left");
  l++;

  doc.text("_____________", 130, y + l * lSpace, "left");
  doc.text("_____________", 170, y + l * lSpace, "left");
  l++;
  doc.setFontSize(12);
  doc.text("CLOSING BALANCES", 40, y + l * lSpace, "left");
  doc.text(ThousandView(balance["balance"] || "0.00"), 130, y + l * lSpace, "left");
  doc.text(
    ThousandView((balance["initialBalance"] || 0) + totalInflow - totalOutflow || "0.00"),
    170,
    y + l * lSpace,
    "left"
  );
  l++;
  doc.setFont("helvetica", "bold");
  doc.setFontSize(8);
  doc.text("GENERATED AT " + moment().format("YYYY-MM-DD hh:mm:ss a"), 180, 285, "right");
  doc.setFontSize(9);
  doc.setTextColor("#0000AF");
  doc.text("SOFTWARED BY WWW.INSTANTDIGITS.COM || +9471 999 2075", 100, 293, "center");
  doc.save(`DayFlowStatement.pdf`);
}

export function StockViewPDF(data, metadata) {
  const doc = new jsPDF();

  const y = 0;
  let l = 3; //total 59 line available in the A4

  const lSpace = 5;
  let page = 1;

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(metadata.type, 30, y + l * lSpace, "left");
  doc.text("FIRM CODE : " + metadata.firmCode, 70, y + l * lSpace, "left");
  doc.text("DATE(s) : " + metadata.date, 120, y + l * lSpace, "left");
  l++;
  doc.setFontSize(8);
  doc.text("Page : " + page, 180, y + l * lSpace, "center");
  l++;

  doc.setFontSize(8);
  doc.setFont("helvetica", "bold");
  doc.text("GENERATED AT " + moment().format("YYYY-MM-DD hh:mm:ss a"), 180, 285, "right");
  doc.setTextColor("#0000AF");
  doc.setFontSize(9);
  doc.text("SOFTWARED BY WWW.INSTANTDIGITS.COM || +9471 999 2075", 100, 293, "center");
  doc.setTextColor("black");

  function AddPage() {
    if (l > 54) {
      doc.setFontSize(9);
      doc.setFont("helvetica", "bold");
      doc.text("Continued..", 100, y + l * lSpace, "center");
      doc.addPage();
      l = 3;
      page++;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(metadata.type + "  CONTINUED FROM PAGE " + (page - 1), 30, y + l * lSpace, "left");

      doc.text("DATE(s) : " + metadata.date, 120, y + l * lSpace, "left");
      l++;
      doc.setFontSize(8);

      doc.text("Page : " + page, 180, y + l * lSpace, "center");
      l++;

      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.text("GENERATED AT " + moment().format("YYYY-MM-DD hh:mm:ss a"), 180, 285, "right");
      doc.setTextColor("#0000AF");
      doc.setFontSize(9);
      doc.text("SOFTWARED BY WWW.INSTANTDIGITS.COM || +9471 999 2075", 100, 293, "center");
      doc.setTextColor("black");
    }
  }

  for (const cat in data) {
    if (typeof data[cat] == "object") {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(11);
      doc.text(
        CapitalizeWords(ProductNameDecode(cat)) +
          "  : " +
          data[cat].qts +
          " || " +
          data[cat].weight +
          "g",
        20,
        y + l * lSpace
      );

      l += 1;
      AddPage();

      for (const cat2 in data[cat]) {
        if (typeof data[cat][cat2] == "object") {
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text(CapitalizeWords(ProductNameDecode(cat2)), 25, y + l * lSpace);
          doc.text(ThousandView(data[cat][cat2].qts + "") + " || ", 150, y + l * lSpace, "right");
          doc.text(ThousandView(data[cat][cat2].weight + "") + "g", 150, y + l * lSpace, "left");

          l += 1;
          AddPage();
          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          for (const cat3 in data[cat][cat2]) {
            if (typeof data[cat][cat2][cat3] == "object") {
              doc.setFont("helvetica", data[cat][cat2][cat3].ids ? "bold" : "normal");
              doc.text(CapitalizeWords(ProductNameDecode(cat3)), 30, y + l * lSpace);

              doc.text(
                ThousandView(data[cat][cat2][cat3].qts + "") + " || ",
                175,
                y + l * lSpace,
                "right"
              );
              doc.text(
                ThousandView(data[cat][cat2][cat3].weight + "") + "g",
                175,
                y + l * lSpace,
                "left"
              );
              l += 1;
              AddPage();
              doc.setFont("helvetica", "normal");
              doc.setFontSize(10);
              if (data[cat][cat2][cat3].ids) {
                let c = 0;

                for (const id of data[cat][cat2][cat3].ids) {
                  if (id) {
                    doc.text(
                      id.split("@")[1] + " - " + ProductNameDecode(id.split("@")[0]) + "g",
                      32 + c * 44,
                      y + l * lSpace
                    );
                    c++;
                    if (c > 3) {
                      c = 0;
                      l++;
                      AddPage();
                      doc.setFont("helvetica", "normal");
                      doc.setFontSize(10);
                    }
                  }
                }
                l += 1;
              }
            }
          }
        }
      }
      l++;
    }
  }

  doc.save(`${metadata.type + "_" + metadata.date}.pdf`);
}

export function DayReportGenerator(data, metadata) {
  const doc = new jsPDF();

  const y = 0;
  let l = 3; //total 59 line available in the A4

  const lSpace = 5;
  let page = 1;

  var karadWeight = {};
  var karadPrice = {};
  var reps = {};
  var totals = {};

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("SALES", 30, y + l * lSpace, "left");
  doc.text("FIRM CODE : " + metadata.code, 70, y + l * lSpace, "left");
  doc.text("DATE(s) : " + data.date, 120, y + l * lSpace, "left");
  l++;
  doc.setFontSize(8);
  doc.text("Page : " + page, 180, y + l * lSpace, "center");
  l++;

  doc.setFontSize(8);
  doc.setFont("helvetica", "bold");
  doc.text("GENERATED AT " + moment().format("YYYY-MM-DD hh:mm:ss a"), 180, 285, "right");
  doc.setTextColor("#0000AF");
  doc.setFontSize(9);
  doc.text("SOFTWARED BY WWW.INSTANTDIGITS.COM || +9471 999 2075", 100, 293, "center");
  doc.setTextColor("black");

  function AddPage(type, strick, NoTableHeader) {
    if (l > 54 || strick) {
      doc.setFontSize(9);
      doc.setFont("helvetica", "bold");
      doc.text("Pages Continued..", 100, y + l * lSpace, "center");
      doc.addPage();
      l = 3;
      page++;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(type, 30, y + l * lSpace, "left");
      doc.text("FIRM CODE : " + metadata.code, 70, y + l * lSpace, "left");
      doc.text("DATE(s) : " + data.date, 120, y + l * lSpace, "left");
      l++;
      doc.setFontSize(8);

      doc.text("Page : " + page, 180, y + l * lSpace, "center");
      l++;
      if (!NoTableHeader) {
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text("INV NO.", 10, y + l * lSpace, "left");
        doc.text("CUSTOMER", 30, y + l * lSpace, "left");
        doc.text("ITEMS", 70, y + l * lSpace, "left");
        doc.text("WEIGHT(s)", 145, y + l * lSpace, "right");
        doc.text("PRICE", 165, y + l * lSpace, "right");
        doc.text("TOTAL", 185, y + l * lSpace, "right");
        doc.text("PAYMENT", 205, y + l * lSpace, "right");
      }

      l++;

      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.text("GENERATED AT " + moment().format("YYYY-MM-DD hh:mm:ss a"), 180, 285, "right");
      doc.setTextColor("#0000AF");
      doc.setFontSize(9);
      doc.text("SOFTWARED BY WWW.INSTANTDIGITS.COM || +9471 999 2075", 100, 293, "center");
      doc.setTextColor("black");
      doc.setFont("helvetica", "normal");
    }
  }

  if ("salesItems" in data && Object.values(data["salesItems"]).length > 0) {
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("INV NO.", 10, y + l * lSpace, "left");
    doc.text("CUSTOMER", 30, y + l * lSpace, "left");
    doc.text("ITEMS", 70, y + l * lSpace, "left");
    doc.text("WEIGHT(s)", 145, y + l * lSpace, "right");
    doc.text("PRICE", 165, y + l * lSpace, "right");
    doc.text("TOTAL", 185, y + l * lSpace, "right");
    doc.text("PAYMENT", 205, y + l * lSpace, "right");

    l++;
    doc.setFont("helvetica", "normal");
    for (const invoice of ObjtoArraySortInvoiceSN(data["salesItems"])) {
      AddPage("SALES");
      totals["counts"] = (totals["counts"] || 0) + 1;
      doc.text(totals["counts"] + ")", 9, y + l * lSpace, "right");
      doc.text(invoice["invoiceSN"], 10, y + l * lSpace, "left");
      doc.text(invoice["name"], 30, y + l * lSpace, "left");
      doc.text(ThousandView(invoice["total"] || "0.0"), 185, y + l * lSpace, "right");
      doc.text(ThousandView(invoice["payAmount"] || "0.0"), 205, y + l * lSpace, "right");

      reps[invoice["issuedby"]] = (reps[invoice["issuedby"]] || 0) + Number(invoice["total"]);
      reps[invoice["issuedby"] + "_Counts"] = (reps[invoice["issuedby"] + "_Counts"] || 0) + 1;
      totals["total"] = (totals["total"] || 0) + Number(invoice["total"]);
      totals["payAmount"] = (totals["payAmount"] || 0) + Number(invoice["payAmount"]);

      for (const itemID in invoice["itemList"]) {
        AddPage("SALES");
        let item = invoice["itemList"][itemID];

        let label = item.label.length > 35 ? item.label.slice(0, 35) + "..." : item.label;

        doc.text(label, 70, y + l * lSpace, "left");
        doc.text(item["weight"] + "g", 145, y + l * lSpace, "right");
        doc.text(ThousandView(item["unitPrice"]), 165, y + l * lSpace, "right");

        karadWeight[item["karad"]] = (karadWeight[item["karad"]] || 0) + Number(item["weight"]);
        karadPrice[item["karad"]] = (karadPrice[item["karad"]] || 0) + Number(item["unitPrice"]);
        totals["weight"] = (totals["weight"] || 0) + Number(item["weight"]);

        l++;
      }

      l++;
    }

    l = l - 1;
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("...............", 145, y + l * lSpace, "right");
    doc.text("...................", 185, y + l * lSpace, "right");
    doc.text(".................", 205, y + l * lSpace, "right");
    l++;

    doc.text("GRAND TOTÂL", 30, y + l * lSpace, "left");
    doc.text(ThousandView(totals["weight"].toFixed(3)) + "g", 145, y + l * lSpace, "right");
    doc.text(ThousandView(totals["total"]), 185, y + l * lSpace, "right");
    doc.text(ThousandView(totals["payAmount"]), 205, y + l * lSpace, "right");
    l++;

    if (l + Math.max(Object.keys(karadWeight).length, Object.keys(reps).length) + 5 > 57)
      AddPage("SALES", true, true);
    else l++;
    var boxStart = l;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text("KARAD SALES DETAILS", 142, y + l * lSpace, "left");
    l++;
    doc.setFontSize(10);
    doc.text("KARAD", 140, y + l * lSpace, "left");
    doc.text("WEIGHT(g)", 180, y + l * lSpace, "right");
    doc.text("PRICE", 205, y + l * lSpace, "right");
    l++;
    doc.setFont("helvetica", "normal");

    for (const karad in karadWeight) {
      doc.text(karad, 140, y + l * lSpace, "left");
      doc.text(ThousandView(karadWeight[karad].toFixed(2) || "N.A"), 180, y + l * lSpace, "right");
      doc.text(ThousandView(karadPrice[karad] || "N.A"), 205, y + l * lSpace, "right");
      l++;
    }

    l = boxStart;

    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text("SALES REP PERFORMANCE", 20, y + l * lSpace, "left");
    l++;
    doc.setFontSize(10);
    doc.text("NAME", 20, y + l * lSpace, "left");
    doc.text("COUNTS", 80, y + l * lSpace, "right");
    doc.text("TOTAL SALES", 85, y + l * lSpace, "left");

    l++;
    doc.setFont("helvetica", "normal");

    for (const name in reps) {
      if (!name.includes("Counts")) {
        doc.text(name, 20, y + l * lSpace, "left");
        doc.text(reps[name + "_Counts"] + "", 80, y + l * lSpace, "right");
        doc.text(ThousandView(reps[name].toFixed(2) || "N.A"), 90, y + l * lSpace, "left");
        l++;
      }
    }
  }
  if ("purchaseItems" in data && Object.values(data["purchaseItems"]).length > 0) {
    karadPrice = {};
    karadWeight = {};
    reps = {};
    totals = {};

    AddPage("PURCHASE", true);
    doc.setFont("helvetica", "normal");
    for (const invoice of ObjtoArraySortInvoiceSN(data["purchaseItems"])) {
      totals["counts"] = (totals["counts"] || 0) + 1;
      doc.text(totals["counts"] + ")", 9, y + l * lSpace, "right");
      doc.text(invoice["invoiceSN"], 10, y + l * lSpace, "left");
      doc.text(invoice["name"], 30, y + l * lSpace, "left");
      doc.text(ThousandView(invoice["total"]), 185, y + l * lSpace, "right");
      doc.text(ThousandView(invoice["payAmount"] || "N.A"), 205, y + l * lSpace, "right");
      reps[invoice["issuedby"]] = (reps[invoice["issuedby"]] || 0) + Number(invoice["total"]);
      reps[invoice["issuedby"] + "_Counts"] = (reps[invoice["issuedby"] + "_Counts"] || 0) + 1;
      totals["total"] = (totals["total"] || 0) + Number(invoice["total"]);
      totals["payAmount"] = (totals["payAmount"] || 0) + Number(invoice["payAmount"]);
      for (const itemID in invoice["itemList"]) {
        AddPage("PURCHASE");
        let item = invoice["itemList"][itemID];

        let label = item.label.length > 25 ? item.label.slice(0, 25) + "..." : item.label;

        doc.text(label, 70, y + l * lSpace, "left");
        doc.text(item["weight"] + "g, " + item["actWeight"] + "g", 145, y + l * lSpace, "right");
        doc.text(ThousandView(item["unitPrice"]), 165, y + l * lSpace, "right");

        karadWeight[item["karad"]] = (karadWeight[item["karad"]] || 0) + Number(item["actWeight"]);
        karadPrice[item["karad"]] = (karadPrice[item["karad"]] || 0) + Number(item["unitPrice"]);
        totals["weight"] = (totals["weight"] || 0) + Number(item["actWeight"]);
        l++;
      }

      l++;
    }

    l = l - 1;
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("...............", 145, y + l * lSpace, "right");
    doc.text("...................", 185, y + l * lSpace, "right");
    doc.text(".................", 205, y + l * lSpace, "right");
    l++;

    doc.text("GRAND TOTÂL", 30, y + l * lSpace, "left");
    doc.text(ThousandView(totals["weight"].toFixed(3)) + "g", 145, y + l * lSpace, "right");
    doc.text(ThousandView(totals["total"]), 185, y + l * lSpace, "right");
    doc.text(ThousandView(totals["payAmount"]), 205, y + l * lSpace, "right");
    l++;

    if (l + Math.max(Object.keys(karadWeight).length, Object.keys(reps).length) + 5 > 57)
      AddPage("PURCHASE", true, true);
    else l++;
    boxStart = l;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text("KARAD PURCHASE DETAILS", 142, y + l * lSpace, "left");
    l++;
    doc.setFontSize(10);
    doc.text("KARAD", 140, y + l * lSpace, "left");
    doc.text("WEIGHT(g)", 180, y + l * lSpace, "right");
    doc.text("PRICE", 205, y + l * lSpace, "right");
    l++;
    doc.setFont("helvetica", "normal");

    for (const karad in karadWeight) {
      doc.text(karad, 140, y + l * lSpace, "left");
      doc.text(ThousandView(karadWeight[karad].toFixed(2) || "N.A"), 180, y + l * lSpace, "right");
      doc.text(ThousandView(karadPrice[karad] || "N.A"), 205, y + l * lSpace, "right");
      l++;
    }

    l = boxStart;

    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text("REP PERFORMANCE", 20, y + l * lSpace, "left");
    l++;
    doc.setFontSize(10);
    doc.text("NAME", 20, y + l * lSpace, "left");
    doc.text("COUNTS", 80, y + l * lSpace, "right");
    doc.text("TOTAL PURCHASE", 85, y + l * lSpace, "left");

    l++;
    doc.setFont("helvetica", "normal");

    for (const name in reps) {
      if (!name.includes("Counts")) {
        doc.text(name, 20, y + l * lSpace, "left");
        doc.text(reps[name + "_Counts"] + "", 80, y + l * lSpace, "right");
        doc.text(ThousandView(reps[name].toFixed(2) || "N.A"), 90, y + l * lSpace, "left");
        l++;
      }
    }
  }

  if ("orderItems" in data && Object.values(data["orderItems"]).length > 0) {
    AddPage("ORDERS", true);
    doc.setFont("helvetica", "normal");
    let orI = 1;
    for (const invoice of ObjtoArraySortInvoiceSN(data["orderItems"])) {
      AddPage("ORDERS");
      // let invoice = data["orderItems"][invoiceID];
      doc.text(orI + ")", 9, y + l * lSpace, "right");
      orI = orI + 1;
      doc.text(invoice["invoiceSN"], 10, y + l * lSpace, "left");
      doc.text(invoice["name"], 30, y + l * lSpace, "left");
      doc.text(ThousandView(invoice["total"]), 185, y + l * lSpace, "right");
      doc.text(ThousandView(invoice["payAmount"] || "N.A"), 205, y + l * lSpace, "right");

      for (const itemID in invoice["itemList"]) {
        AddPage("ORDERS");
        let item = invoice["itemList"][itemID];

        let label = item.label.length > 25 ? item.label.slice(0, 25) + "..." : item.label;

        doc.text(label, 70, y + l * lSpace, "left");
        doc.text(item["weight"] + "g", 145, y + l * lSpace, "right");
        doc.text(ThousandView(item["unitPrice"]), 165, y + l * lSpace, "right");
        l++;
      }

      l++;
    }
  }

  doc.save(`${"DayReport_" + data.date}.pdf`);
}

export function GenereteSoldItemsReport(data, metadata) {
  function formatTheReport(data) {
    let out = {};

    Object.values(data["salesItems"]).map((invoice) => {
      Object.keys(invoice["itemList"]).map((id) => {
        let path = id.split("_");
        let item = invoice["itemList"][id];
        if (path.length > 3) {
          let cat = id.includes("Goldsmith") ? "Goldsmith" : path.slice(0, 2).join("-");

          let itemDetails = path[3].split("@");

          out[cat] = [
            ...(out[cat] || []),
            {
              label: path[2] + " (INV - " + invoice.invoiceSN + ")",
              qr: itemDetails[1],
              weight: item.weight,
              unitPrice: item.unitPrice,
              rep: invoice.issuedby,
            },
          ];
        } else {
          out["Attached Items"] = [
            ...(out["Attached Items"] || []),
            {
              label: item.label + " (INV - " + invoice.invoiceSN + ")",
              qr: "N.A",
              weight: item.weight,
              unitPrice: item.unitPrice,
              rep: invoice.issuedby,
            },
          ];
        }
      });
    });
    return out;
  }

  let formatedOut = sortObject(formatTheReport(data));

  const doc = new jsPDF();

  const y = 0;
  let l = 3; //total 59 line available in the A4

  const lSpace = 5;
  let page = 1;

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("SOLD ITEMS STATEMENT", 15, y + l * lSpace, "left");
  doc.text("FIRM CODE : " + metadata.code, 85, y + l * lSpace, "left");
  doc.text("DATE(s) : " + data.date, 140, y + l * lSpace, "left");
  l++;
  doc.setFontSize(8);
  doc.text("Page : " + page, 180, y + l * lSpace, "center");
  l++;

  doc.setFontSize(8);
  doc.setFont("helvetica", "bold");
  doc.text("GENERATED AT " + moment().format("YYYY-MM-DD hh:mm:ss a"), 180, 285, "right");
  doc.setTextColor("#0000AF");
  doc.setFontSize(9);
  doc.text("SOFTWARED BY WWW.INSTANTDIGITS.COM || +9471 999 2075", 100, 293, "center");
  doc.setTextColor("black");

  function AddPage() {
    if (l > 54) {
      doc.setFontSize(9);
      doc.setFont("helvetica", "bold");
      doc.text("Pages Continued..", 100, y + l * lSpace, "center");
      doc.addPage();
      l = 3;
      page++;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("SOLD ITEMS Cont'd", 15, y + l * lSpace, "left");
      doc.text("FIRM CODE : " + metadata.code, 85, y + l * lSpace, "left");
      doc.text("DATE(s) : " + data.date, 140, y + l * lSpace, "left");
      l++;
      doc.setFontSize(8);

      doc.text("Page : " + page, 180, y + l * lSpace, "center");
      l++;

      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text("ITEMS", 20, y + l * lSpace, "left");
      doc.text("QR CODE", 85, y + l * lSpace, "left");
      doc.text("WEIGHT(g)", 135, y + l * lSpace, "right");
      doc.text("PRICE", 165, y + l * lSpace, "right");
      doc.text("SALES REP", 175, y + l * lSpace, "left");

      l++;

      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.text("GENERATED AT " + moment().format("YYYY-MM-DD hh:mm:ss a"), 180, 285, "right");
      doc.setTextColor("#0000AF");
      doc.setFontSize(9);
      doc.text("SOFTWARED BY WWW.INSTANTDIGITS.COM || +9471 999 2075", 100, 293, "center");
      doc.setTextColor("black");
      doc.setFont("helvetica", "normal");
    }
  }

  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text("ITEMS", 20, y + l * lSpace, "left");
  doc.text("QR CODE", 85, y + l * lSpace, "left");
  doc.text("WEIGHT(g)", 135, y + l * lSpace, "right");
  doc.text("PRICE", 165, y + l * lSpace, "right");
  doc.text("SALES REP", 175, y + l * lSpace, "left");
  l++;
  for (const cat in formatedOut) {
    AddPage();
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(cat, 15, y + l * lSpace, "left");
    l++;
    for (const itemID in formatedOut[cat]) {
      AddPage();
      let item = formatedOut[cat][itemID];
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text(item.label, 20, y + l * lSpace, "left");
      doc.text(item.qr, 85, y + l * lSpace, "left");
      doc.text(item.weight + "g", 135, y + l * lSpace, "right");
      doc.text(ThousandView(item.unitPrice), 165, y + l * lSpace, "right");
      doc.text(item.rep, 175, y + l * lSpace, "left");
      l++;
      // console.log(item);
    }
    l++;
  }
  doc.save(`${"SoldItemsOn" + data.date}.pdf`);
}

export function GenereteCustomerReport(data, metadata) {
  const doc = new jsPDF();

  const y = 0;
  let l = 3; //total 59 line available in the A4

  const lSpace = 5;
  let page = 1;

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("CUSTOMERS STATEMENT", 15, y + l * lSpace, "left");
  doc.text("FIRM CODE : " + metadata.code, 85, y + l * lSpace, "left");

  l++;
  doc.setFontSize(8);
  doc.text("Page : " + page, 180, y + l * lSpace, "center");
  l++;

  doc.setFontSize(8);
  doc.setFont("helvetica", "bold");
  doc.text("GENERATED AT " + moment().format("YYYY-MM-DD hh:mm:ss a"), 180, 285, "right");
  doc.setTextColor("#0000AF");
  doc.setFontSize(9);
  doc.text("SOFTWARED BY WWW.INSTANTDIGITS.COM || +9471 999 2075", 100, 293, "center");
  doc.setTextColor("black");

  function AddPage() {
    if (l > 54) {
      doc.setFontSize(9);
      doc.setFont("helvetica", "bold");
      doc.text("Pages Continued..", 100, y + l * lSpace, "center");
      doc.addPage();
      l = 3;
      page++;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("CUSTOMERS STATEMENT Cont'd", 15, y + l * lSpace, "left");
      doc.text("FIRM CODE : " + metadata.code, 85, y + l * lSpace, "left");

      l++;
      doc.setFontSize(8);

      doc.text("Page : " + page, 180, y + l * lSpace, "center");
      l++;

      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text("NAME", 10, y + l * lSpace, "left");
      doc.text("NIC", 50, y + l * lSpace, "left");
      doc.text("PHONE/ADDRESS", 77, y + l * lSpace, "left");
      doc.text("OUTSTANDINGs", 115, y + l * lSpace, "left");
      doc.text("ORDERS", 170, y + l * lSpace, "left");

      l++;

      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.text("GENERATED AT " + moment().format("YYYY-MM-DD hh:mm:ss a"), 180, 285, "right");
      doc.setTextColor("#0000AF");
      doc.setFontSize(9);
      doc.text("SOFTWARED BY WWW.INSTANTDIGITS.COM || +9471 999 2075", 100, 293, "center");
      doc.setTextColor("black");
      doc.setFont("helvetica", "normal");
    }
  }

  function AddBox({ width, text, listOfText, x, lineStart, align, lineSpace, maxLine }) {
    function chunkSubstr(str, size) {
      const numChunks = Math.ceil(str.length / size);
      const chunks = new Array(numChunks);

      for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
        chunks[i] = str.substr(o, size);
      }

      return chunks;
    }

    let boxStart = lineStart;
    let lineList =
      listOfText ||
      (maxLine ? chunkSubstr(text, width).slice(0, maxLine) : chunkSubstr(text, width));

    lineList.forEach((line) => {
      doc.text(line, x, y + boxStart * lSpace, align || "left");
      boxStart = boxStart + (lineSpace || 1);
    });

    return boxStart - lineStart + 1;
  }

  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text("NAME", 10, y + l * lSpace, "left");
  doc.text("NIC", 50, y + l * lSpace, "left");
  doc.text("PHONE/ADDRESS", 77, y + l * lSpace, "left");
  doc.text("OUTSTANDINGs", 115, y + l * lSpace, "left");
  doc.text("ORDERS", 170, y + l * lSpace, "left");
  // doc.text("PURCHASES", 185, y + l * lSpace, "left");
  l++;

  let i = 0;

  function ArrangeBasedOustanding(list) {
    return (list || []).sort(function (a, b) {
      return b.outstanding - a.outstanding;
    });
  }

  let cusOustandingArange = ArrangeBasedOustanding(Object.values(data));
  for (const cusID in cusOustandingArange) {
    AddPage();

    i++;
    doc.setTextColor("black");
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    let customer = cusOustandingArange[cusID];
    // doc.text(CapitalizeWords(customer.name), 10, y + l * lSpace, "left");
    doc.text(i + ". ", 10, y + l * lSpace, "right");
    let c1 = AddBox({
      width: 23,
      text: CapitalizeWords(customer.name),
      x: 10,
      lineStart: l,
      lineSpace: 0.7,
      maxLine: 2,
    });

    doc.text(customer.NIC, 50, y + l * lSpace, "left");
    doc.setTextColor(
      customer.NICimages == 2 ? "green" : customer.NICimages == 1 ? "orange" : "red"
    );
    doc.setFontSize(7);
    doc.setFont("helvetica", "bold");
    doc.text(
      customer.NICimages == 2
        ? "Both Sides Scanned"
        : customer.NICimages == 1
        ? "Single Side Scanned"
        : "No Scan Avaliable",
      50,
      y + (l + 0.7) * lSpace,
      "left"
    );
    doc.setFontSize(9);
    doc.setTextColor("black");
    doc.setFont("helvetica", "normal");

    doc.text(customer.phone, 77, y + l * lSpace, "left");
    doc.setFontSize(8);
    c1 = Math.max(
      c1,
      AddBox({
        width: 24,
        text: CapitalizeWords(customer.address),
        x: 77,
        lineStart: l + 0.6,
        lineSpace: 0.6,
        maxLine: 2,
      })
    );
    doc.setFontSize(9);

    if (customer.outstanding) {
      doc.setTextColor("red");
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text(ThousandView(customer.outstanding), 115, y + l * lSpace, "left");
      doc.setFont("helvetica", "normal");
      doc.setTextColor("black");
      c1 = Math.max(
        c1,
        AddBox({
          listOfText: (customer.salesPending || []).map(
            (inv) =>
              moment(inv.timeStamp, "YYYY-MM-DD HH:mm:ss_SSS").format("DD-MM, hh:mm") +
              "| " +
              inv.invoiceSN +
              "| " +
              ThousandView(inv.balance)
          ),
          x: 115,
          lineStart: l + 0.7,
          lineSpace: 0.7,
        })
      );
    } else {
      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.text("No Outstanding", 115, y + l * lSpace, "left");
    }

    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");

    if (customer.orderBalances.length > 0) {
      doc.setTextColor("orange");
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("Pending Orders", 165, y + l * lSpace, "left");
      doc.setFont("helvetica", "normal");
      doc.setTextColor("black");
      doc.setFontSize(7);
      c1 = Math.max(
        c1,
        AddBox({
          listOfText: customer.orderBalances.map(
            (inv) =>
              moment(inv.timeStamp, "YYYY-MM-DD HH:mm:ss_SSS").format("DD-MM, hh:mm") +
              "| " +
              inv.invoiceSN +
              "| " +
              ThousandView(inv.totalCash)
          ),
          x: 165,
          lineStart: l + 0.7,
          lineSpace: 0.6,
        })
      );
    } else {
      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.text("No Pending Orders", 165, y + l * lSpace, "left");
    }

    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    l = l + Math.max(c1, 2);
    // break;
  }

  doc.save(`${"CustomerReport" + moment().format("YYYY-MM-DD")}.pdf`);
}
