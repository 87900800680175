import { StyleSheet } from "react-native";

const Styles = StyleSheet.create({
  billHeader: {
    width: "95%",
    marginTop:10,
    flexDirection: "row",
    maxWidth: "1400px",
    backgroundColor: '#0000AF',
    zIndex: -1,
  },
  billFooter: {
    width: "95%",
    flexDirection: "row",
    maxWidth: "1400px",
    backgroundColor: '#0000AF',
    zIndex: -1,
  },
  billHeaderTextCommon: {
    fontSize: "max(2vmin, 14px)",
    textAlign: "center",
    color: "white",
    borderRightWidth: 1,
    fontWeight: "bold",
    borderColor: "white",
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
  },
  billFooterTextCommon: {
    fontSize: "max(2.5vmin, 14px)",
    textAlign: "center",
    color: "white",
    borderRightWidth: 1,
    fontWeight: "bold",
    borderColor: "white",
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
  },
  billItem: {
    width: "95%",
    flexDirection: "row",
    maxWidth: "1400px",
    backgroundColor: "white",
    zIndex: -1,
  },
  billItemText: {
    fontSize: "max(2vmin, 14px)",
    textAlign: "center",
    color: "black",
    borderRightWidth: 1,
    // fontWeight: "bold",
    borderColor: "white",
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
  },
});

export default Styles;
