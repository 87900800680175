import React, { useEffect, useState, useCallback } from "react";
import {
  Modal,
  Image,
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
  Pressable,
  TextInput,
  Keyboard,
} from "react-native";
import moment from "moment";
import { useAlert } from "react-alert";

import { useSelector, useDispatch } from "react-redux";
import { AiFillDelete, AiFillPrinter } from "react-icons/ai";

import { ThousandView, ProductNameDecode, StackFinder } from "../../Functions/Functions";
import Styles from "./Styles";

import { tree, treeSubText } from "../../Fonts/FontSizes";

import DatesBoundPicker from "../../Componets/Modals/DatesBoundPickers";

import ItemAdder from "../../Componets/Modals/ItemAdder";

// import { ViewImage } from "../Modals/Modals";

import GeneratePDF, { StockViewPDF } from "../../PDFGenerators/Jspdftest";

import {
  GetProductDetails,
  DeleteFromProductTree,
  SetPrintJob,
  DeleteCatFromTree,
} from "../../Firebase/Main";
import KaradDetailModal from "./ViewDetails";

const filterColor = ["transparent", "green", "red", "#AD75EF"];
const filterLabel = ["ALL", "INSTOCK", "SOLD", "NEWLY ADDED"];

export default function App(props) {
  const [initData, setInitData] = useState(false);
  const dispatch = useDispatch();
  const root = useSelector((state) => state.data.productTree); //the tree
  const vendorProduct = useSelector((state) => state.data.vendorProduct); //the tree

  const userData = useSelector((state) => state.data.userData);

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(false);
  const [datePick, setDatePick] = useState(false);
  const [filterType, setFilterType] = useState("INSTOCK");
  const [viewKaradDetails, setKaradDetails] = useState(false);

  const [showCats, setShowCats] = useState([]);
  const [showMakes, setShowMakes] = useState([]);
  const [showSubMakes, setSubShowMakes] = useState([]);
  const [filterIndex, setFilterIndex] = useState(1);
  const firmInfo = useSelector((state) => state.data.firmInfo);
  const [filterKarad, setFilterKarad] = useState();
  const [tempKarad, setTempKarad] = useState();
  const [karadInput, setkaradInput] = useState(false);
  const [addCat, setAddCat] = useState(false);
  const [path, setPath] = useState([]);
  const alert = useAlert();

  //permisions
  const showWeight =
    userData.uid === firmInfo.masterID ||
    (["admin"].includes(userData.type) && filterType == "NEWLY ADDED");

  //const [initData, setInitialData] = useState(false);

  const [showCatGram, setShowCatGram] = useState("");
  const [showItemGram, setShowItemGrame] = useState("");

  const currentMoment = moment();

  const relatieveDate = (date) => {
    const timeStampMoment = moment(date, "YYYY-MM-DD HH:mm:ss_SSS");
    if (currentMoment.subtract(2, "days") < timeStampMoment) return timeStampMoment.calendar();
    return timeStampMoment.format("YYYY-MM-DD");
  };

  function countChild(obj, type, karad, filterType1) {
    let tot = 0;
    Object.keys(obj).map((keys) => {
      if (itemFilter(keys, obj[keys], karad, filterType1) && type == "G")
        tot = tot + Number(ProductNameDecode(keys.split("@")[0]));
      else if (itemFilter(keys, obj[keys], karad, filterType1)) tot = tot + 1;
    });

    return tot;
  }

  function itemFilter(item, value, karad, filterType1) {
    if (item == "AddedOn") return false;

    let itemDetails = item.split("@");
    let valueDetail = Number(value) ? Number(value) : (value || "").split("@");
    let itemKarad = ProductNameDecode(itemDetails[2] || ""); //|| "22";

    let karadFilter =
      karad || filterKarad
        ? Number(itemKarad) == Number(ProductNameDecode(karad || filterKarad || "22"))
        : true;

    let filterType2 = filterType1 || filterType;

    // if (
    //   (value >= date &&
    //     // (!endDate || endDate >= value) &&
    //     valueDetail[valueDetail.length - 1] != "Deleted") ||
    //   Number(value)
    // )
    if (valueDetail[valueDetail.length - 1] != "Deleted")
      switch (filterType2) {
        case "ALL":
          return value >= date && (!endDate || endDate >= value) && karadFilter;
        case "INSTOCK":
          if (endDate) {
            return endDate >= itemDetails[3] && (Number(value) > 0 || valueDetail[0] >= endDate);
          }
          return Number(value) > 0 && karadFilter;
        case "SOLD":
          return (
            karadFilter &&
            value >= date &&
            (!endDate || endDate >= value) &&
            !Number(value) &&
            (valueDetail.length == 1 || valueDetail[valueDetail.length - 1] == "Sold")
          );
        case "RETAKE":
          return (
            karadFilter &&
            value >= date &&
            (!endDate || endDate >= value) &&
            !Number(value) &&
            valueDetail[valueDetail.length - 1] == "Retake"
          );
      }

    let isAddDateUser =
      karadFilter &&
      valueDetail[valueDetail.length - 1] != "Deleted" &&
      itemDetails[3] >= date &&
      (itemDetails[4] == userData.userName || userData.uid == firmInfo.masterID || filterType1) &&
      (!endDate || endDate >= itemDetails[3]);

    switch (filterType2) {
      case "NEWLY ADDED":
        return isAddDateUser;
      case "INTERNAL QR":
        return isAddDateUser && itemDetails[1].includes(firmInfo.code);
      case "EXTERNAL QR":
        return isAddDateUser && !itemDetails[1].includes(firmInfo.code);
      case "DELETED":
        return (
          valueDetail[valueDetail.length - 1] == "Deleted" &&
          valueDetail[0] >= date &&
          (!endDate || endDate >= valueDetail[0]) &&
          karadFilter
        );
    }

    return false;
  }

  function countChildFromMake(obj, type, karad, filterType1) {
    let tot = 0;
    Object.values(obj).map((value) => {
      if (typeof value == "object") tot = tot + countChild(value, type, karad, filterType1);
    });
    return tot;
  }

  function countChildFromCat(obj, type, karad, filterType1) {
    let tot = 0;
    Object.values(obj).map((value) => {
      if (typeof value == "object") tot = tot + countChildFromMake(value, type, karad, filterType1);
    });
    return tot;
  }

  function CatToGram(obj) {
    let temp = {};
    Object.keys(obj).map((cat) => {
      Object.keys(obj[cat]).map((gram) => {
        if ((filterIndex == 1 && Number(obj[cat][gram])) || filterIndex == 0) {
          let aprox = Math.ceil(Number(ProductNameDecode(gram.split("@")[0])));
          let ceilPoint = aprox + (aprox % 2);

          let key = ("0" + Number(ceilPoint - 2)).slice(-2) + "-" + ("0" + ceilPoint).slice(-2);

          temp[key] = {
            ...temp[key],
            [gram]: cat,
          };
        }
      });
    });
    return Object.keys(temp)
      .sort()
      .reduce((obj, key) => {
        obj[key] = temp[key];
        return obj;
      }, {});
  }

  function ItemsToGram(obj) {
    let temp2 = {};
    Object.keys(obj).map((gram) => {
      if ((filterIndex == 1 && Number(obj[gram])) || filterIndex == 0) {
        let aprox = Math.ceil(Number(ProductNameDecode(gram.split("@")[0])));
        let ceilPoint = aprox + (aprox % 2);

        let key = ("0" + Number(ceilPoint - 2)).slice(-2) + "-" + ("0" + ceilPoint).slice(-2);

        temp2[key] = (temp2[key] || 0) + 1;
      }
    });

    return Object.keys(temp2)
      .sort()
      .reduce((obj, key) => {
        obj[key] = temp2[key];
        return obj;
      }, {});
  }

  function treeToPrint(printRoot, isIdPrint) {
    let printTree = {
      type: filterType,
    };

    let count = 0;

    Object.keys(printRoot).map((cat) => {
      if (cat != "AddedOn" && cat != "Goldsmith") {
        let tempCat = {
          qts: countChildFromCat(printRoot[cat], false, false, false),
          weight: countChildFromCat(printRoot[cat], "G", false, false).toFixed(2),
        };
        count = count + tempCat.qts;
        if (Number(tempCat.qts)) {
          //let temm
          Object.keys(printRoot[cat]).map((make) => {
            if (make != "AddedOn") {
              let tempMake = {
                qts: countChildFromMake(printRoot[cat][make], false, false, false),
                weight: countChildFromMake(printRoot[cat][make], "G", false, false).toFixed(2),
              };
              if (Number(tempMake.weight)) {
                Object.keys(printRoot[cat][make]).map((subMake) => {
                  if (subMake != "AddedOn" && (showMakes.length > 0 || isIdPrint)) {
                    let tempSubMake = {
                      qts: countChild(printRoot[cat][make][subMake]),
                      weight: countChild(printRoot[cat][make][subMake], "G").toFixed(2),
                      ids: isIdPrint
                        ? Object.keys(printRoot[cat][make][subMake])
                            .sort(function (a, b) {
                              return (
                                a.split("@")[1].replace(/^\D+/g, "") -
                                b.split("@")[1].replace(/^\D+/g, "")
                              );
                            })
                            .map((key) =>
                              itemFilter(key, printRoot[cat][make][subMake][key])
                                ? ProductNameDecode(key)
                                : false
                            )
                        : false,
                    };
                    if (Number(tempSubMake.weight)) tempMake[subMake] = tempSubMake;
                  }
                });
                tempCat[make] = tempMake;
              }
            }
          });

          printTree[cat] = tempCat;
        }
      }
    });

    return [printTree, count];
  }

  function printStock(path, isIdPrint) {
    let printRoot = path
      ? path.length < 2
        ? { [path[0]]: StackFinder(root, path) }
        : { [path[0]]: { [path[1]]: StackFinder(root, path) } }
      : root;
    let [printData, count] = treeToPrint(printRoot, isIdPrint);

    StockViewPDF(printData, {
      type: filterType,
      firmCode: firmInfo.code,
      date: date + (endDate ? " to " + endDate : ""),
    });

    // if (isIdPrint && count > 1000)
    //   return alert('Sorry!\n you can"t print selections which is consist of No of IDS above 500');

    // Object.keys(printData).length > 0
    //   ? SetPrintJob({
    //       printerType: "stockPrinter",
    //       printReceipt: {
    //         ...printData,
    //         firmCode: firmInfo.code,
    //         printedOn: endDate ? date + " to " + endDate : date,
    //       },
    //       typeUpdate: false,
    //     }).then(() => alert("Printing Job has submitted"))
    //   : alert("There is no item to print");
  }

  console.log();

  function loadProductData(id) {
    GetProductDetails({ id: id }).then((details) => {
      setInitData({ ...details, todayPricePerG: firmInfo["22K"] });
    });
  }

  function itemShow(path, item, value, index) {
    let tempDate = relatieveDate(value);
    let items = ProductNameDecode(item).split("@");

    let isIn = Number(value) || tempDate == "Invalid date";
    return (
      <TouchableOpacity
        style={[
          Styles.treeItem,
          {
            backgroundColor:
              items[3] >= date && items[4] == userData.userName ? "#D7BAFA" : "white",
          },
        ]}
        onLongPress={() => loadProductData(path.join("_") + "_" + item)}
        key={index}
      >
        <Text style={[Styles.treeItemText, { width: isIn ? "80%" : "40%" }]}>
          {items[0] + (items.length > 1 ? "g - " + items[1] : " g")}{" "}
          {items[3] >= date && items[4] == userData.userName && "   : Newly Added"}
        </Text>

        {isIn ? (
          <>
            <Text style={[Styles.treeItemText, { color: "green" }]}>{value}</Text>

            <TouchableOpacity
              onPress={() =>
                alert.info("Need a longer press to delete the item and you need to be an admin")
              }
              onLongPress={() =>
                userData.type == "admin" &&
                items[4] == userData.userName &&
                items[0] >= moment().format("YYYY-MM-DD")
                  ? DeleteFromProductTree({ id: path.join("_") + "_" + item }).then(() =>
                      alert.success("The product deleted successfully")
                    )
                  : firmInfo.masterID == userData.uid
                  ? DeleteFromProductTree({ id: path.join("_") + "_" + item }).then(() =>
                      alert.success("The product deleted successfully")
                    )
                  : alert.error(
                      "Sorry! you need to be an admin or Director for this action, However the director has the full power"
                    )
              }
              style={{ marginLeft: 20 }}
            >
              <AiFillDelete size={tree} color={"red"} />
            </TouchableOpacity>
          </>
        ) : (
          <Text
            style={[
              {
                width: "50%",
                color: "red",
                fontSize: 16,
                paddingLeft: 2,
                fontWeight: "bold",
              },
            ]}
            numberOfLines={2}
          >
            OUT {tempDate.toLocaleUpperCase()}
          </Text>
        )}
      </TouchableOpacity>
    );
  }

  function todayStockStatement() {
    let printTree = {};

    Object.keys(root).map((cat) => {
      if (cat != "AddedOn" && cat != "Goldsmith") {
        let tempCat = {
          count: {
            start: {
              qts: countChildFromCat(root[cat], false, false, 0),
              weight: countChildFromCat(root[cat], "G", false, 0).toFixed(2),
            },
            added: {
              qts: countChildFromCat(root[cat], false, false, "NEWLY ADDED"),
              weight: countChildFromCat(root[cat], "G", false, "NEWLY ADDED").toFixed(2),
            },
            final: {
              qts: countChildFromCat(root[cat], false, false, "INSTOCK"),
              weight: countChildFromCat(root[cat], "G", false, "INSTOCK").toFixed(2),
            },
            sold: {
              qts: countChildFromCat(root[cat], false, false, "SOLD"),
              weight: countChildFromCat(root[cat], "G", false, "SOLD").toFixed(2),
            },
          },
        };

        //let temm
        Object.keys(root[cat]).map((make) => {
          if (make != "AddedOn") {
            let tempMake = {
              start: {
                qts: countChildFromMake(root[cat][make], false, false, 0),
                weight: countChildFromMake(root[cat][make], "G", false, 0).toFixed(2),
              },
              added: {
                qts: countChildFromMake(root[cat][make], false, false, "NEWLY ADDED"),
                weight: countChildFromMake(root[cat][make], "G", false, "NEWLY ADDED").toFixed(2),
              },
              final: {
                qts: countChildFromMake(root[cat][make], false, false, "INSTOCK"),
                weight: countChildFromMake(root[cat][make], "G", false, "INSTOCK").toFixed(2),
              },
              sold: {
                qts: countChildFromMake(root[cat][make], false, false, "SOLD"),
                weight: countChildFromMake(root[cat][make], "G", false, "SOLD").toFixed(2),
              },
            };

            tempCat[make] = tempMake;
          }
        });

        printTree[cat] = tempCat;
      }
    });

    return printTree;
  }

  // console.log(todayStockStatement());

  //console.log(treeToPrint());
  return (
    <>
      {addCat && (
        <ItemAdder
          type={addCat}
          path={path}
          close={() => {
            setAddCat(false);
            setPath([]);
          }}
          itemAdded={(qr) => dispatch({ type: "ADDEDITEM_PUSH", payLoad: qr })}
          //initialData={itemData}
          //items={parents}
        />
      )}

      {datePick && (
        <DatesBoundPicker
          startDate={date}
          endDate={endDate}
          setStartDate={setDate}
          setEndDate={setEndDate}
          close={() => setDatePick(false)}
        />
      )}

      <View style={Styles.viewTag}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: 5,
            paddingTop: 5,
            justifyContent: "center",
          }}
        >
          {/* <View style={{ width: "20%", marginLeft: "5%", marginRight: "5%" }}>
            <View style={[Styles.buttonView, { backgroundColor: "white" }]} onPress={props.setType}>
              <Text style={[Styles.buttonText, props.editEnable && { color: "white" }]}>
                {"STOCK ON TODAY"}
              </Text>
            </View>
          </View> */}

          {filterLabel.map((data, index) => (
            <TouchableOpacity
              key={index}
              style={[
                Styles.buttonView,
                { backgroundColor: "white", width: "15%", marginLeft: "1%" },
                {
                  backgroundColor: index == filterIndex ? filterColor[filterIndex] : "white",
                },
                (index != filterIndex || filterIndex == 0) && {
                  borderColor: "white",
                  borderWidth: 1,
                },
              ]}
              onPress={() => {
                setFilterType(data);
                setFilterIndex(index);
              }}
            >
              <Text
                style={[Styles.buttonText, { color: index == filterIndex ? "white" : "black" }]}
              >
                {data}
              </Text>
            </TouchableOpacity>
          ))}

          <TouchableOpacity
            style={[Styles.buttonView, { width: "20%", marginLeft: "1%" }]}
            onPress={() => {
              setDatePick(true);
            }}
            onLongPress={() => {
              setDate(moment().format("YYYY-MM-DD"));
              setEndDate(false);
            }}
          >
            <Text style={[Styles.buttonText, { color: "black" }]}>
              {endDate || date != moment().format("YYYY-MM-DD")
                ? date + " --> " + (endDate || "")
                : "PICK DATES"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      <ScrollView style={{ width: "100%" }} showsVerticalScrollIndicator={false}>
        <View
          style={{
            width: "95%",
            flexDirection: "column",
            maxWidth: "1200px",
            alignSelf: "center",
            margin: 10,
            zIndex: -1,
          }}
        >
          {root &&
            Object.keys(root).map(
              (cat, index) =>
                cat != "Goldsmith" && (
                  <View key={index} style={{ alignItems: "flex-start", width: "100%" }}>
                    <TouchableOpacity
                      onPress={() => {
                        let index = showCats.indexOf(cat);
                        if (index > -1)
                          setShowCats([...showCats.slice(0, index), ...showCats.slice(index + 1)]);
                        else setShowCats([...showCats, cat]);
                      }}
                      style={[Styles.treeHeadingMake]}
                      onLongPress={() => setKaradDetails([cat])}
                    >
                      <Text style={Styles.treeHeadingMakeText}>
                        {ProductNameDecode(cat).toUpperCase()}
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          setAddCat("CAT");
                          setPath([cat]);
                        }}
                      >
                        <Text
                          style={[
                            Styles.treeHeadingItemText,
                            {
                              borderLeftWidth: 1,
                              borderColor: "white",
                              paddingLeft: 10,
                              marginLeft: 20,
                              minWidth: 40,
                              // fontSize: 14,
                            },
                          ]}
                        >
                          + CAT
                        </Text>
                      </TouchableOpacity>
                      <Text
                        style={[
                          Styles.treeHeadingItemText,
                          {
                            borderLeftWidth: 1,
                            borderColor: "white",
                            paddingLeft: 10,
                            marginLeft: 20,
                            minWidth: 40,
                            // fontSize: 14,
                          },
                        ]}
                      >
                        {ThousandView(countChildFromCat(root[cat]))}
                      </Text>
                      <Text
                        style={[
                          Styles.treeHeadingItemText,
                          {
                            borderLeftWidth: 1,
                            borderColor: "white",
                            paddingLeft: 10,
                            // fontSize: 14,
                          },
                        ]}
                      >
                        W{" "}
                        {showWeight
                          ? ThousandView(countChildFromCat(root[cat], "G").toFixed(2))
                          : "*****"}
                        g
                      </Text>
                      <TouchableOpacity
                        style={{ position: "absolute", right: 10 }}
                        onPress={() => {
                          root[cat].hasOwnProperty("AddedOn")
                            ? DeleteCatFromTree([cat])
                            : alert.error(
                                `For Security reasons! you have to delete every products in ${cat.toUpperCase()} to delete the category`
                              );
                        }}
                      >
                        <AiFillDelete
                          size={"max(3vmin, 20px)"}
                          color={root[cat].hasOwnProperty("AddedOn") ? "white" : "#FC9560"}
                        />
                      </TouchableOpacity>
                    </TouchableOpacity>

                    {Object.keys(root[cat]).map((make, index) => {
                      return (
                        showCats.includes(cat) &&
                        cat != "AddedOn" && (
                          <View key={index}>
                            {make.length > 0 && make != "AddedOn" && (
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <TouchableOpacity
                                  onPress={() => {
                                    let index = showMakes.indexOf(make);
                                    if (index > -1)
                                      setShowMakes([
                                        ...showMakes.slice(0, index),
                                        ...showMakes.slice(index + 1),
                                      ]);
                                    else setShowMakes([...showMakes, make]);
                                  }}
                                  style={[
                                    Styles.treeHeadingItem,
                                    {
                                      paddingTop: 5,
                                      paddingBottom: 5,
                                      backgroundColor: "#0000AF",
                                      flexDirection: "row",
                                      marginLeft: 50,
                                      // width: "60%",
                                    },
                                  ]}
                                  onLongPress={() => setKaradDetails([cat, make])}
                                >
                                  <Text style={[Styles.treeMakeText]} numberOfLines={2}>
                                    {ProductNameDecode(make).toUpperCase()}
                                  </Text>
                                  <TouchableOpacity
                                    // style={{ position: "absolute", right: 10 }}
                                    onPress={() => {
                                      root[cat][make].hasOwnProperty("AddedOn")
                                        ? DeleteCatFromTree([cat, make])
                                        : alert.error(
                                            `For Security reasons! you have to delete every products in ${make.toUpperCase()} to delete the category`
                                          );
                                    }}
                                  >
                                    <AiFillDelete
                                      size={"max(3vmin, 20px)"}
                                      color={
                                        root[cat][make].hasOwnProperty("AddedOn")
                                          ? "white"
                                          : "#3E3ED2"
                                      }
                                    />
                                  </TouchableOpacity>
                                  <TouchableOpacity
                                    onPress={() => {
                                      setAddCat("CAT");
                                      setPath([cat, make]);
                                    }}
                                  >
                                    <Text style={[Styles.treeHeadingItemText, ,]}>+ CAT</Text>
                                  </TouchableOpacity>
                                  <Text style={[Styles.treeHeadingItemText, ,]}>
                                    {countChildFromMake(root[cat][make])}
                                  </Text>
                                  <Text
                                    style={[
                                      Styles.treeHeadingItemText,
                                      {
                                        borderLeftWidth: 1,
                                        borderColor: "white",
                                        paddingLeft: 10,

                                        paddingRight: 10,
                                      },
                                    ]}
                                  >
                                    W:{" "}
                                    {showWeight
                                      ? countChildFromMake(root[cat][make], "G").toFixed(2)
                                      : "****"}
                                    g
                                  </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  onPress={() => {
                                    printStock([cat, make], true);
                                  }}
                                >
                                  <AiFillPrinter color="red" size={tree * 1.2} />
                                </TouchableOpacity>
                              </View>
                            )}
                            {Object.keys(
                              showCatGram == cat + "_" + make
                                ? CatToGram(root[cat][make])
                                : root[cat][make]
                            ).map((subMake, index) => {
                              //CatToGram(root[cat][make]);

                              let childObj =
                                showCatGram == cat + "_" + make
                                  ? CatToGram(root[cat][make])
                                  : root[cat][make];

                              return (
                                showMakes.includes(make) &&
                                subMake != "AddedOn" && (
                                  <View key={index}>
                                    {make.length > 0 && (
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <TouchableOpacity
                                          onPress={() => {
                                            let index = showSubMakes.indexOf(subMake);
                                            if (index > -1)
                                              setSubShowMakes([
                                                ...showSubMakes.slice(0, index),
                                                ...showSubMakes.slice(index + 1),
                                              ]);
                                            else setSubShowMakes([...showSubMakes, subMake]);
                                          }}
                                          onLongPress={() => setKaradDetails([cat, make, subMake])}
                                          style={[
                                            Styles.treeHeadingItem,
                                            {
                                              paddingTop: 3,
                                              paddingBottom: 3,
                                              marginLeft: 100,
                                              // width: "60%",
                                            },
                                          ]}
                                        >
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Text
                                              style={[Styles.treeMakeTextSub]}
                                              numberOfLines={2}
                                            >
                                              {ProductNameDecode(subMake).toUpperCase()}
                                            </Text>
                                            <TouchableOpacity
                                              // style={{ position: "absolute", right: 10 }}
                                              onPress={() => {
                                                root[cat][make][subMake].hasOwnProperty("AddedOn")
                                                  ? DeleteCatFromTree([cat, make, subMake])
                                                  : alert.error(
                                                      `For Security reasons! you have to delete every products in ${subMake.toUpperCase()} to delete the category`
                                                    );
                                              }}
                                            >
                                              <AiFillDelete
                                                size={"max(3vmin, 20px)"}
                                                color={
                                                  root[cat][make][subMake].hasOwnProperty("AddedOn")
                                                    ? "white"
                                                    : "#55AEF5"
                                                }
                                              />
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                              onPress={() => {
                                                if (
                                                  firmInfo.bulkHandler &&
                                                  ((StackFinder(vendorProduct, [
                                                    cat,
                                                    make,
                                                    subMake,
                                                  ]) &&
                                                    StackFinder(vendorProduct, [cat, make, subMake])
                                                      .quantity < 1) ||
                                                    !StackFinder(vendorProduct, [
                                                      cat,
                                                      make,
                                                      subMake,
                                                    ]))
                                                )
                                                  return alert.error(
                                                    "Sorry! \nVendor Bucket is empty\nBucket Path : \n" +
                                                      [cat, make, subMake].join("/ ")
                                                  );
                                                else {
                                                  setAddCat("ITEM");
                                                  setPath([cat, make, subMake]);
                                                }
                                              }}
                                            >
                                              <Text
                                                style={[
                                                  Styles.treeHeadingItemText,
                                                  {
                                                    borderLeftWidth: 1,
                                                    borderColor: "white",

                                                    minWidth: 25,
                                                    textAlign: "right",
                                                  },
                                                ]}
                                              >
                                                + ITEM
                                              </Text>
                                            </TouchableOpacity>
                                            <Text
                                              style={[
                                                Styles.treeHeadingItemText,
                                                {
                                                  borderLeftWidth: 1,
                                                  borderColor: "white",

                                                  minWidth: 25,
                                                  textAlign: "right",
                                                },
                                              ]}
                                            >
                                              {countChild(childObj[subMake])}
                                            </Text>
                                            <Text
                                              style={[
                                                Styles.treeHeadingItemText,
                                                {
                                                  borderLeftWidth: 1,
                                                  borderColor: "white",
                                                  paddingLeft: 10,

                                                  paddingRight: 10,
                                                  minWidth: 80,
                                                },
                                              ]}
                                            >
                                              {ThousandView(
                                                countChild(childObj[subMake], "G").toFixed(2)
                                              )}
                                              g
                                            </Text>
                                          </View>
                                        </TouchableOpacity>

                                        {/* {showCatGram != cat + "_" + make && (
                                      <TouchableOpacity
                                        onPress={() => {
                                          setShowItemGrame(
                                            showItemGram ==
                                              cat + "_" + make + "_" + subMake
                                              ? false
                                              : cat + "_" + make + "_" + subMake
                                          );
                                          //setShowMakes([make]);
                                        }}
                                      >
                                        <Text
                                          style={[
                                            Styles.treeHeadingItemText,
                                            {
                                              borderWidth: 1,
                                              borderColor: "#2196F3",
                                              color: "#2196F3",
                                              paddingLeft: 4,
                                              paddingRight: 4,
                                              borderRadius: 4,
                                            },
                                          ]}
                                        >
                                          {showItemGram ==
                                          cat + "_" + make + "_" + subMake
                                            ? "Gram"
                                            : "Cat"}
                                        </Text>
                                      </TouchableOpacity>
                                    )} */}
                                      </View>
                                    )}
                                    <ScrollView>
                                      {Object.keys(
                                        showItemGram == cat + "_" + make + "_" + subMake
                                          ? ItemsToGram(root[cat][make][subMake])
                                          : childObj[subMake]
                                      ).map((item, index) => {
                                        let value =
                                          showItemGram == cat + "_" + make + "_" + subMake
                                            ? ItemsToGram(root[cat][make][subMake])[item]
                                            : childObj[subMake][item];

                                        return (
                                          itemFilter(item, value) && // (((Number(value) || relatieveDate(value) == "Invalid date") && filterIndex < 2) ||(value >= date && filterIndex != 1))
                                          showSubMakes.includes(subMake) &&
                                          itemShow([cat, make, subMake], item, value, index)
                                        );
                                      })}
                                    </ScrollView>
                                  </View>
                                )
                              );
                            })}
                          </View>
                        )
                      );
                    })}
                  </View>
                )
            )}
          <TouchableOpacity
            onPress={() => {
              setAddCat("CAT");
              // setPath([cat]);
            }}
            style={[
              {
                minWidth: 50,
                // height: 50,
                marginTop: 15,
                paddingVertical: 5,
                borderRadius: 10,
                backgroundColor: filterKarad ? "green" : "#B90505", //"#FFAC30",
                //alignSelf: "flex-end",
                marginLeft: 10,
                width: "20%",
              },
            ]}
          >
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={[
                  Styles.treeHeadingCatText,
                  {
                    marginLeft: 0,
                    color: "white",
                    marginRight: 20,
                    textAlign: "center",
                  },
                ]}
              >
                + MAIN CAT
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </ScrollView>

      {/* <StatusBar style="auto" /> */}
      {karadInput ? (
        <View
          style={[
            Styles.PDbutton,
            {
              backgroundColor: "#0000AF",
              width: "100%",
              position: "relative",
              bottom: 0,
            },
          ]}
        >
          <View style={{ width: "70%" }}>
            <TextInput
              autoFocus={true}
              value={tempKarad}
              onChangeText={(e) => {
                if (Number(e) >= 0) setTempKarad(e);
                if (Number(e) == 0) setFilterKarad();
              }}
              paddingLeft={12}
              placeholderTextColor="#706767"
              style={Styles.textInput}
              placeholder="Set View Karat"
              keyboardType="numeric"
            />
          </View>

          <TouchableOpacity
            style={[Styles.button, Styles.pinChildAddButton]}
            onPress={() => {
              setkaradInput(false);
              setFilterKarad(tempKarad);
            }}
          >
            <Text style={[Styles.buttonText]}>SET</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <View
          style={[
            {
              minWidth: 50,
              position: "absolute",
              bottom: 5,
              right: 10,
            },
          ]}
        >
          <TouchableOpacity
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              paddingVertical: 7,
              backgroundColor: "#B90505",
              borderRadius: 25,
              marginBottom: 5,
            }}
            onPress={() =>
              showWeight
                ? printStock()
                : alert.error("Sorry! you don't have the permission to print this view")
            }
          >
            <Text
              style={[
                {
                  color: "white",
                  marginHorizontal: tree * 1.2,
                  textAlign: "center",
                  fontSize: tree,
                },
              ]}
            >
              PRINT THE VIEW
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              paddingVertical: 7,
              backgroundColor: "#B90505",
              borderRadius: 25,
            }}
            onPress={() =>
              ["admin"].includes(userData.type)
                ? GeneratePDF(todayStockStatement(), {
                    firmCode: firmInfo.code,
                    date: date,
                    type: "DAY STOCK STATEMENT",
                  })
                : alert.error("Sorry you dont have permission")
            }
          >
            <Text
              style={[
                {
                  color: "white",
                  marginHorizontal: tree * 1.2,
                  textAlign: "center",
                  fontSize: tree,
                },
              ]}
            >
              TODAY STOCK STATEMENT{/* {filterKarad ? filterKarad : "All"} K */}
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </>
  );
}
