import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  Pressable,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Keyboard,
  StyleSheet,
  ScrollView,
} from "react-native";
import DatePicker from "react-datepicker";
import moment, { max, min } from "moment";
import { useSelector, useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import "./DatesBoundPickers.css";
// import DayRereceiptsLoader from "../../Screens/RealtimeLoaders/DayReceiptLoader";
import { tree } from "../../Fonts/FontSizes";

import {
  DayReportGenerator,
  GenerateFlowStatementPDF,
  GenereteCustomerReport,
  GenereteSoldItemsReport,
} from "../../PDFGenerators/Jspdftest";
import { dbTree } from "../../Firebase/Config";
import { useAlert } from "react-alert";
import { DayRegenareteReort, CustomerLoadDB } from "./RegerateDay";
import { ThousandView } from "../../Functions/Functions";

function onDateChange(date) {
  const selectDate =
    date.getFullYear() +
      "-" +
      ("0" + Number(date.getMonth() + 1).toLocaleString()).slice(-2) +
      "-" +
      ("0" + date.getDate().toLocaleString()).slice(-2) || null;

  return selectDate;
}

export default function GoldManger(props) {
  const firmInfo = useSelector((state) => state.data.firmInfo);
  const userData = useSelector((state) => state.data.userData);
  const alert = useAlert();

  const [date, setDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)));
  const [pickedBal, setPickedBal] = useState(false);

  const [pickedcashBal, setPickedCashBal] = useState(false);

  const [pickedSoldBal, setPickedSoldBal] = useState(false);

  const todayBal = useSelector((state) => state.data.todayReceipts);

  function cashInflow(balance) {
    return (
      (balance.cashSales.payMethod.Cash || 0) +
      (balance["A_C"].advance.Cash || 0) +
      (balance.paidSales.payMethod.Cash || 0) +
      (balance.otherIncome.payMethod.Cash || 0) +
      (balance.order.advance.Cash || 0) +
      (balance.paidOrder.payMethod.Cash || 0) +
      (todayBal.paidSMS.payMethod.Cash || 0)
    );
  }

  function cashOutflow(balance) {
    return (
      (balance.purchase.advance.Cash || 0) +
      (balance.payout || 0) +
      (balance["expense"].payMethod.Cash || 0) +
      (balance.bank.payMethod.Cash || 0) +
      (balance.savings.payMethod.Cash || 0) +
      (balance.pays.payMethod.Cash || 0)
    );
  }

  const cashFlowObject = (bal) => {
    return {
      date: bal.date,
      initialCashBalance: bal.InitialCashBalance || 0,
      cashInFlows: [
        {
          label: "Fully Paid Sales",
          cash: bal.cashSales.payMethod.Cash || 0,
          total: bal.cashSales.total || 0,
          info: "Tot. Rs." + ThousandView(bal.cashSales.total),
        },
        {
          label: "Account Sales",
          cash: bal["A_C"].advance.Cash || 0,
          total: (bal["A_C"].total || 0) - (bal["A_C"].outstanding || 0),
          info:
            "Tot. Rs." +
            ThousandView(bal["A_C"].total) +
            ", Out. Rs." +
            ThousandView(bal["A_C"].outstanding || 0),
        },
        {
          label: "Account Payment Received",
          cash: bal.paidSales.payMethod.Cash || 0,
          total: bal.paidSales.total || 0,
          info: false,
        },
        {
          label: "Order",
          cash: bal["order"].advance.Cash || 0,
          total: (bal["order"].total || 0) - (bal["order"].outstanding || 0),
          info: "Tot. Rs." + ThousandView(bal["order"].total),
        },
        {
          label: "Order Payment Received",
          cash: bal.paidOrder.payMethod.Cash || 0,
          total: bal.paidOrder.total || 0,
          info: false,
        },
        {
          label: "SMS Payment Received",
          cash: bal.paidSMS.payMethod.Cash || 0,
          total: bal.paidSMS.total || 0,
          info: false,
        },
        {
          label: "Other Income",
          cash: bal.otherIncome.payMethod.Cash || 0,
          total: bal.otherIncome.total || 0,
          info: false,
        },
      ],
      cashIn: cashInflow(bal),

      cashOutFlows: [
        {
          label: "Customer Purchase",
          cash: bal.purchase.advance.Cash || 0,
          total: bal.purchase.total || 0,
          info: "Tot. Rs." + ThousandView(bal.purchase.total),
        },
        {
          label: "Expense",
          cash: bal["expense"].payMethod.Cash || 0,
          total: bal["expense"].total,
          info: false,
        },
        {
          label: "Bank Deposited",
          cash: bal.bank.payMethod.Cash || 0,
          total: bal.bank.total || 0,
          info: false,
        },
        {
          label: "Other Savings",
          cash: bal.savings.payMethod.Cash || 0,
          total: bal.savings.total || 0,
          info: false,
        },
        {
          label: "Other Payment",
          cash: bal.pays.payMethod.Cash || 0,
          total: bal.pays.total || 0,
          info: false,
        },
      ],
      cashOut: cashOutflow(bal),

      balance: bal.InitialCashBalance + cashInflow(bal) - cashOutflow(bal),
    };
  };

  function LoadingBalances(date) {
    let todayDataRef = dbTree.ref(`/${userData.firmID}/ReceiptsSummary/${date}`);
    todayDataRef.once("value", function (snapshot) {
      if (snapshot.val() && snapshot.val().cashSales && snapshot.val().cashSales.payMethod) {
        setPickedBal({ ...snapshot.val() });
        DayReportGenerator({ ...snapshot.val() }, firmInfo);
        alert.success("The Report Loaded ");
      } else alert.info("Sorry! Nothing found, You may try recalculate");
    });
  }

  function LoadingCashflowBalances(date) {
    let todayDataRef = dbTree.ref(`/${userData.firmID}/ReceiptsSummary/${date}`);
    todayDataRef.once("value", function (snapshot) {
      if (snapshot.val() && snapshot.val().cashSales && snapshot.val().cashSales.payMethod) {
        setPickedCashBal({ ...snapshot.val() });
        GenerateFlowStatementPDF(cashFlowObject(snapshot.val()));
        alert.success("The Report Loaded ");
      } else alert.info("Sorry! Nothing found, You may try recalculate");
    });
  }

  function LoadingSoldItemsBalances(date) {
    let todayDataRef = dbTree.ref(`/${userData.firmID}/ReceiptsSummary/${date}`);
    todayDataRef.once("value", function (snapshot) {
      if (snapshot.val() && snapshot.val().cashSales && snapshot.val().cashSales.payMethod) {
        setPickedSoldBal({ ...snapshot.val() });
        GenereteSoldItemsReport(snapshot.val(), { code: firmInfo.code });
        alert.success("The Report Loaded ");
      } else alert.info("Sorry! Nothing found, You may try recalculate");
    });
  }
  return (
    <>
      <ScrollView style={{ width: "100%" }} showsVerticalScrollIndicator={false}>
        <View style={{ alignItems: "center", marginBottom: "20vh" }}>
          <Text style={[Styles.buttonText, { color: "black", marginTop: 30 }]}>
            {"SALES, PURCHASE & ORDER REPORT"}
          </Text>

          <View style={Styles.viewTag}>
            <TouchableOpacity
              style={[Styles.buttonView, { width: "40%", marginLeft: "5%", marginVertical: 10 }]}
              onPress={() => {
                DayReportGenerator(todayBal, firmInfo);
              }}
            >
              <Text style={[Styles.buttonText, { color: "black" }]}>{"TODAY REPORT"}</Text>
            </TouchableOpacity>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 5,
                marginLeft: "5%",
                paddingTop: 5,
                marginVertical: 10,
                // justifyContent: "center",
              }}
            >
              <TouchableOpacity
                style={[Styles.buttonView, { backgroundColor: "white", width: "15%" }]}
                onPress={() => {}}
              >
                <Text style={[Styles.buttonText]}>{"REPORT ON"}</Text>
              </TouchableOpacity>

              <View
                style={[
                  {
                    width: "30%",
                    marginLeft: "3%",
                    justifyContent: "center",
                    fontSize: "max(3vmin, 20px)",
                  },
                ]}
              >
                <DatePicker
                  selected={date}
                  onChange={(date) => {
                    setDate(date);
                    setPickedBal(false);
                  }}
                  // style={{ width: "100%" }}
                  // className="react-datepicker"
                  wrapperClassName="datePicker"
                />
              </View>

              <TouchableOpacity
                style={[
                  Styles.buttonView,
                  { width: "20%", marginLeft: "5%" },
                  pickedBal && { backgroundColor: "green" },
                ]}
                onPress={() => {
                  if (!pickedBal) {
                    alert.info("pls wait...");
                    LoadingBalances(onDateChange(date));
                  }
                }}
              >
                <Text style={[Styles.buttonText, { color: pickedBal ? "white" : "black" }]}>
                  {pickedBal ? "LOADED" : "LOAD"}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={[
                  Styles.buttonView,
                  { width: "20%", marginLeft: "2%" },
                  // pickedBal && { backgroundColor: "green" },
                ]}
                onPress={() => {
                  alert.info("pls wait...");
                  // pickedBal && LoadingBalances(onDateChange(date));
                  DayRegenareteReort(onDateChange(date), userData.firmID).then((data) => {
                    if (data) {
                      DayReportGenerator({ ...data }, firmInfo);
                      alert.success("The Report Re-Generated ");
                    } else alert.error("No records found");
                  });
                }}
                activeOpacity={pickedBal}
              >
                <Text style={[Styles.buttonText, { color: "black" }]}>{"RECALCULATE"}</Text>
              </TouchableOpacity>
            </View>
          </View>

          <Text style={[Styles.buttonText, { color: "black", marginTop: 30 }]}>
            {"CASHFLOW AND NETFLOW REPORT"}
          </Text>

          <View style={[Styles.viewTag, { zIndex: -1 }]}>
            <TouchableOpacity
              style={[Styles.buttonView, { width: "40%", marginLeft: "5%", marginVertical: 10 }]}
              onPress={() => {
                GenerateFlowStatementPDF(cashFlowObject(todayBal));
              }}
            >
              <Text style={[Styles.buttonText, { color: "black" }]}>{"TODAY FLOW STATEMENT"}</Text>
            </TouchableOpacity>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 5,
                marginLeft: "5%",
                paddingTop: 5,
                marginVertical: 10,
                // justifyContent: "center",
              }}
            >
              <TouchableOpacity
                style={[Styles.buttonView, { backgroundColor: "white", width: "15%" }]}
                onPress={() => {}}
              >
                <Text style={[Styles.buttonText]}>{"REPORT ON"}</Text>
              </TouchableOpacity>

              <View
                style={[
                  {
                    width: "30%",
                    marginLeft: "3%",
                    justifyContent: "center",
                    fontSize: "max(3vmin, 20px)",
                  },
                ]}
              >
                <DatePicker
                  selected={date}
                  onChange={(date) => {
                    setDate(date);
                    setPickedCashBal(false);
                  }}
                  // style={{ width: "100%" }}
                  // className="react-datepicker"
                  wrapperClassName="datePicker"
                />
              </View>

              <TouchableOpacity
                style={[
                  Styles.buttonView,
                  { width: "20%", marginLeft: "5%" },
                  pickedcashBal && { backgroundColor: "green" },
                ]}
                onPress={() => {
                  if (!pickedcashBal) {
                    alert.info("pls wait...");
                    LoadingCashflowBalances(onDateChange(date));
                  }
                }}
              >
                <Text style={[Styles.buttonText, { color: pickedcashBal ? "white" : "black" }]}>
                  {pickedcashBal ? "LOADED" : "LOAD"}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Styles.buttonView,
                  { width: "20%", marginLeft: "2%" },
                  // pickedBal && { backgroundColor: "green" },
                ]}
                onPress={() => {
                  alert.info("pls wait...");
                  // pickedBal && LoadingBalances(onDateChange(date));
                  DayRegenareteReort(onDateChange(date), userData.firmID).then((data) => {
                    if (data) {
                      GenerateFlowStatementPDF(cashFlowObject(data));
                      alert.success("The Report Re-Generated with recalculated amounts ");
                    } else alert.error("No records found");
                  });
                }}
              >
                <Text style={[Styles.buttonText, { color: "black" }]}>{"RECALCULATE"}</Text>
              </TouchableOpacity>
            </View>
          </View>

          <Text style={[Styles.buttonText, { color: "black", marginTop: 30 }]}>
            {"SOLD ITEMS REPORT"}
          </Text>
          <View style={[Styles.viewTag, { zIndex: -1 }]}>
            <TouchableOpacity
              style={[Styles.buttonView, { width: "40%", marginLeft: "5%", marginVertical: 10 }]}
              onPress={() => {
                alert.info("Pls Wait...");
                GenereteSoldItemsReport(todayBal, { code: firmInfo.code });
              }}
            >
              <Text style={[Styles.buttonText, { color: "black" }]}>{"TODAY SOLD ITEMS"}</Text>
            </TouchableOpacity>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 5,
                marginLeft: "5%",
                paddingTop: 5,
                marginVertical: 10,
                // justifyContent: "center",
              }}
            >
              <TouchableOpacity
                style={[Styles.buttonView, { backgroundColor: "white", width: "15%" }]}
                onPress={() => {}}
              >
                <Text style={[Styles.buttonText]}>{"REPORT ON"}</Text>
              </TouchableOpacity>

              <View
                style={[
                  {
                    width: "30%",
                    marginLeft: "3%",
                    justifyContent: "center",
                    fontSize: "max(3vmin, 20px)",
                  },
                ]}
              >
                <DatePicker
                  selected={date}
                  onChange={(date) => {
                    setDate(date);
                    setPickedSoldBal(false);
                  }}
                  // style={{ width: "100%" }}
                  // className="react-datepicker"
                  wrapperClassName="datePicker"
                />
              </View>

              <TouchableOpacity
                style={[
                  Styles.buttonView,
                  { width: "20%", marginLeft: "5%" },
                  pickedSoldBal && { backgroundColor: "green" },
                ]}
                onPress={() => {
                  if (!pickedSoldBal) {
                    alert.info("pls wait...");
                    LoadingSoldItemsBalances(onDateChange(date));
                  }
                }}
              >
                <Text style={[Styles.buttonText, { color: pickedSoldBal ? "white" : "black" }]}>
                  {pickedSoldBal ? "LOADED" : "LOAD"}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Styles.buttonView,
                  { width: "20%", marginLeft: "2%" },
                  // pickedBal && { backgroundColor: "green" },
                ]}
                onPress={() => {
                  alert.info("pls wait...");
                  // pickedBal && LoadingBalances(onDateChange(date));
                  DayRegenareteReort(onDateChange(date), userData.firmID).then((data) => {
                    if (data) {
                      GenereteSoldItemsReport(data, { code: firmInfo.code });
                      alert.success("The Report Re-Generated with Recalculations.");
                    } else alert.error("No records found");
                  });
                }}
              >
                <Text style={[Styles.buttonText, { color: "black" }]}>{"RECALCULATE"}</Text>
              </TouchableOpacity>
            </View>
          </View>

          <Text style={[Styles.buttonText, { color: "black", marginTop: 30 }]}>
            {"CUSTOMER REPORT"}
          </Text>
          <View style={[Styles.viewTag, { zIndex: -1, alignItems: "center" }]}>
            <TouchableOpacity
              style={[Styles.buttonView, { width: "40%", marginVertical: 10 }]}
              onPress={() => {
                if (moment().format("dddd") != "Sunday")
                  return alert.info("Sorry! The report only available at Sundays");
                else if (firmInfo.customerReport == moment().format("YYYY-MM-DD"))
                  return alert.info("Sorry! You have already downloaded the report for this week!");

                alert.info("Pls Wait...");
                CustomerLoadDB(userData.firmID).then((data) => {
                  alert.success("The Report Generated Sunceffuly");
                  GenereteCustomerReport(data, { code: firmInfo.code });
                });
              }}
            >
              <Text style={[Styles.buttonText, { color: "black" }]}>
                {"GENERATE / Only available at Sundays"}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </>
  );
}

const Styles = StyleSheet.create({
  titleText: {
    color: "#2F2EE1",
    borderColor: "#2F2EE1",
    borderBottomWidth: 2,
    marginLeft: -400,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontWeight: "bold",
    fontSize: "max(14px, 4vmin)",
    marginTop: "1%",
    marginBottom: "1%",
  },
  viewTag: {
    width: "95%",
    marginTop: "1%",
    paddingTop: "min(0.5%, 5px)",
    paddingBottom: "min(0.5%, 5px)",
    maxWidth: "1400px",
    borderRadius: "1vmin",
    backgroundColor: "#6AC0FF",
    justifyContent: "center",
  },
  buttonView: {
    width: "100%",
    maxWidth: "1400px",
    fontSize: "max(1.4vmin, 10px)",
    padding: "7px",
    borderRadius: 10,
    backgroundColor: "#FFAC30",
    textAlign: "center",
    fontWeight: "bold",
  },
  buttonText: {
    fontSize: "max(2.5vmin, 16px)",
    textAlign: "center",
    fontWeight: "bold",
  },
  modelTextInput: {
    // margin: "13px",
    width: "50%",
    fontSize: "max(3.3vmin, 20px)",

    // backgroundColor: "white",
    // borderRadius: 3,
    // borderColor: "#757474",
    // borderWidth: 1,
    marginLeft: 0,
  },
});
