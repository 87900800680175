import { StyleSheet, useWindowDimensions, Dimensions } from "react-native";

import { button, modelText, windowWidth } from "../../Fonts/FontSizes";

export const styles = StyleSheet.create({
  modalCenterdView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  modelTextInput: {
    margin: "13px",
    width: "100%",
    fontSize: "max(3.3vmin, 20px)",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: 3,
    borderColor: "#757474",
    borderWidth: 1,
    marginLeft: 0,
  },
  modalView: {
    width: "80%",
    maxWidth: "650px",
    backgroundColor: "white",
    borderRadius: 20,
    padding: "min(20px,3%)",
    paddingLeft: "min(3%)",
    paddingRight: "min(3%)",
    alignItems: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },

  button: {
    backgroundColor: "#FFAC30",
    borderRadius: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  buttonText: {
    fontSize: "3vmin",
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
  },
  modalHeadingText: {
    fontSize: button,
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
  },
  modalLabelText: {
    fontSize: button * 0.9,
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
  },
  imageContainer: {
    height: "80vh",
    width:'100%'
  },
});

export default styles;
