import Firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyAQYIOwflAg1P_v--d8fK2YjZeXKN0qQ_Y",
  authDomain: "gold-pos-plus.firebaseapp.com",
  databaseURL: "https://gold-pos-plus-default-rtdb.firebaseio.com",
  projectId: "gold-pos-plus",
  storageBucket: "gold-pos-plus.appspot.com",
  messagingSenderId: "30077862297",
  appId: "1:30077862297:web:25e277d95420d2757b9308",
};

// const fire = !Firebase.apps.length
//   ? Firebase.initializeApp(firebaseConfig)
//   : Firebase.app(); //Firebase.initializeApp(firebaseConfig);

var fire;
try {
  fire = Firebase.app("Main");
} catch (err) {
  fire = Firebase.initializeApp(firebaseConfig, "Main");
}

export const db = fire.firestore();

export const dbTree = fire.database();
export const dbStorage = fire.storage();
