import React, { Component, useState, useCallback, useTheme } from "react";
import { Text, View, ScrollView } from "react-native";

import Styles from "./Styles";
import ItemTag from "./ItemTag";
import { ThousandView, TotalBillList, StackFinder } from "../../Functions/Functions";
import { useSelector } from "react-redux";
import { ViewImage } from "../Modals/Modals";

function App(props) {
  const root = useSelector((state) => state.data.productTree);
  const [showImage, setShowImage] = useState(false);

  return (
    <>
      {showImage && <ViewImage image={showImage} close={() => setShowImage(false)} />}
      <ScrollView style={{ width: "100%", zIndex: -1 }}>
        <View style={{ alignItems: "center" }}>
          <View style={Styles.billHeader}>
            <Text style={[Styles.billHeaderTextCommon, { width: "49%" }]}>DESCRIPTION</Text>
            <Text style={[Styles.billHeaderTextCommon, { width: "12%" }]}>QTS.</Text>
            <Text style={[Styles.billHeaderTextCommon, { width: "12%" }]}>WEIGHT (g)</Text>
            <Text style={[Styles.billHeaderTextCommon, { width: "12%" }]}>KARAD</Text>
            {/* <Text style={[Styles.billHeaderTextCommon, { width: "14%" }]}>PURCHASE %</Text> */}
            <Text style={[Styles.billHeaderTextCommon, { width: "15%" }]}>PRICE</Text>
          </View>

          {Object.values(props.billItems).map((data, index) => (
            <ItemTag
              item={data}
              key={index}
              inStock={StackFinder(root, data.id.split("_"))}
              index={index}
              onShowImage={setShowImage}
              onUpdate={props.onUpdate}
              viewStatus={props.viewStatus}
              onDelete={props.onDelete}
              submitted={props.submitted}
            />
          ))}
        </View>
      </ScrollView>
      <View style={Styles.billFooter}>
        <Text style={[Styles.billFooterTextCommon, { width: "75%" }]}>TOTAL</Text>
        <Text style={[Styles.billFooterTextCommon, { width: "25%" }]}>
          {ThousandView(TotalBillList(props.billItems))}
        </Text>
      </View>
    </>
  );
}

export default App;
