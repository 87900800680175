import moment from "moment";
import { StackFinder, SearchInList } from "../Functions/Functions";

import { db, dbTree } from "./Config";

import {
  UpdateStakeholderPayment,
  StakeholderBalanceHandler,
  StakeholderBalanceHandlerDelete,
} from "./Stakeholders";

import { inventoryUpdate, ItemsPurchaseAvgSet } from "./Inventory";

var userDataGlobal;

//-----NON REALTIME LOADING/READING ---------------------------------------------------------------
export function UserDataUpdate_Receipts(userData) {
  userDataGlobal = userData;
}

async function InvoiceIncrement() {
  const userRef = db.collection(`/userAccounts/`).doc(userDataGlobal.uid);
  await userRef.set(
    {
      invoiceSN: userDataGlobal.invoiceSN ? Number(userDataGlobal.invoiceSN) + 1 : 1,
    },
    { merge: true }
  );
}

export async function LoadReceiptData(timeStamp) {
  const docSnapshot = await db
    .collection(`/${userDataGlobal.firmID}/Receipts/AllReceipts/`)
    .doc(timeStamp)
    .get();

  return docSnapshot.data();
}

export async function LoadReceiptsMetaData({ type, nameID, asd, date, limit, issuedUID }) {
  var docSnapshot = null;
  if (nameID && issuedUID)
    docSnapshot = await db
      .collection(`/${userDataGlobal.firmID}/Receipts/AllReceipts/`)
      .where("type", Array.isArray(type) ? "in" : "==", type)
      .where("nameID", "==", nameID)
      .where("issuedUID", "==", issuedUID)
      .orderBy("timeStamp", asd ? "asc" : "desc")
      .startAt(date)
      .limit(limit)
      .get();
  else if (nameID)
    docSnapshot = await db
      .collection(`/${userDataGlobal.firmID}/Receipts/AllReceipts/`)
      .where("type", Array.isArray(type) ? "in" : "==", type)
      .where("nameID", "==", nameID)
      .orderBy("timeStamp", asd ? "asc" : "desc")
      .startAt(date)
      .limit(limit)
      .get();
  else if (issuedUID)
    docSnapshot = await db
      .collection(`/${userDataGlobal.firmID}/Receipts/AllReceipts/`)
      .where("type", Array.isArray(type) ? "in" : "==", type)
      .where("issuedUID", "==", issuedUID)
      .orderBy("timeStamp", asd ? "asc" : "desc")
      .startAt(date)
      .limit(limit)
      .get();
  else
    docSnapshot = await db
      .collection(`/${userDataGlobal.firmID}/Receipts/AllReceipts/`)
      .where("type", Array.isArray(type) ? "in" : "==", type)
      .orderBy("timeStamp", asd ? "asc" : "desc")
      .startAt(date)
      .limit(limit)
      .get();

  let outData = docSnapshot.docs.map((doc) => {
    let data = doc.data();
    return Object.assign({
      ...data,
      type: data.deletedType || data.type,
    });
  });
  return outData;
}

export async function UpdateReceipt(data, prevBill) {
  const resceipt = db
    .collection(`/${userDataGlobal.firmID}/Receipts/AllReceipts/`)
    .doc(data.timeStamp);
  const res2 = await resceipt.set(data, { merge: true });

  // !prevBill && InvoiceIncrement();

  inventoryUpdate({ billItems: data.itemList, increment: true });
  data.balance > 0 &&
    data.nameID &&
    UpdateStakeholderPayment({
      cusId: data.nameID,
      total: data.balance,
      type: "add",
      supType: "Suppliers",
    });
  // switch (data.type.split("_")[0]) {
  //   case "Purchase":
  //     data.balance > 0 &&
  //       data.nameID &&
  //       StakeholderBalanceHandler({
  //         receipt: data,
  //         supType: "Customers",
  //       });
  //     return;

  //   case "Order":
  //     data.nameID &&
  //       StakeholderBalanceHandler({
  //         receipt: data,
  //         supType: "Customers",
  //       });
  //     return;
  //   case "Repair":
  //     data.nameID &&
  //       StakeholderBalanceHandler({
  //         receipt: data,
  //         supType: "Customers",
  //       });
  //     return;

  //   case "Sales":
  //     data.nameID &&
  //       StakeholderBalanceHandler({
  //         receipt: data,
  //         supType: "Customers",
  //       });
  //     return;

  //   case "Paid":
  //     StakeholderBalanceHandler({
  //       receipt: data,
  //       supType: "Customers",
  //     });
  //     return;
  // }
}

export async function DeleteReceipt(data) {
  // switch (data.type.split("_")[0]) {
  //   case "Sales":
  //     inventoryUpdate({ billItems: data.itemList, increment: true });
  //     data.balance > 0 &&
  //       data.nameID &&
  //       StakeholderBalanceHandlerDelete({
  //         receipt: data,
  //         supType: "Customers",
  //       });
  //     break;

  //   case "Expense":
  //     data.subType &&
  //       data.subType == "Damage" &&
  //       inventoryUpdate({ billItems: data.itemList, increment: true });
  //     break;
  //   case "Purchase":
  //     data.balance > 0 &&
  //       data.nameID &&
  //       StakeholderBalanceHandlerDelete({
  //         receipt: data,
  //         supType: "Customers",
  //       });
  //     break;
  //   case "Return":
  //     inventoryUpdate({ billItems: data.itemList, increment: false });
  //   case "Paid":
  //     UpdateStakeholderPayment({
  //       cusId: data.nameID,
  //       total: data.total,
  //       type: "add",
  //       supType: data.supType + "s",
  //     });
  //     break;
  // }

  const docSnapshot = db
    .collection(`/${userDataGlobal.firmID}/Receipts/AllReceipts/`)
    .doc(data.timeStamp);

  await docSnapshot.set(
    {
      type: "Trash",
      deletedType: data.type,
      timeStamp: moment().format("YYYY-MM-DD HH:mm:ss_SSS"),
      deletedTimeStamp: data.timeStamp,
    },
    { merge: true }
  );
}

export async function RetrieveReceipt(data) {
  // switch (data.deletedType.split("_")[0]) {
  //   case "Sales":
  //     inventoryUpdate({ billItems: data.itemList, increment: false });
  //     data.balance > 0 &&
  //       data.nameID &&
  //       StakeholderBalanceHandler({
  //         receipt: { ...data, type: data.deletedType, timeStamp: data.deletedTimeStamp },
  //         supType: "Customers",
  //       });
  //     break;

  //   case "Expense":
  //     data.subType &&
  //       data.subType == "Damage" &&
  //       inventoryUpdate({ billItems: data.itemList, increment: false });
  //     break;
  //   case "Purchase":
  //     data.balance > 0 &&
  //       data.nameID &&
  //       StakeholderBalanceHandler({
  //         receipt: { ...data, type: data.deletedType, timeStamp: data.deletedTimeStamp },
  //         supType: "Customers",
  //       });
  //     break;
  //     break;

  //   case "Return":
  //     inventoryUpdate({ billItems: data.itemList, increment: true });
  //     break;

  //   case "Paid":
  //     UpdateStakeholderPayment({
  //       cusId: data.nameID,
  //       total: data.total,
  //       type: "del",
  //       supType: data.supType + "s",
  //     });
  //     break;
  // }

  const docSnapshot = db
    .collection(`/${userDataGlobal.firmID}/Receipts/AllReceipts/`)
    .doc(data.deletedTimeStamp);

  await docSnapshot.set(
    {
      type: data.deletedType,
      timeStamp: data.deletedTimeStamp,
    },
    { merge: true }
  );
}

export async function DeleteTrashReceipt(data) {
  const docSnapshot = db
    .collection(`/${userDataGlobal.firmID}/Receipts/AllReceipts/`)
    .doc(data.deletedTimeStamp);

  await docSnapshot.delete();

  const docSnapshot2 = db
    .collection(`/${userDataGlobal.firmID}/Receipts/permanentlyDeleted/`)
    .doc(data.deletedTimeStamp);

  await docSnapshot2.set(data, { merge: true });

  return true;
}
