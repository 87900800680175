import { db, dbTree } from "./Config";
import Firebase from "firebase";

var userDataGlobal = null;

export function UpdateUserData_Stakeholder(userData) {
  userDataGlobal = userData;
}

export async function StakeholderDetailsLoad({ type, id }) {
  const cusRecord = db.collection(`/${userDataGlobal.firmID}/Stakeholders/` + type).doc(id);
  const snapshot = await cusRecord.get();
  if (snapshot.empty) {
    return false;
  }
  return snapshot.data();
}

//-----WIRTE to DB ----------------------------------------------------

export async function AddStakeholders({ type, data }) {
  const cusSearch = db
    .collection(`/${userDataGlobal.firmID}/Stakeholders/` + type)
    .doc(type + "Search");
  const cusRecord = db.collection(`/${userDataGlobal.firmID}/Stakeholders/` + type).doc(data.id);

  const res1 = await cusSearch.set(
    {
      [data.id]: {
        id: data.id,
        name: data.name,
      },
    },
    { merge: true }
  );

  const res2 = await cusRecord.set(data, { merge: true });
}

export async function DeleteStakeholder({ data }) {
  // delete the search
  const cusSearch = db
    .collection(`/${userDataGlobal.firmID}/Stakeholders/` + data.type + "s")
    .doc(data.type + "s" + "Search");
  cusSearch.set(
    {
      [data.id]: Firebase.firestore.FieldValue.delete(),
    },
    { merge: true }
  );

  //move to another directory
  const deleteRecord = db
    .collection(`/${userDataGlobal.firmID}/Stakeholders/` + data.type + "sDeleted")
    .doc(data.id);

  const res2 = await deleteRecord.set(data, { merge: true });

  //delete the original one
  const cusRecord = db
    .collection(`/${userDataGlobal.firmID}/Stakeholders/` + data.type + "s")
    .doc(data.id);
  await cusRecord.delete();
}

export async function UpdateStakeholderPayment({ cusId, total, type, supType }) {
  //type pay, del, add
  console.log({ cusId, total, type, supType });
  const cusRecord = db.collection(`/${userDataGlobal.firmID}/Stakeholders/` + supType).doc(cusId);
  StakeholderDetailsLoad({ type: supType, id: cusId }).then((cusData) =>
    cusRecord.set(
      {
        outstanding:
          Number(cusData.outstanding) + (type == "add" ? Number(total) : Number(-1 * total)),
        ...(type != "pay" && {
          totPurchase:
            Number(cusData.totPurchase) + (type == "add" ? Number(total) : Number(-1 * total)),
        }),
      },
      { merge: true }
    )
  );

  // const globalOutRef = dbTree.ref(`/${userDataGlobal.firmID}/Stakeholders/Outstandings`);
  // globalOutRef.once("value").then((snapshot) => {
  //   let data = snapshot.val();
  //   if (snapshot.val()) {
  //     globalOutRef.set({
  //       ...data,
  //       [supType]: Number(data[supType]) + (type == "add" ? Number(total) : Number(-1 * total)),
  //     });
  //     return;
  //   } else {
  //     globalOutRef.set({
  //       ...{ Customers: 0, Suppliers: 0 },
  //       [supType]: type == "add" ? Number(total) : Number(-1 * total),
  //     });
  //   }
  // });
}
