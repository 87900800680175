import React, { useState, useEffect, useRef, useCallback } from "react";
import { StyleSheet, Text, View, TouchableOpacity, TextInput } from "react-native";

import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";

import {
  ThousandView,
  ProductNameDecode,
  StackFinder,
  ProductNameEncode,
} from "../../Functions/Functions";

import StakeholderFeild from "./Stakeholderfeild";

import Select from "react-select"; //https://medium.com/@lahiru0561/react-select-with-custom-label-and-custom-search-122bfe06b6d7
import moment from "moment";

const InvoiceTypes = [
  { label: "Vendor VendorPurchase", value: "VendorPurchase" },
  // { label: "Sales", value: "Sales_Saved" },
  // { label: "Return", value: "Return" },
  // { label: "Expenses", value: "Expense" },
  // { label: "Bank Deposit", value: "Bank" },
];

const customStyles = {
  control: (styles) => ({ ...styles, padding: "1px" }), // padding: "max(0.2vmin, 1px)
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "blue",
    padding: 10,
  }),
  container: () => ({
    fontSize: "max(2vmin, 15px)",
  }),
};

const inveTypeStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles) => ({ ...styles, padding: "1px" }), // padding: "max(0.2vmin, 1px)"
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "green",

    zIndex: 10,
  }),
  container: () => ({
    fontSize: "max(2vmin, 15px)",
  }),
};

function App(props) {
  const searchingItemList = useSelector((state) => state.data.productSearch); //the tree
  const root = useSelector((state) => state.data.productTree);
  const firmInfo = useSelector((state) => state.data.firmInfo);
  const vendorProduct = useSelector((state) => state.data.vendorProduct); //the tree

  const alert = useAlert();

  const [itemDetails, setItemDetails] = useState();
  const [quantity, setQuantity] = useState();
  const [weight, setWeight] = useState();
  const [karad, setKarad] = useState();

  const [discount, setDiscount] = useState();
  const [unitPrice, setUnitPrice] = useState();
  const [unit, setUnit] = useState();
  const [forcusStakholder, setforcusStakholder] = useState(false);

  const [invType, setInvType] = useState("VendorPurchase");

  const firstUpdate = useRef(true);

  const inveTypeRef = useRef();
  const itemRef = useRef();
  const quantityRef = useRef();
  const discountRef = useRef();
  const weightRef = useRef();
  const karadRef = useRef();
  //const theme = useTheme();

  useEffect(() => {
    if (firstUpdate.current) {
      // props.isEditEnabled && setTimeout(() => itemRef.current.focus(), 100);
      firstUpdate.current = false;
      return;
    }
    setTimeout(() => inveTypeRef.current && inveTypeRef.current.focus(), 100);
    reset();
  }, [props.reset]);

  //  useCallback(itemDetails ? StackFinder(root, itemDetails.id.split("_")) || 0 : 0, [
  //   itemDetails,
  //   root,
  // ]);

  function handeSubmit() {
    if (
      ["Expense", "Return", "VendorPurchase"].includes(invType) &&
      itemDetails &&
      quantity &&
      unitPrice
    ) {
      props.onAdd({
        id: itemDetails.id,
        // inWeight: itemDetails.weight,
        // inQuantity: itemDetails.quantity,
        updatedOn: moment().format("YYYY-MM-DD"),
        label: itemDetails.label,
        weight: Number(weight),
        "24K": firmInfo["24K"] || 0,
        karad: karad,
        unitPrice: unitPrice,
        quantity: Number(quantity),
      });
      reset();
      setTimeout(() => itemRef.current.focus(), 10);
    }
    // else if (["Sales_Saved"].includes(invType) && itemDetails && quantity) {
    //   props.onAdd({
    //     id: itemDetails.id,
    //     inWeight: itemDetails.weight,
    //     inQuantity: itemDetails.quantity,
    //     label: itemDetails.label,
    //     sellingPrice: itemDetails.sellingPrice,
    //     unit: itemDetails.unit,
    //     item: ProductNameDecode(itemDetails.item),
    //     make: ProductNameDecode(itemDetails.make),
    //     quantity: Number(quantity),
    //     unit: itemDetails.unit || "Nos",
    //     unitPrice: unitPrice,
    //     discount: discount || 0,
    //   });
    //   reset();
    //   setTimeout(() => itemRef.current.focus(), 10);
    // }
    else alert.error("Something Missing");
  }

  function customFilter(option, searchText) {
    if (
      option.data.id
        .replace(/ /g, "")
        .replace(/_/g, "")
        .toUpperCase()
        .includes(ProductNameEncode(searchText).replace(/ /g, "").toUpperCase()) &&
      !props.billItems.hasOwnProperty(option.data.id)
    )
      return true;
    else return false;
  }

  function reset() {
    itemRef.current && itemRef.current.clearValue();
    quantityRef.current && quantityRef.current.clear();
    discountRef.current && discountRef.current.clear();
    weightRef.current && weightRef.current.clear();

    karadRef.current && karadRef.current.clear();

    //unitRef.current.clear();

    setItemDetails();
    setUnitPrice();
    setKarad();
    setWeight();
    setQuantity();
    setDiscount();
    setUnit();
  }

  function Discount() {
    if (discount && (discount + "").includes("+") && Number(discount.slice(1)))
      return Number(itemDetails.sellingPrice) + Number(discount.slice(1));
    else if (!Number(discount) || !discount) return itemDetails ? itemDetails.sellingPrice : "NaN";
    else if (Number(discount) > 0)
      return Math.ceil((Number(itemDetails.sellingPrice) * (100 - discount)) / 100);
    else return Number(itemDetails.sellingPrice) + discount;
  }
  return (
    <View style={Styles.viewTag}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ width: "18%", marginLeft: "1%", margin: "1vmin" }}>
          <Select
            //className="basic-single "
            menuPortalTarget={document.body}
            styles={inveTypeStyles}
            classNamePrefix="select"
            defaultValue={
              props.viewStatus
                ? { label: props.editReceipt.type.split("_")[0], value: "Sales" }
                : InvoiceTypes[0]
            }
            isLoading={false}
            isClearable={true}
            style={{ width: 100 }}
            isRtl={false}
            isSearchable={true}
            name="color"
            options={InvoiceTypes}
            onChange={(data) => {
              data && props.setInvoiceType(data.value);
              data && setInvType(data.value);
            }}
            returnKeyType="next"
            isDisabled={props.submitted || props.viewStatus}
            ref={inveTypeRef}
            onKeyDown={(data) => {
              data.key == "ArrowRight" && setforcusStakholder(!forcusStakholder);
            }}
          />
        </View>

        <StakeholderFeild
          invType={invType}
          reset={props.reset}
          setStakeholderDetails={(data) => {
            props.setStakeholderDetails(data);
          }}
          forcusStakholder={forcusStakholder}
          nextForcus={() => itemRef.current.focus()}
          onPreviousFocus={() => inveTypeRef.current.focus()}
          itemDetails={itemDetails}
          stakeholderDetails={props.stakeholderDetails}
          isEditEnabled={props.viewStatus}
          editReceipt={props.editReceipt}
          setExpenseSubtype={props.setExpenseSubtype}
          stakeholderSideButton={props.stakeholderSideButton}
        />

        {itemDetails ? (
          <>
            <View style={{ width: "20%", marginLeft: "1%" }}>
              <Text
                style={[
                  Styles.modelTextInput,
                  { color: "green", fontWeight: "bold", textAlign: "center" },
                ]}
              >
                {" "}
                STOCK QTS. : {ThousandView(itemDetails.quantity)}
              </Text>
            </View>
            <View style={{ width: "20%", marginLeft: "1%" }}>
              <Text style={Styles.modelTextInput}>
                {" "}
                STOCK WEIGHT : {ThousandView(itemDetails.weight)}g
              </Text>
            </View>
          </>
        ) : (
          props.stakeholderDetails &&
          !props.editDisabled &&
          ["Sales_Saved", "VendorPurchase"].includes(invType) && (
            <>
              <View style={{ width: "32%", marginLeft: "1%" }}>
                <Text
                  style={[
                    Styles.modelTextInput,
                    { color: "red", fontWeight: "bold", textAlign: "center" },
                  ]}
                >
                  TOTAL OUTSTANDING : Rs. {ThousandView(props.stakeholderDetails.outstanding)}
                </Text>
              </View>
            </>
          )
        )}

        {props.viewStatus == "ViewOnly" &&
          props.editReceipt.type.includes("Saved") &&
          !props.editDisabled && (
            <View style={{ width: "13%" }}>
              <TouchableOpacity
                style={Styles.buttonView}
                onPress={() => {
                  props.setViewStatus("Edit");
                  setTimeout(() => itemRef.current.focus(), 100);
                }}
              >
                <Text style={Styles.buttonText}>EDIT</Text>
              </TouchableOpacity>
            </View>
          )}
      </View>

      {!props.submitted && !(props.viewStatus == "ViewOnly") && (
        <View style={{ flexDirection: "row" }}>
          <View style={{ width: "30%", marginLeft: "1%", margin: "1vmin" }}>
            <Select
              //className="basic-single "
              styles={customStyles}
              classNamePrefix="select"
              //defaultValue={options[0]}
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              style={{ width: 100 }}
              isRtl={false}
              isSearchable={true}
              name="color"
              options={Object.values(searchingItemList)}
              getOptionLabel={(option) =>
                option.label ||
                `${ProductNameDecode(option.make)} ${ProductNameDecode(option.item)}`
              }
              filterOption={customFilter}
              getOptionValue={(option) => `${option.id}`}
              onChange={(data) => {
                //getInStock({ id: data.id }).then((data) => setInStock(data));

                data &&
                  setItemDetails(
                    StackFinder(vendorProduct, (data.id || "").split("_")) || {
                      quantity: 0,
                      weight: 0,
                      purPercentage: 0,
                      ...data,
                    }
                  );
              }}
              onKeyDown={(data) => {
                data.key == "ArrowLeft" && !itemDetails && setforcusStakholder(!forcusStakholder);
                (itemDetails && data.key == "ArrowRight") ||
                  (data.key == "Enter" && quantityRef.current.focus());
              }}
              returnKeyType="next"
              ref={itemRef}
            />
          </View>
          <View style={{ width: "10%", marginLeft: "1%" }}>
            <TextInput
              value={ThousandView(quantity)}
              onChangeText={(data) => {
                let e = data.replace(/,/g, "");
                if (Number(e) || !e)
                  props.quantityCheckDisabled
                    ? setQuantity(e)
                    : alert.show("Over Quantity Than Avaiable Stock");
              }}
              paddingLeft={12}
              placeholderTextColor="black"
              style={Styles.modelTextInput}
              editable={itemDetails ? true : false}
              placeholder="Quantity"
              //onKeyDown={(data) => handleKeyboard(data.key)}
              tabIndex="1"
              returnKeyType="next"
              onSubmitEditing={() => {
                if (quantity) weightRef.current.focus();
              }}
              ref={quantityRef}
              onKeyPress={(data) => {
                data.key == "ArrowLeft" && itemRef.current.focus();
                data.key == "ArrowRight" && quantity && discountRef.current.focus();
              }}
            />
          </View>
          <View style={{ width: "10%", marginLeft: "1%" }}>
            <TextInput
              value={ThousandView(weight)}
              onChangeText={(data) => {
                let e = data.replace(/,/g, "");
                if (Number(e) >= 0) setWeight(e);
              }}
              paddingLeft={12}
              placeholderTextColor="black"
              style={Styles.modelTextInput}
              editable={itemDetails ? true : false}
              placeholder="Weight (g)"
              //onKeyDown={(data) => handleKeyboard(data.key)}
              tabIndex="1"
              returnKeyType="next"
              onSubmitEditing={() => {
                if (weight) karadRef.current.focus();
              }}
              ref={weightRef}
              onKeyPress={(data) => {
                data.key == "ArrowLeft" && itemRef.current.focus();
                data.key == "ArrowRight" && quantity && discountRef.current.focus();
              }}
            />
          </View>
          <View style={{ width: "10%", marginLeft: "1%" }}>
            <TextInput
              value={karad}
              onChangeText={(data) => {
                let e = data.replace(/,/g, "");
                if (Number(e) >= 0) setKarad(e);
              }}
              paddingLeft={12}
              placeholderTextColor="black"
              style={Styles.modelTextInput}
              editable={itemDetails ? true : false}
              placeholder="Karad"
              //onKeyDown={(data) => handleKeyboard(data.key)}
              tabIndex="1"
              returnKeyType="next"
              onSubmitEditing={() => {
                if (karad) discountRef.current.focus();
              }}
              ref={karadRef}
              onKeyPress={(data) => {
                data.key == "ArrowLeft" && itemRef.current.focus();
                data.key == "ArrowRight" && quantity && discountRef.current.focus();
              }}
            />
          </View>

          <View style={{ width: "14%", marginLeft: "1%", flexDirection: "row" }}>
            {/* <Text style={[Styles.modelTextInput, { width: "25%", color: "red" }]}>
              {discount > 0 && !(discount + "").includes("+") ? "%" : ""}
            </Text> */}

            <TextInput
              value={ThousandView(unitPrice)}
              onChangeText={(data) => {
                let e = data.replace(/,/g, "");
                if (Number(e) >= 0) setUnitPrice(e);
              }}
              //editable={!vKeyboard}
              editable={itemDetails ? true : false}
              paddingLeft={12}
              placeholderTextColor="black"
              style={Styles.modelTextInput}
              placeholder={"Price"}
              returnKeyType="next"
              ref={discountRef}
              onSubmitEditing={(data) => {
                handeSubmit();
              }}
              onKeyPress={(data) => {
                data.key == "ArrowLeft" && quantityRef.current.focus();
              }}
            />
          </View>
          {/* <View style={{ width: "10%", marginLeft: "1%" }}>
            {!["Expense", "Return", "VendorPurchase"].includes(invType) && (
              <Text style={Styles.modelTextInput}>UP : {ThousandView(Discount())}</Text>
            )}
          </View> */}
          {/* <View style={{ width: "10%", marginLeft: "1%" }}>
            <Text
              value={unit}
              onChangeText={(e) => {
                if (!Number(e) || !e) setUnit(e);
              }}
              editable={itemDetails ? true : false}
              paddingLeft={12}
              placeholderTextColor="black"
              style={Styles.modelTextInput}
              placeholder="Unit / Nos"
              returnKeyType="next"
              onSubmitEditing={(data) => {
                handeSubmit();
              }}
            >
              Unit : {itemDetails ? itemDetails.unit || "Nos" : "Nos"}
            </Text>
          </View> */}

          <View style={{ width: "13%" }}>
            <TouchableOpacity style={Styles.buttonView} onPress={handeSubmit}>
              <Text style={Styles.buttonText}>ADD</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  );
}

const Styles = StyleSheet.create({
  viewTag: {
    width: "95%",
    marginTop: 10,
    paddingTop: 2,
    paddingBottom: 2,
    maxWidth: "1400px",
    borderRadius: "1vmin",
    backgroundColor: "#6AC0FF",
  },
  modelTextInput: {
    margin: "1vmin",
    width: "100%",
    fontSize: "max(2vmin, 16px)",
    padding: "8px",
    backgroundColor: "white",
    borderRadius: 5,
    borderColor: "#757474",
    borderWidth: 0,
    marginLeft: 0,
  },
  buttonView: {
    width: "100%",
    margin: "1vmin",
    fontSize: "max(2vmin, 16px)",
    padding: "8px",
    borderRadius: 10,
    backgroundColor: "#FFAC30",
    textAlign: "center",
    fontWeight: "bold",
  },
  buttonText: {
    fontSize: "max(2vmin, 16px)",
    textAlign: "center",
    fontWeight: "bold",
  },
});

export default App;
