import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { db, dbTree, updateVeriableFuntions } from "../Firebase/Main";
import { ProductNameDecode, updateVeriableUserdataInFU } from "../Functions/Functions";

import SuppilerLoader from "./RealtimeLoaders/Suppliers";

import VendorProductTree from "./RealtimeLoaders/VendorProductTree";

import RealtTimeReceiptLoad from "./RealtimeLoaders/RealtTimeReceiptLoad";
import DayRereceiptsLoader from "./RealtimeLoaders/DayReceiptLoader";

export default function Apps() {
  const userData = useSelector((state) => state.data.userData);
  const root = useSelector((state) => state.data.productTree);
  const searchingItemList = useSelector((state) => state.data.productSearch); //the tree
  const firmInfo = useSelector((state) => state.data.firmInfo); //the tree
  const vendorProduct = useSelector((state) => state.data.vendorProduct); //the tree
  const realTimeActivator = useSelector((state) => state.data.realTimeActivator); //the tree

  const dispatch = useDispatch();

  const firstStart = useRef(true);

  useEffect(() => {
    const productTreeRef = dbTree.ref(`${userData.firmID}/ProductTree/Root`);

    var productTreechanges = productTreeRef.on(
      "value",
      (snapshot) => {
        if (snapshot.val()) {
          let data = snapshot.val();
          dispatch({
            type: "PRODUCT_TREE_LOADING",
            payLoad: data,
          });

          let SearchList = {};
          Object.keys(data).map(
            (cat) =>
              cat != "Goldsmith" &&
              Object.keys(data[cat]).map((make) =>
                Object.keys(data[cat][make]).map(
                  (subMake) =>
                    (SearchList[cat + "_" + make + "_" + subMake] = {
                      id: cat + "_" + make + "_" + subMake,
                      label: ProductNameDecode(cat[0] + " - " + make + " " + subMake),
                    })
                )
              )
          );
          // console.log(SearchList);
          dispatch({
            type: "PRODUCT_SEARCH_LOADING",
            payLoad: SearchList,
          });
          return;
        } else {
          dispatch({ type: "PRODUCT_TREE_LOADING", payLoad: {} });
          return;
        }
      },
      (err) => {
        return console.clear();
      }
    );

    // const unSubProductSerachList = db
    //   .collection(`${userData.firmID}/Products/ProductTree`)
    //   .doc("SearchList")
    //   .onSnapshot(
    //     (docSnapshot) => {
    //       if (docSnapshot.data()) {
    //         dispatch({
    //           type: "PRODUCT_SEARCH_LOADING",
    //           payLoad: docSnapshot.data(),
    //         });
    //         return;
    //       } else {
    //         dispatch({
    //           type: "PRODUCT_SEARCH_LOADING",
    //           payLoad: [],
    //         });
    //         return;
    //       }
    //     },
    //     (err) => {
    //       console.log(`Encountered error: ${err}`);
    //       return false;
    //     }
    //   );

    const unCompanyInfo = db
      .collection(`${userData.firmID}/`)
      .doc("GeneralInfo")
      .onSnapshot(
        (docSnapshot) => {
          console.clear();
          if (docSnapshot.data()) {
            dispatch({
              type: "FIRM_INFO_LOADING",
              payLoad: docSnapshot.data(),
            });
            return;
          } else {
            dispatch({
              type: "FIRM_INFO_LOADING",
              payLoad: false,
            });
            return;
          }
        },
        (err) => {
          return false;
        }
      );

    // const unsubCustomers = db
    //   .collection(`${userData.firmID}/Stakeholders/Customers/`)
    //   .doc("CustomersSearch")
    //   .onSnapshot(
    //     (docSnapshot) => {
    //       let data = docSnapshot.data();
    //       if (data) {
    //         dispatch({
    //           type: "CUSTOMERS_LIST_LOADING",
    //           payLoad: Object.values(data),
    //         });
    //         return;
    //       } else {
    //         dispatch({ type: "CUSTOMERS_LIST_LOADING", payLoad: [] });
    //         return;
    //       }
    //     },
    //     (err) => {
    //       console.log(`Encountered error: ${err}`);
    //       return false;
    //     }
    //   );

    return () => {
      //unSubProductSerachList();
      //unsubSuppier();
      // unsubCustomers();
      //unsubUserdata();
      unCompanyInfo();
      productTreeRef.off("value", productTreechanges);
    };
  }, []);

  useEffect(() => {
    updateVeriableFuntions({
      userData: userData,
      temproot: root,
      tempProductSearch: searchingItemList,
      tempFirmInfo: firmInfo,
      tempVendorProduct: vendorProduct,
    });
    updateVeriableUserdataInFU({
      userData: userData,
      temproot: root,
      tempProductSearch: searchingItemList,
      tempFirmInfo: firmInfo,
    });
  }, [userData, root, searchingItemList, firmInfo, vendorProduct]);

  return (
    <>
      <DayRereceiptsLoader />
      {firmInfo.bulkHandler && <VendorProductTree />}
      {realTimeActivator.includes("SUPPLIERLIST") && <SuppilerLoader />}
      {realTimeActivator.includes("REALTIMERECEIPT") && <RealtTimeReceiptLoad />}
    </>
  );
}
