import moment from "moment";

const todayOutputFormula = {
  date: moment().format("YYYY-MM-DD"),
  InitialCashBalance: 0,
  InitialChequeBalance: 0,

  otherIncome: {
    total: 0,
    payMethod: { nothing: 0 },
    list: {},
  },

  pays: {
    total: 0,
    payMethod: {},
    list: {},
  },

  sellers: {},

  cashSales: {
    total: 0,
    advance: 0,
    customers: {},
    payMethod: { nothing: 0 },
  },

  ["A_C"]: {
    total: 0,
    advance: { nothing: 0 },
    outstanding: 0,
    customers: {},
  },

  salesItems: {},
  salesKarats: {},
  purchaseItems: {},
  purchaseKarats: {},

  purchase: {
    total: 0,
    advance: { nothing: 0 },
    outstanding: 0,
    suppliers: {},
  },

  order: {
    total: 0,
    advance: { nothing: 0 },
    outstanding: 0,
    suppliers: {},
  },

  orderItems: {},

  stockReductionValues: {
    total: 0,
  },
  paidSales: {
    total: 0,
    payMethod: {},
  },
  paidOrder: {
    total: 0,
    payMethod: {},
  },
  paidSMS: {
    total: 0,
    payMethod: {},
  },

  payout: 0,

  return: {
    total: 0,
    items: {},
  },

  savings: {
    total: 0,
    payMethod: {},
  },

  bank: {
    total: 0,
    items: {},
    payMethod: { nothing: 0 },
  },

  expense: {
    total: 0,
    payMethod: { nothing: 0 },
    supTypes: {},
    DamageList: {},
    list: {},
  },
};

const InitialState = {
  screen: "LOGIN",
  isInvActive: false,
  tapIndex: false,
  extraTapIndex: 0,
  todayReceipts: { ...todayOutputFormula },
  productTree: {},
  productSearch: [],
  customersList: [],
  suppilersList: [],
  preInfo: { date: moment().format("YYYY-MM-DD") },
  userData: { firmID: "MP-DEPLOYED-2021-11-02" },
  cashFlow: false,
  shortcutStack: [],
  firmInfo: {},
  vendorProduct: {},
  realTimeActivator: [],
  realtimeReceipts: [],
  addedItem: [],
};
const countReducer = (state = InitialState, action) => {
  switch (action.type) {
    case "INVOICE_ACTIVE_LOADING":
      return { ...state, isInvActive: action.payLoad };
    case "EXTRA_TAP_INDEX":
      return { ...state, extraTapIndex: action.payLoad };
    case "PRODUCT_TREE_LOADING":
      return { ...state, productTree: action.payLoad };
    case "VENDOR_PRODUCT_LOADING":
      return { ...state, vendorProduct: action.payLoad };

    case "PRODUCT_SEARCH_LOADING":
      return { ...state, productSearch: action.payLoad };
    case "CUSTOMERS_LIST_LOADING":
      return { ...state, customersList: action.payLoad };
    case "SUPPLIERS_LIST_LOADING":
      return { ...state, suppilersList: action.payLoad };
    case "PREINFO_LOADING":
      return { ...state, preInfo: action.payLoad };
    case "CASHFLOW_LOADING":
      return { ...state, cashFlow: action.payLoad };
    case "TODAY_RECEIPTS":
      return { ...state, todayReceipts: { ...state.todayReceipts, ...action.payLoad } };
    case "REALTIME_RECEIPTS":
      return { ...state, realtimeReceipts: action.payLoad };

    case "REALTIME_ACTIVATORS_PUSH":
      return state.realTimeActivator.indexOf(action.payLoad) > 0
        ? state
        : { ...state, realTimeActivator: [...state.realTimeActivator, action.payLoad] };

    case "REALTIME_ACTIVATORS_CLEAN":
      return { ...state, realTimeActivator: [] };

    case "REALTIME_ACTIVATORS_REMOVE":
      let index = state.realTimeActivator.indexOf(action.payLoad);
      if (index > 0)
        return {
          ...state,
          realTimeActivator: [
            ...state.realTimeActivator.slice(0, index),
            ...state.realTimeActivator.slice(index + 1),
          ],
        };
      return {
        ...state,
      };

    case "USER_DATA_LOADING":
      return {
        ...state,
        userData: action.payLoad,
        preInfo: { ...state.preInfo, user: action.payLoad.name },
      };

    case "FIRM_DATA_LOADING":
      return {
        ...state,
        preInfo: { ...state.preInfo, firm: action.payLoad },
      };
    case "SHORTCUT_PUSH":
      return {
        ...state,
        shortcutStack: [...state.shortcutStack, action.payLoad],
      };

    case "SHORTCUT_POP":
      return {
        ...state,
        shortcutStack: state.shortcutStack.slice(0, -1),
      };

    case "FIRM_INFO_LOADING":
      return {
        ...state,
        firmInfo: action.payLoad,
      };

    case "ADDEDITEM_PUSH":
      return {
        ...state,
        addedItem: [...state.addedItem, action.payLoad],
      };
    case "ADDEDITEM_CLEAN":
      return {
        ...state,
        addedItem: [],
      };
    default:
      return state;
  }
};

export default countReducer;
