import { StackFinder } from "../Functions/Functions";
import { dbTree, db } from "./Config";

var userDataGlobal;
var root = {};
var productSearchList = [];
var vendorProduct = {};

export function UpdaeVariable_Inventory({
  temRoot,
  userData,
  tempProductSearch,
  tempVendorProduct,
}) {
  userDataGlobal = userData;
  root = temRoot;
  productSearchList = tempProductSearch;
  vendorProduct = tempVendorProduct || {};
}

export function inventoryUpdate({ billItems, increment }) {
  Object.values(billItems).map((data) => {
    inventoryItemSet({ item: data, increment: increment });
  });
}

export function inventoryItemSet({ item, increment }) {
  let path = item.id.split("_");
  if (StackFinder(vendorProduct, path)) {
    let quantity = increment
      ? Number(StackFinder(vendorProduct, path).quantity) + Number(item.quantity)
      : Number(StackFinder(vendorProduct, path).quantity) - Number(item.quantity);

    let weight = increment
      ? Number(StackFinder(vendorProduct, path).weight) + Number(item.weight)
      : Number(StackFinder(vendorProduct, path).weight) - Number(item.weight);

    dbTree
      .ref(`${userDataGlobal.firmID}/vendorProduct/`)
      .child(path.join("/"))
      .update({ ...item, quantity: quantity, weight: weight });
  } else {
    dbTree.ref(`${userDataGlobal.firmID}/vendorProduct/`).child(path.join("/")).update(item);
  }
}

export function bulkInventoryReducer({ id, weight, quantity, increment }) {
  let path = id.split("_");
  if (StackFinder(vendorProduct, path)) {
    let quantityT = increment
      ? Number(StackFinder(vendorProduct, path).quantity) + Number(quantity)
      : Number(StackFinder(vendorProduct, path).quantity) - Number(quantity);

    let weightT = increment
      ? Number(StackFinder(vendorProduct, path).weight) + Number(weight)
      : Number(StackFinder(vendorProduct, path).weight) - Number(weight);

    dbTree
      .ref(`${userDataGlobal.firmID}/vendorProduct/`)
      .child(path.join("/"))
      .update({ quantity: quantityT, weight: weightT });
  }
}

export async function ItemsPurchaseAvgSet({ billItems, type }) {}
