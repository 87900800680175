import React, { Component, useState, useEffect, useRef, useCallback } from "react";
import SwipeableViews from "react-swipeable-views";
import moment from "moment";
import {
  Button,
  Image,
  FlatList,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  TextInput,
} from "react-native";

import { useSelector, useDispatch } from "react-redux";

import StakeholderFeild from "../InvoiceItemAdder/Stakeholderfeild";
import DatePicker from "react-datepicker";
import "./DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { FolderOpenFilled } from "@ant-design/icons";
import Select from "react-select"; //https://medium.com/@lahiru0561/react-select-with-custom-label-and-custom-search-122bfe06b6d7

const InvoiceTypes = [
  { label: "Sales", value: "Sales_Saved" },
  { label: "Customer Payment", value: "Paid_A/C_Paid" },
  { label: "Purchase", value: "Purchase" },
  { label: "Supplier Paid", value: "Paid_Purchase_Paid" },
  { label: "Return", value: "Return" },
  { label: "Expense", value: "Expense" },
  { label: "Bank Deposit", value: "Bank" },
];

const inveTypeStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles) => ({ ...styles, padding: "1px" }), // padding: "max(0.2vmin, 1px)"
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "green",

    zIndex: 10,
  }),
  container: () => ({
    fontSize: "max(2vmin, 15px)",
  }),
};

export default function App(props) {
  const inveTypeRef = useRef();
  const itemRef = useRef();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [trashEnabled, setTrashEnabled] = useState(false);

  const [invType, setInvType] = useState("Sales_Saved");

  return (
    <View style={Styles.viewTag}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ width: "85%", flexDirection: "row" }}>
          {!trashEnabled ? (
            <>
              <View
                style={{ width: "18%", marginLeft: "5%", marginRight: "4.5%", margin: "1vmin" }}
              >
                <Select
                  //className="basic-single "
                  menuPortalTarget={document.body}
                  styles={inveTypeStyles}
                  classNamePrefix="select"
                  defaultValue={InvoiceTypes[0]}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  style={{ width: 100 }}
                  isRtl={false}
                  isSearchable={true}
                  name="color"
                  options={InvoiceTypes}
                  onChange={(data) => {
                    data && props.setShowType([data.value, data.value.split("_")[0]]);
                    data && setInvType(data.value);
                  }}
                  returnKeyType="next"
                  isDisabled={props.submitted}
                  ref={inveTypeRef}
                />
              </View>

              {["Sales_Saved", "Purchase", "Paid_A/C_Paid"].includes(invType) && (
                <StakeholderFeild
                  invType={invType}
                  reset={props.reset}
                  setStakeholderDetails={props.setStakeholderDetails}
                  nextForcus={() => null}
                  stakeholderDetails={() => null}
                  isPayDisabled={true}
                />
              )}
              <View style={{ width: "18%", marginLeft: "4.5%", margin: "1vmin" }}>
                <DatePicker
                  selected={selectedDate}
                  style={{ padding: "2vmin" }}
                  onChange={(date) => {
                    props.onDateChange(date);
                    setSelectedDate(date);
                  }}
                />
              </View>
            </>
          ) : (
            <Text style={Styles.heading}>THE TRASH</Text>
          )}
        </View>

        <TouchableOpacity
          style={[Styles.buttonView, { width: "10%" }, trashEnabled && { backgroundColor: "red" }]}
          onPress={() => {
            trashEnabled ? props.setShowType() : props.setShowType("Trash");
            setInvType("Trash");
            setTrashEnabled(!trashEnabled);
          }}
        >
          <FolderOpenFilled
            style={{ color: "white", fontWeight: "bold", fontSize: "max(3vmin, 15px)" }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}

const Styles = StyleSheet.create({
  viewTag: {
    width: "95%",
    marginTop: 10,
    paddingTop: 1,
    paddingBottom: 1,
    maxWidth: "1400px",
    borderRadius: "1vmin",
    backgroundColor: "#6AC0FF",
    justifyContent: "center",
  },
  receiptDate: {
    fontSize: "2vmin",
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: "1vmin",
  },

  button: {
    backgroundColor: "green",
    borderRadius: 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 6,
    marginBottom: 1,
  },

  buttonView: {
    width: "100%",
    margin: "1vmin",
    fontSize: "max(2.2vmin, 14px)",
    padding: "7px",
    borderRadius: 10,
    backgroundColor: "#FFAC30",
    textAlign: "center",
    fontWeight: "bold",
  },

  buttonText: {
    fontSize: "max(2.2vmin, 14px)",
    textAlign: "center",
    fontWeight: "bold",
  },
  heading: {
    margin: "0.4vmin",
    color: "black",
    padding: "7px",
    marginLeft: "40%",
    fontSize: "max(2.8vmin, 18px)",
    textAlign: "center",
    fontWeight: "bold",
  },
});
