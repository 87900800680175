import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Provider, useSelector } from "react-redux";

import { createStore } from "redux";

import { positions, transitions, Provider as AlertProvider } from "react-alert";

import AlertTemplate from "react-alert-template-basic";

import HomeScreen from "./Screens/HomeScreen";
import allReducers from "./Reducer/main";
import LoginScreen from "./Screens/Login/LoginScreen";
import { InterNetError, Alert } from "./Componets/Modals/Modals";
import { ThousandView } from "./Functions/Functions";

const store = createStore(allReducers);

function Flip() {
  const userData = useSelector((state) => state.data.userData);
  const firmInfo = useSelector((state) => state.data.firmInfo);

  return (
    <>
      {userData.status == "active" && userData.login ? <HomeScreen /> : <LoginScreen />}
      <View style={Styles.footer}>
        {firmInfo["silver"] && (
          <Text style={[Styles.footerTitle, { marginRight: "5%" }]}>
            SILVER : {ThousandView(Number(firmInfo["silver"]).toFixed(2))}
          </Text>
        )}
        <Text style={Styles.footerTitle}>
          V2 SOFTWARED BY INSTANT DIGITS (PVT) LTD, CALL US ON +94 71 777 2095
        </Text>
        {firmInfo["24K"] && (
          <Text style={[Styles.footerTitle, { marginLeft: "5%" }]}>
            24K : {ThousandView(Number(firmInfo["24K"]).toFixed(2))}
          </Text>
        )}
      </View>
    </>
  );
}

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 99999999999,
    marginTop: "3%",
    // flex: 1,
    // backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
};
function App() {
  const [online, setOnline] = useState(true);

  useEffect(
    () =>
      setInterval(() => {
        setOnline(window.navigator.onLine);
      }, 1000),
    []
  );

  useEffect(() => {
    console.log =
      console.warn =
      console.error =
      console.info =
      console.debug =
      console.assert =
        () => {};

    //GlobalDebug(false);
  }, []);

  console.log("I am just another dummy console log,suppose to be suppressed 🙂");

  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        {!online && <InterNetError />}
        <Flip />
      </AlertProvider>
    </Provider>
  );
}

const Styles = StyleSheet.create({
  footer: {
    paddingTop: "0.3%",
    paddingBottom: "0.3%",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#0000AF",
    position: "absolute",
    bottom: 0,
  },
  footerTitle: {
    fontSize: "calc(1.5vmin)",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
  },
});

export default App;
