import React, { Component, useState, useEffect, useRef, useCallback } from "react";
import SwipeableViews from "react-swipeable-views";
import {
  StyleSheet,
  Text,
  Modal,
  Alert,
  View,
  Dimensions,
  TouchableOpacity,
  Pressable,
  TextInput,
  TouchableWithoutFeedback,
} from "react-native";
import moment from "moment";
import { useAlert } from "react-alert";

import BillEdit from "../Billing/Main";
import { StackFinder, ThousandView } from "../../Functions/Functions";
import { PincodeVerfication } from "../../Componets/Modals/Modals";

const ModalView = (props) => {
  return (
    <Modal transparent={true} visible={true} useNativeDriver={true} onRequestClose={props.close}>
      <TouchableWithoutFeedback activeOpacity={1} onPress={props.close}>
        <View style={Styles.modalCenterdView}>
          <TouchableWithoutFeedback activeOpacity={1}>
            <View style={[Styles.receiptModalView, { height: "95%" }]}>
              <BillEdit
                viewStatus={"ViewOnly"}
                close={props.close}
                editReceipt={props.editReceipt}
                editDisabled={props.editDisabled}
                onEditUpdate={props.onEditUpdate}
              />
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default function App(props) {
  const currentMoment = moment();
  const timeStampMoment = moment(props.data.timeStamp, "YYYY-MM-DD HH:mm:ss_SSS");
  const tempType = props.data.type.split("_").pop();
  const alert = useAlert();

  const [editModal, setEditModal] = useState(false);
  const [pwdVerification, setPwdVerification] = useState(false);

  const deleteAction = (type) => {
    if (type == "DELETE") {
      alert.show(
        props.showType == "Trash"
          ? "Once you delete from Trash, you will never get it back"
          : "Do You want to Delete it?"
      );
      setPwdVerification("DELETE");
    } else {
      alert.show("Do you want to restore the Bill?");
      setPwdVerification("RESTORE");
    }
  };

  const relatieveDate = () => {
    if (currentMoment.subtract(1, "hour") < timeStampMoment) return timeStampMoment.fromNow();
    else if (currentMoment.subtract(6, "hour") < timeStampMoment) return timeStampMoment.calendar();
    else if (currentMoment.subtract(2, "days") < timeStampMoment) return timeStampMoment.calendar();
    return timeStampMoment.format("YYYY-MM-DD, hh:mm:ss a");
  };

  return (
    <TouchableOpacity
      style={[Styles.receiptTagView, props.index % 2 == 1 && { backgroundColor: "white" }]}
      onPress={() => typeof props.data.itemList == "object" && setEditModal(true)}
    >
      {pwdVerification && (
        <PincodeVerfication
          close={() => setPwdVerification(false)}
          onVerified={pwdVerification == "RESTORE" ? props.restore : props.delete}
          onFaild={() => {
            setPwdVerification(false);
          }}
        />
      )}
      {editModal && (
        <ModalView
          editReceipt={props.data}
          close={() => setEditModal(false)}
          editDisabled={props.editDisabled}
          onEditUpdate={props.onEditUpdate}
        />
      )}
      <View style={{ width: "12%", paddingLeft: "1%" }}>
        <Text style={Styles.receiptDate}> {relatieveDate()}</Text>
      </View>
      {props.data.type.includes("Paid") ? (
        <View
          style={[
            { width: "78%", paddingLeft: "1%" },
            props.showType == "Trash" && { width: "65%" },
          ]}
        >
          <View style={{ width: "100%", paddingLeft: "1%", flexDirection: "row" }}>
            <Text
              style={[Styles.receiptDate, { width: "50.5%", textAlign: "left", color: "green" }]}
              numberOfLines={1}
            >
              A {props.data.supType && props.data.supType.toUpperCase()} PAYMENT :{" "}
              {props.data.name && props.data.name.toUpperCase()}
            </Text>

            <Text style={[Styles.receiptDate, { width: "44%", textAlign: "left", color: "green" }]}>
              Paid : Rs. {ThousandView(props.data.payAmount) + " by " + props.data.payMethod}
            </Text>
          </View>
          <View style={{ width: "100%", paddingLeft: "1%", flexDirection: "row" }}>
            <Text
              style={[
                Styles.receiptDate,
                { width: "25%", textAlign: "left", fontWeight: "normal" },
              ]}
            >
              Made By : {props.data.issuedby}
            </Text>

            <Text
              style={[Styles.receiptDate, { width: "70%", textAlign: "left" }]}
              numberOfLines={1}
            >
              {props.data.comment}
            </Text>
          </View>
        </View>
      ) : (
        <View
          style={[
            { width: "78%", paddingLeft: "1%" },
            props.showType == "Trash" && { width: "65%" },
          ]}
        >
          <View style={{ width: "100%", paddingLeft: "1%", flexDirection: "row" }}>
            <Text
              style={[
                Styles.receiptDate,
                { width: "30%", textAlign: "left" },
                props.data.type == "Expense" && { color: "red" },
                props.data.type == "Bank" && { color: "#214802" },
                props.data.type == "Order_Pending" && { color: "#810045" },
                ["Purchase", "Return"].includes(props.data.type) && { color: "#ED5B09" },
              ]}
              numberOfLines={1}
            >
              {(["Sales", "Sales_Saved"].includes(props.data.type)
                ? props.data.balance > 0
                  ? "A/C"
                  : "Cash"
                : props.data.type.split("_")[0].toUpperCase()) +
                " - " +
                (props.data.name && props.data.name.toUpperCase())}
            </Text>

            <Text
              style={[
                Styles.receiptDate,
                { width: "20%", textAlign: "left" },
                props.showType == "Trash" && { width: "30%" },
              ]}
            >
              Total: Rs. {ThousandView(props.data.total)}
            </Text>

            {["Sales_Saved", "Purchase", "Order_Pending"].includes(props.data.type) && (
              <Text
                style={[Styles.receiptDate, { width: "27%", textAlign: "left", color: "green" }]}
              >
                AD.: Rs.{" "}
                {props.data.payAmount
                  ? ThousandView(props.data.payAmount) + " by " + props.data.payMethod
                  : "0"}
              </Text>
            )}

            {props.showType != "Trash" &&
              ["Sales_Saved", "Purchase", "Order_Pending"].includes(props.data.type) && (
                <Text
                  style={[
                    Styles.receiptDate,
                    {
                      width: "20%",
                      textAlign: "left",
                      color: props.data.balance > 0 ? "red" : "green",
                    },
                  ]}
                >
                  {props.data.balance
                    ? "Balance: Rs. " + ThousandView(props.data.balance)
                    : "Fully Paid"}
                </Text>
              )}
          </View>
          <View style={{ width: "100%", paddingLeft: "1%", flexDirection: "row" }}>
            <Text
              style={[
                Styles.receiptDate,
                { width: "31%", textAlign: "left", fontWeight: "normal" },
              ]}
            >
              Made By : {props.data.issuedby}
            </Text>

            <Text
              style={[Styles.receiptDate, { width: "70%", textAlign: "left" }]}
              numberOfLines={1}
            >
              {props.data.itemNames}
            </Text>
          </View>
        </View>
      )}

      {props.showType == "Trash" && (
        <View style={{ width: "10%", marginLeft: "2%" }}>
          <TouchableOpacity
            style={[Styles.buttonView, { backgroundColor: "#FFAC30" }]}
            onPress={() => deleteAction("RESTORE")}
          >
            <Text style={[Styles.buttonText, { color: "black" }]}>{"RESTORE"}</Text>
          </TouchableOpacity>
        </View>
      )}
      <View style={{ width: "10%", paddingLeft: "2%", paddingRight: "2%" }}>
        <TouchableOpacity style={[Styles.buttonView]} onPress={() => deleteAction("DELETE")}>
          <Text style={[Styles.buttonText]}>{"DELETE"}</Text>
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
}

const Styles = StyleSheet.create({
  viewTag: {
    width: "95%",
    marginTop: "1%",
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
    maxWidth: "1400px",
    borderRadius: "1vmin",
    backgroundColor: "#6AC0FF",
  },
  receiptTagView: {
    width: "100%",
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
    maxWidth: "1400px",
    borderRadius: 5,
    backgroundColor: "#B3E5FC",
    borderBottomWidth: 2,
    borderColor: "#0000AF",
    flexDirection: "row",
  },
  receiptDate: {
    fontSize: "max(2vmin, 13px)",
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: "1vmin",
  },
  buttonView: {
    width: "100%",

    fontSize: "max(1.4vmin, 10px)",
    padding: "7px",
    borderRadius: 10,
    backgroundColor: "red",
    textAlign: "center",
    fontWeight: "bold",
  },
  buttonText: {
    fontSize: "max(1.4vmin, 10px)",
    textAlign: "center",
    fontWeight: "bold",
    color: "white",
  },

  modelTextInput: {
    margin: "1vmin",
    width: "100%",
    fontSize: "2vmin",
    padding: "0.7vmin",
    backgroundColor: "white",
    borderRadius: 5,
    borderColor: "#757474",
    borderWidth: 0,
    marginLeft: 0,
  },
  modalCenterdView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  receiptModalView: {
    width: "90%",
    maxWidth: "1440px",
    backgroundColor: "#F1F2FB",
    borderRadius: 5,
    padding: "1%",
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
});
