import { StyleSheet } from "react-native";

import {
  button,
  tree,
  treeSub,
  windowWidth,
  modelText,
  label,
  normal,
  headingLarge,
  treeSubText,
} from "../../Fonts/FontSizes";

export const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: "#E4F7FE",
    // paddingLeft: 5,
    // paddingRight: 5,
  },
  viewTag: {
    width: "95%",
    marginTop: "1%",
    paddingTop: "min(0.5%, 5px)",
    paddingBottom: "min(0.5%, 5px)",
    maxWidth: "1400px",
    borderRadius: "1vmin",
    backgroundColor: "red", //"#6AC0FF",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#FFAC30",
    borderRadius: 20,
    paddingTop: 7,
    paddingBottom: 7,
  },
  buttonText: {
    fontSize: button,
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
  },

  treeHeadingCatView: {
    marginBottom: 3,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  treeHeadingCat: {
    backgroundColor: "#8A3200",
    paddingTop: 3,
    paddingBottom: 3,
    width: "100%",
    alignItems: "center",
  },
  treeHeadingCatText: {
    fontSize: "max(2.5vmin, 16px)",
    fontWeight: "bold",
    marginLeft: 15,
    textAlign: "left",
    color: "white",
  },
  treeHeadingMake: {
    flexDirection: "row",
    padding: 10,
    alignItems: "center",
    margin: 3,
    borderRadius: 10,
    marginLeft: 15,
    minWidth: "50%",
    backgroundColor: "#FF441B",
  },
  treeHeadingMakeText: {
    fontSize: "max(2.5vmin, 16px)",
    fontWeight: "bold",
    marginLeft: 15,
    textAlign: "left",
    color: "white",
    // fontSize: treeSub * 1.0,
  },
  treeHeadingItem: {
    flexDirection: "row",
    padding: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    margin: 3,
    borderRadius: 12,
    marginLeft: 35,
    minWidth: "50%",
    backgroundColor: "#2196F3",
  },
  treeHeadingItemText: {
    fontWeight: "bold",
    marginLeft: 10,
    textAlign: "left",
    color: "white",
    borderLeftWidth: 1,
    borderColor: "white",
    paddingLeft: 10,
    fontSize: "max(2.5vmin, 16px)",
    minWidth: 30,
    //width: "50%",
  },

  treeMakeText: {
    fontSize: "max(2.5vmin, 16px)",
    fontWeight: "bold",
    marginLeft: 15,
    textAlign: "left",
    color: "white",
    width: windowWidth * 0.2,
  },
  treeMakeTextSub: {
    fontSize: "max(2.5vmin, 16px)",

    fontWeight: "bold",
    marginLeft: 15,
    textAlign: "left",
    color: "white",
    width: windowWidth * 0.22,
  },
  treeItem: {
    flexDirection: "row",
    //padding: 3,
    alignItems: "center",
    //justifyContent: "flex-end",
    margin: 3,
    borderRadius: 10,
    marginLeft: "20%",
    width: "80%",
    backgroundColor: "#EAEAEA",
    //minHeight: tree * 1.5,
  },
  treeItemText: {
    fontSize: "max(2.2vmin, 16px)",
    fontWeight: "bold",
    marginLeft: 15,
    textAlign: "left",
    color: "black",
  },
  textInput: {
    margin: modelText * 0.5,
    width: "100%",
    fontSize: modelText,
    padding: modelText * 0.4,
    backgroundColor: "white",
    borderRadius: 10,
    borderColor: "#757474",
    borderWidth: 1,
    marginLeft: 0,
  },
  PDbutton: {
    backgroundColor: "white",
    paddingLeft: 10,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  PDbuttonText: {
    fontSize: tree,
    marginTop: tree / 2,
    marginBottom: tree / 2,
    fontWeight: "900",
  },
  pinChildAddButton: {
    position: "absolute",
    right: tree * 1.1,
    borderRadius: 9,
    fontSize: tree * 1.5,
    padding: 5,
    color: "red",
    width: "20%",
  },
  modalCenterdView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 99999,
  },
  receiptModalView: {
    width: windowWidth * 0.95,
    backgroundColor: "white",
    borderRadius: 5,
    padding: "1%",
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  modalView: {
    width: windowWidth * 0.9,
    maxWidth: 450,
    backgroundColor: "white",
    borderRadius: 20,
    padding: "5%",
    alignItems: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },

  invLabelView: {
    flexDirection: "row",
    backgroundColor: "#0000AF",
    padding: 0,
    color: "#FFFFFF",
    marginTop: 8,
  },
  invItemSplit: {
    borderRightWidth: 1,
    paddingTop: 5,
    paddingBottom: 5,
    borderColor: "#FFFFFF",
  },
  invLabelText: {
    fontSize: label,
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
  },
  invItemView: {
    flexDirection: "row",
    padding: 0,
    color: "#FFFFFF",

    backgroundColor: "#FBFBFB",
  },
  invItemText: {
    color: "black",
    fontSize: normal,
    fontWeight: "bold",
  },

  bottomView: {
    position: "absolute",
    bottom: 0,
  },
  totalView: {
    flexDirection: "row",
    backgroundColor: "#0000AF",
    width: "100%",
    marginBottom: -5,
  },
  totalText: {
    fontSize: label,
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
    fontSize: headingLarge * 0.95,
  },
  billHeader: {
    width: "95%",
    marginTop: 10,
    flexDirection: "row",
    maxWidth: "1400px",
    backgroundColor: "#0000AF",
    zIndex: -1,
  },

  billHeaderTextCommon: {
    fontSize: "max(2vmin, 14px)",
    textAlign: "center",
    color: "white",
    borderRightWidth: 1,
    fontWeight: "bold",
    borderColor: "white",
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
  },
  billFooterTextCommon: {
    fontSize: "max(2.5vmin, 14px)",
    textAlign: "center",
    color: "white",
    borderRightWidth: 1,
    fontWeight: "bold",
    borderColor: "white",
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
  },
  billItem: {
    width: "95%",
    flexDirection: "row",
    maxWidth: "1400px",
    backgroundColor: "white",
    zIndex: -1,
  },
  billItemText: {
    fontSize: "max(2vmin, 14px)",
    textAlign: "center",
    color: "black",
    borderRightWidth: 1,
    // fontWeight: "bold",
    borderColor: "white",
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
  },
  buttonView: {
    width: "100%",
    maxWidth: "1400px",
    fontSize: "max(1.4vmin, 10px)",
    padding: "7px",
    borderRadius: 10,
    backgroundColor: "#FFAC30",
    textAlign: "center",
    fontWeight: "bold",
  },
  buttonText: {
    fontSize: "max(2.5vmin, 16px)",
    textAlign: "center",
    fontWeight: "bold",
  },
  buttonLoadMore: {
    width: "100%",
    maxWidth: "1400px",
    fontSize: "max(2vmin, 14px)",
    padding: "7px",
    borderRadius: 10,
    backgroundColor: "#FFAC30",
    textAlign: "center",
    fontWeight: "bold",
    margin: "2vmin",
  },
  buttonLoadMoreText: {
    fontSize: "max(2vmin, 14px)",
    textAlign: "center",
    fontWeight: "bold",
  },
  viewTag: {
    width: "95%",
    marginTop: "1%",
    paddingTop: "min(0.5%, 5px)",
    paddingBottom: "min(0.5%, 5px)",
    maxWidth: "1400px",
    borderRadius: "1vmin",
    backgroundColor: "#6AC0FF",
    justifyContent: "center",
  },
});

export default styles;
