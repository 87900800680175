import moment from "moment";
import { UserAuth, UserUpdateDB, GetUsersOfFirmID, UserCreate } from "./LocalApi";

const apiUrl = "https://smart-pos-plus-apis-22.df.r.appspot.com";

export async function GetTodayBalances({ firmID }) {
  const respose = await fetch(`${apiUrl}/getTodayPerformance?firmID=${firmID}`, {
    method: "get",
    mode: "no-cors",
  });
  const data = await respose.json();

  return data;
}

export async function GetSalesOutstanding({ firmID }) {
  const respose = await fetch(`${apiUrl}/getSalesOutstanding?firmID=${firmID}`, {
    method: "get",
    mode: "no-cors",
  });
  const data = await respose.json();

  return data;
}

export async function GetPurchaseOutstanding({ firmID }) {
  const respose = await fetch(`${apiUrl}/getPurchaseOutstanding?firmID=${firmID}`, {
    method: "get",
    mode: "no-cors",
  });
  const data = await respose.json();

  return data;
}

export async function GetThisMonthBalances({ firmID }) {
  const respose = await fetch(`${apiUrl}/getThisMonthPerformance?firmID=${firmID}`, {
    method: "get",
    mode: "no-cors",
  });
  const data = await respose.json();

  return data;
}

export async function GetLastMonthBalances({ firmID }) {
  let year =
    moment().format("MM") == "01" ? Number(moment().format("YYYY")) - 1 : moment().format("YYYY");

  let month = moment().format("MM") == "01" ? "12" : Number(moment().format("MM")) - 1;

  const respose = await fetch(
    `${apiUrl}/getMonthPerformance?firmID=${firmID}&&month=${month}&&year=${year}`,
    {
      method: "get",
      mode: "no-cors",
    }
  );
  const data = await respose.json();

  return data;
}

export async function UserUpdate({ uid, data }) {
  const respose = await fetch(`${apiUrl}/userUpdate`, {
    method: "post",
    mode: "no-cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      uid: uid,
      data: data,
    }),
  });
  const revdata = await respose.json();

  return revdata;
}

export async function AddNewUserApi({ data }) {
  // const respose = await fetch(`${apiUrl}/userCreate`, {
  //   method: "post",
  //   mode: "no-cors",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(data),
  // });
  console.log(data);
  const revdata = await UserCreate(data);
  return revdata;
}

export async function UserAuthApi({ userName, pwd }) {
  const respose = await UserAuth({
    pwd: pwd,
    userName: userName,
    timeStamp: moment().format("YYYY-MM-DD HH:mm:ss_SSS"),
  });

  //const data = await respose.json();

  return respose;
}

export async function UserLogoutApi({ uid }) {
  const respose = await UserUpdateDB({
    data: {
      login: false,
      lastLogout: moment().format("YYYY-MM-DD HH:mm:ss_SSS"),
    },
    uid: uid,
  });

  return respose;
}

export async function DeleteUserApi({ data }) {
  console.log(data);
  const respose = await fetch(`${apiUrl}/userDelete`, {
    method: "post",
    mode: "no-cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const revdata = await respose.json();

  return revdata;
}

export async function GetAllUsers({ firmID }) {
  const respose = await GetUsersOfFirmID({
    firmID: firmID,
  });

  return respose;
}
