import React, { useEffect, useState, useRef } from "react";
import { TextInput, Image, Text, View, TouchableOpacity } from "react-native";
import { useDispatch } from "react-redux";

import Styles from "./Styles";

import { UserAuthApi } from "../../Functions/ApiFunctions";

import Logo from "../../assets/logo.png";

export default function App(props) {
  const dispatch = useDispatch();
  const [isVerified, setVerified] = useState(true);
  const [user, setUser] = useState();
  const [pwd, setPwd] = useState();

  const pwdRef = useRef();
  const userRef = useRef();

  // useEffect(() => {
  //   UserAuthApi({ userName: "goldtest100", pwd: "1234" }).then((data) => {
  //     storeData(data);
  //     setVerified(data.status == "active" && data.login ? true : false);
  //   });
  // }, []);

  const storeData = async (value) => {
    props.onSignInPress && props.onSignInPress();
    dispatch({ type: "USER_DATA_LOADING", payLoad: value });
  };

  return (
    <View style={Styles.appContainer}>
      <View style={Styles.loginTopTap}>
        <img style={{ height: "20vmin" }} src={Logo} />
        <Text style={Styles.heading}>GOLD PLUS</Text>
        <Text style={Styles.tagLine}>TOWARDS "ACCURATE PROFIT"! </Text>
      </View>
      <View style={Styles.loginCard}>
        <TextInput
          style={Styles.textInput}
          autoFocus={true}
          placeholder="USER NAME"
          onChangeText={(e) => {
            setUser(e);
            setVerified(true);
          }}
          value={user}
          onSubmitEditing={() => user && pwdRef.current.focus()}
          ref={userRef}
          onKeyPress={(data) => data.key == "ArrowRight" && pwdRef.current.focus()}
        />
        <TextInput
          style={Styles.textInput}
          placeholder="PASSWORD"
          onChangeText={(e) => {
            setPwd(e);
            setVerified(true);
          }}
          onKeyPress={(data) => data.key == "ArrowLeft" && userRef.current.focus()}
          value={pwd}
          secureTextEntry={true}
          onSubmitEditing={(data) => {
            if (user && pwd)
              UserAuthApi({ userName: user, pwd: pwd }).then((data) => {
                storeData(data);
                setVerified(data.status == "active" && data.login ? true : false);
              });
          }}
          ref={pwdRef}
        />
        <Text style={[Styles.warningText]}> {!isVerified && "An Unauthorized Action"} </Text>
        <View style={{ width: "70%", alignSelf: "center", marginTop: 10 }}>
          <TouchableOpacity
            style={[Styles.button]}
            onPress={() => {
              if (user && pwd)
                UserAuthApi({ userName: user, pwd: pwd }).then((data) => {
                  storeData(data);
                  setVerified(data.status == "active" && data.login ? true : false);
                });
            }}
          >
            <Text style={Styles.buttonText}>SIGN IN</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
