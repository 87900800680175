import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
    height: "100vh",
    backgroundColor: "#E4F7FE",
  },
  loginLogo: {
    height: 120,
  },
  loginTopTap: {
    height: "60%",
    paddingTop: "2vmin",
    alignItems: "center",
    backgroundColor: "#0000AF",
  },
  heading: {
    marginTop: 10,
    color: "#FFFFFF",
    fontSize: "6vmin",
    fontWeight: "bold",
  },
  tagLine: {
    color: "#FFFFFF",
    marginTop: "1vmin",
    fontSize: "5vmin",
    fontWeight: "bold",
  },
  loginCard: {
    width: "40%",
    maxWidth: "550px",
    alignSelf: "center",
    backgroundColor: "white",
    padding: "3%",
    marginTop: "-7.5%",
    borderRadius: 10,
  },
  textInput: {
    margin: "1vmin",
    width: "100%",
    fontSize: "max(2.9vmin, 16px)",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: 5,
    borderColor: "#757474",
    borderWidth: 0,
    marginLeft: 0,
    borderWidth: 2,
    borderColor: "black",
  },
  button: {
    backgroundColor: "#037EE0",
    borderRadius: 20,
    paddingTop: 7,
    paddingBottom: 7,
  },
  buttonText: {
    fontSize: "2.5vmin",
    textAlign: "center",
    fontWeight: "bold",
    color: "white",
  },
  warningText: {
    color: "red",
    fontSize: "1.5vmin",
    textAlign: "center",
  },
});

export default styles;
