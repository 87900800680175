import React, { useEffect, useState, useRef } from "react";
import { Modal, Pressable, Text, View, TouchableOpacity, TextInput, Keyboard } from "react-native";
import moment from "moment";
import { ThousandView, CapitalizeWords, FormatingReceipt } from "../../Functions/Functions";
import { useSelector } from "react-redux";

import Styles from "./Styles";
import Select from "react-select"; //https://medium.com/@lahiru0561/react-select-with-custom-label-and-custom-search-122bfe06b6d7
import { useAlert } from "react-alert";
import Shortcutkey from "../../Shortcutkey/shortcutkey";
import {PayMethord} from './Common'


const SelectStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles) => ({ ...styles, paddingTop: "6px", paddingBottom: "6px" }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "green",

    zIndex: 10,
  }),
  container: () => ({
    width: "100%",
    fontSize: "3vmin",
    padding: "1.5vmin",

    marginLeft: -10,
  }),
};

export function AddPaymentModal(props) {
  const [payAmount, setPayAmount] = useState();
  const [payMethod, setPayMethod] = useState("Cash");
  const [comment, setComment] = useState();
  const preInfo = useSelector((state) => state.data.preInfo);

  const payMethodRef = useRef();
  const payAmountRef = useRef();
  const commentRef = useRef();
  const alert = useAlert();

  const [submitionStatus, setSubmitionStatus] = useState("SUBMIT");

  useEffect(() => {
    setTimeout(() => payMethodRef.current.focus(), 10);
  }, []);

  function EventHandler(type) {
    switch (type) {
      case "SUBMIT":
        if (Number(payAmount) && payMethod) {
          props.isAdvance
            ? props.onSubmit({
                payAmount: Number(payAmount) || 0,
                payMethod: payMethod,
                comment: comment,
              })
            : props.onSubmit(
                FormatingReceipt({
                  stakeholderDetails: props.stakeholderDetails,
                  preInfo: preInfo,
                  payAmount: payAmount,
                  payMethod: payMethod,
                  comment: comment,
                  type:
                    props.stakeholderDetails.type == "Customer"
                      ? "Paid_A/C_Paid"
                      : "Paid_Purchase_Paid",
                })
              );
          props.stakeholderDetails.type == "Supplier" && props.close();
          setSubmitionStatus("PRINT");
        } else if (payAmount == "0") props.close();
        else {
          alert.error("Any of required * feild is missing");
        }

        return;
      case "PRINT":
        props.onPrint();
        return props.close();
    }
  }

  return (
    <Modal transparent={true} visible={true} useNativeDriver={true} onRequestClose={props.close}>
      <TouchableOpacity style={Styles.modalCenterdView} activeOpacity={1} onPress={props.close}>
        <TouchableOpacity style={Styles.modalView} activeOpacity={1}>
          <Text style={[Styles.buttonText, { color: "black" }]}>
            ADD {props.isAdvance ? "AN ADVANCE" : "A PAYMENT"}
          </Text>
          <View style={Styles.modelTextInput}>{props.stakeholderDetails.name}</View>
          <Shortcutkey
            type="AddNewStakeHolder"
            onPopUpModal={(type) => {
              if (type == "AddNewStakeHolder") {
                props.close();
              }
            }}
          />
          <Select
            menuPortalTarget={document.body}
            styles={SelectStyles}
            classNamePrefix="select"
            defaultValue={PayMethord[0]}
            isDisabled={submitionStatus == "PRINT"}
            isLoading={false}
            isClearable={true}
            style={{ width: 100 }}
            isRtl={false}
            isSearchable={true}
            name="color"
            options={PayMethord}
            onChange={(data) => {
              data && setPayMethod(data.value);
            }}
            ref={payMethodRef}
            returnKeyType="next"
            onKeyDown={(data) => {
              data.key == "Enter" && payAmountRef.current.focus();
            }}
          />
          <TextInput
            value={ThousandView(payAmount)}
            onChangeText={(data) => {
              let value = data.replace(/,/g, "");
              if (Number(value) || !value || value == "0") setPayAmount(value);
              else alert.error("Input should be a number");
            }}
            style={Styles.modelTextInput}
            paddingLeft={12}
            editable={submitionStatus != "PRINT"}
            keyboardType={"decimal-pad"}
            placeholder="Amount *"
            ref={payAmountRef}
            onSubmitEditing={() => {
              if (payAmount) commentRef.current.focus();
            }}
          />

          <TextInput
            value={comment}
            onChangeText={setComment}
            paddingLeft={12}
            style={Styles.modelTextInput}
            placeholder="Comments"
            editable={submitionStatus != "PRINT"}
            ref={commentRef}
            onSubmitEditing={() => {
              EventHandler(submitionStatus);
            }}
          />

          <View
            style={{
              flexDirection: "row",
              width: "90%",
              justifyContent: "center",
            }}
          >
            <Pressable
              style={[Styles.button, { width: "50%", backgroundColor: "green" }]}
              onPress={() => EventHandler(submitionStatus)}
            >
              <Text style={[Styles.buttonText, { color: "white" }]}>{submitionStatus}</Text>
            </Pressable>
            <Pressable
              style={[Styles.button, { width: "50%", backgroundColor: "red", marginLeft: "5%" }]}
              onPress={() => props.close()}
            >
              <Text style={[Styles.buttonText, { color: "white" }]}>CLOSE (Esc)</Text>
            </Pressable>
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}
