import React, { Component, useState, useEffect, useRef } from "react";
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  TextInput,
} from "react-native";
import AdvanceModal from "../Modals/AdvanceModal";
import { UpdateReceipt } from "../../Firebase/Main";
import Shortcutkey from "../../Shortcutkey/shortcutkey";
import {
  ThousandView,
  TotalBillList,
  DeleteFromBill,
  InsertToBillList,
} from "../../Functions/Functions";
function App(props) {
  const [addPayModal, setAddPayView] = useState(false); //payment
  const activeRef = useRef(props.isInvActive);
  const submitted = useRef(props.submitStatus);
  const inveTypeRef = useRef(props.invType);

  useEffect(() => {
    activeRef.current = props.isInvActive;
    submitted.current = props.submitStatus;
    inveTypeRef.current = props.invType;
  }, [props.submitStatus, props.isInvActive, props.invType]);

  return (
    <View style={Styles.viewTag}>
      <Shortcutkey
        type={["Bill", "Stakeholders"]}
        onSubmit={(type) => {
          if (type.includes("Bill")) {
            if (activeRef.current) {
              ["Sales_Saved", "VendorPurchase"].includes(inveTypeRef.current)
                ? setAddPayView(true) //props.onSubmit();
                : props.onSubmit(); //props.onSubmit();
            } else if (submitted.current) props.onPrint();
          }
        }}
        onClean={(type) => {
          if (type.includes("Bill")) {
            props.onClean();
          }
        }}
      />
      {addPayModal && (
        <AdvanceModal
          stakeholderDetails={props.stakeholderDetails}
          total={TotalBillList(props.billItems)}
          close={() => {
            setAddPayView(false);
          }}
          alreadyBill={props.alreadyBill}
          isAdvance={true}
          onSubmit={(data) => {
            props.onSubmit(data);
            setAddPayView(false);
          }}
          onPrint={() => null}
        />
      )}

      <View style={{ width: "30%" }}>
        <TouchableOpacity
          style={[Styles.buttonView, props.isInvActive && { backgroundColor: "red" }]}
          onPress={props.onClean}
        >
          <Text style={[Styles.buttonText, props.isInvActive && { color: "white" }]}>
            {props.isInvActive ? "CANCEL (Esc)" : "NEW BILL (Esc)"}
          </Text>
        </TouchableOpacity>
      </View>

      <View style={{ width: "30%", marginLeft: "10%", marginRight: "1%" }}>
        <TouchableOpacity
          style={Styles.buttonView}
          onPress={() => {
            if (props.isInvActive) {
              ["Sales_Saved", "VendorPurchase"].includes(props.invType)
                ? setAddPayView(true) //props.onSubmit();
                : props.onSubmit();
            } else if (submitted.current) props.onPrint();
          }}
        >
          <Text style={Styles.buttonText}>
            {submitted.current ? "PRINT (F12)" : "SUBMIT (F12)"}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const Styles = StyleSheet.create({
  viewTag: {
    width: "95%",
    marginTop: "0.1%",
    flexDirection: "row",
    maxWidth: "1400px",
    borderRadius: "1vmin",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    //backgroundColor: "#6AC0FF",
  },

  buttonView: {
    width: "100%",
    margin: "1vmin",
    fontSize: "max(2.2vmin, 14px)",
    padding: "7px",
    borderRadius: 10,
    backgroundColor: "#FFAC30",
    textAlign: "center",
    fontWeight: "bold",
  },

  buttonText: {
    fontSize: "max(2.2vmin, 14px)",
    textAlign: "center",
    fontWeight: "bold",
  },
});

export default App;
