import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dbTree } from "../../Firebase/Main";

export default function Apps() {
  const userData = useSelector((state) => state.data.userData);

  const dispatch = useDispatch();

  useEffect(() => {
    const vendorProductRef = dbTree.ref(`${userData.firmID}/vendorProduct/`);
    var vendorProductchanges = vendorProductRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = snapshot.val();
        dispatch({
          type: "VENDOR_PRODUCT_LOADING",
          payLoad: data,
        });
      } else {
        dispatch({ type: "VENDOR_PRODUCT_LOADING", payLoad: {} });
        return;
      }
    });

    return () => {
      vendorProductRef.off("value", vendorProductchanges);
    };
  }, []);

  return <></>;
}
