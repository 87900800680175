import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { db } from "../../Firebase/Main";

export default function Apps(props) {
  const userData = useSelector((state) => state.data.userData);

  const dispatch = useDispatch();
  
  useEffect(() => {
    const unsubUserdata = db
      .collection(`/${userData.firmID}/Receipts/AllReceipts/`)
      .where("type", "in", ["VendorPurchase"])
      .orderBy("timeStamp", "desc")
      .limit(20)
      .onSnapshot(
        (docSnapshot) => {
          let outData = docSnapshot.docs.map((doc) => {
            let data = doc.data();
            return Object.assign({
              ...data,
              type: data.deletedType || data.type,
            });
          });

          return dispatch({ type: "REALTIME_RECEIPTS", payLoad: outData });
        },
        (err) => {
          alert(`Encountered error: ${err}`);
          return dispatch({ type: "REALTIME_RECEIPTS", payLoad: [] });
        }
      );

    return () => {
      unsubUserdata();
    };
  }, []);

  return <></>;
}
