import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  Pressable,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Keyboard,
  Image,
} from "react-native";
import moment from "moment";
import { useAlert } from "react-alert";
import { UserUpdate } from "../../Functions/ApiFunctions";
import { useSelector } from "react-redux";

import Styles from "./Styles";

export function PincodeVerfication(props) {
  const [pin, setPin] = useState();
  const [vPinKeyboard, setVPinKeyboard] = useState(false);
  const [attempt, setAttempt] = useState(2);
  const userData = useSelector((state) => state.data.userData);
  const pinRef = useRef();
  const alert = useAlert();

  function EventHandler(type) {
    if (pin == userData.pin) {
      props.onVerified();
      return props.close();
    } else {
      attempt > 0 && alert.error(`Verification Faild! \n You have Only ${attempt} more try(s)`);
      setAttempt(attempt - 1);
      setPin();
      pinRef.current.clear();
      setTimeout(pinRef.current.focus, 10);
      setVPinKeyboard(false);
      attempt == 0 &&
        UserUpdate({
          uid: userData.uid,
          data: { login: false },
        });

      return;
    }
  }

  function textingVerify(data) {
    if (data == userData.pin) {
      props.onVerified();
      return props.close();
    }
    props.uid &&
      attempt == -1 &&
      UserUpdate({
        uid: props.uid,
        data: { login: false },
      });
  }

  return (
    <Modal transparent={true} visible={true} useNativeDriver={true} onRequestClose={props.close}>
      <TouchableOpacity
        style={Styles.modalCenterdView}
        activeOpacity={1}
        onPress={() => props.onFaild()}
      >
        <TouchableOpacity style={Styles.modalView} activeOpacity={1}>
          <Text style={[Styles.buttonText, { color: "red" }]}>ALERT !</Text>
          <Text style={[Styles.buttonText, { color: "black" }]}>
            Sorry! We need the pin verification
          </Text>

          <TextInput
            value={pin}
            onChangeText={(value) => {
              if (Number(value) || !value) {
                setPin(value);
                textingVerify(value);
              } else alert.show("Input should be a number");
            }}
            autoFocus={true}
            secureTextEntry={true}
            style={Styles.modelTextInput}
            paddingLeft={12}
            keyboardType={"decimal-pad"}
            placeholder="Enter your pin"
            onSubmitEditing={() => {
              if (pin) EventHandler();
            }}
            ref={pinRef}
            onKeyPress={(data) => data.key == "Escape" && props.onFaild()}
          />

          <View
            style={{
              flexDirection: "row",
              width: "90%",
              justifyContent: "center",
            }}
          >
            <Pressable
              style={[Styles.button, { width: "50%", backgroundColor: "green", padding: -10 }]}
              onPress={() => EventHandler()}
            >
              <Text style={[Styles.buttonText, { color: "white" }]}>OK</Text>
            </Pressable>
            {!props.isBackDisabled && (
              <Pressable
                style={[Styles.button, { width: "50%", backgroundColor: "red", marginLeft: "5%" }]}
                onPress={() => props.onFaild()}
              >
                <Text style={[Styles.buttonText, { color: "white" }]}>BACK (Esc)</Text>
              </Pressable>
            )}
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}

export function InterNetError(props) {
  console.clear();
  useEffect(() => {
    const timeout = setInterval(() => {
      console.clear();
    }, 10);
    return () => clearInterval(timeout);
  }, []);

  return (
    <Modal transparent={true} visible={true} useNativeDriver={true} onRequestClose={props.close}>
      <TouchableOpacity style={Styles.modalCenterdView} activeOpacity={1}>
        <TouchableOpacity style={Styles.modalView} activeOpacity={1}>
          <Text style={[Styles.buttonText, { color: "red" }]}>NO INTERNET !</Text>
          <Text style={[Styles.buttonText, { color: "black" }]}>
            The internet is essential to continue your work,
          </Text>
          <Text style={[Styles.buttonText, { color: "black" }]}>
            Pls make sure the connectivity..
          </Text>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}

export function Alert(props) {
  return (
    <Modal transparent={true} visible={true} useNativeDriver={true} onRequestClose={props.close}>
      <TouchableOpacity style={Styles.modalCenterdView} activeOpacity={1}>
        <TouchableOpacity style={Styles.modalView} activeOpacity={1}>
          <Text style={[Styles.buttonText, { color: "red" }]}>ALERT !</Text>
          <View style={{ alignItems: "flex-start" }}>
            <Text style={[Styles.buttonText, { color: "black", fontWeight: "normal" }]}>
              {props.message}
            </Text>
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}

export function ViewImage(props) {
  console.log(props.image.url);
  return (
    <Modal transparent={true} visible={true} useNativeDriver={true} onRequestClose={props.close}>
      <TouchableOpacity style={Styles.modalCenterdView} activeOpacity={1} onPress={props.close}>
        <TouchableOpacity
          style={Styles.modalView}
          activeOpacity={1}
          onPress={() => {
            Keyboard.dismiss();
          }}
        >
          <View style={Styles.imageContainer}>
            <Image
              source={{ uri: props.image.url }}
              style={{ flex: 1, backgroundColor: "transparent", flexDirection: "row" }}
            />
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}
