import React, { useEffect, useState, useRef } from "react";
import { Modal, Pressable, Text, View, TouchableOpacity, TextInput, Keyboard } from "react-native";
import moment from "moment";

import { AddtoProductTree } from "../../Firebase/Main";
import Shortcutkey from "../../Shortcutkey/shortcutkey";

import { UserUpdate } from "../../Functions/ApiFunctions";
import { Product, VariableClass } from "../../Functions/Classes";

import { useSelector } from "react-redux";
import Styles from "./Styles";
import { useAlert } from "react-alert";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./DatesBoundPickers.css";

export default function NewStakeholderModal(props) {
  const [cat, setCat] = useState();
  const firmInfo = useSelector((state) => state.data.firmInfo);
  const [startDate, setStartDate] = useState(
    props.startDate ? new Date(props.startDate) : new Date()
  );
  const [endDate, setEndDate] = useState(props.endDate ? new Date(props.endDate) : new Date());

  const noteRef = useRef();

  const [refresh, setRefresh] = useState(false);

  function onDateChange(date) {
    const selectDate =
      date.getFullYear() +
        "-" +
        ("0" + Number(date.getMonth() + 1).toLocaleString()).slice(-2) +
        "-" +
        ("0" + date.getDate().toLocaleString()).slice(-2) || null;

    return selectDate;
  }

  return (
    <Modal transparent={true} visible={true} useNativeDriver={true} onRequestClose={props.close}>
      <Shortcutkey
        type="AddNewStakeHolder"
        onPopUpModal={(type) => {
          if (type == "AddNewStakeHolder") {
            props.close();
          }
        }}
      />
      <TouchableOpacity style={Styles.modalCenterdView} activeOpacity={1} onPress={props.close}>
        <TouchableOpacity style={Styles.modalView} activeOpacity={1}>
          <Text style={[Styles.buttonText, { color: "black" }]}>DATE BOUND SEARCH & FILTERS</Text>

          <Text
            style={{
              textAlign: "left",
              zIndex: 9999,
              marginBottom: -10,
              marginLeft: 20,
              width: "100%",
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {"START DATE"}
          </Text>
          <View style={[Styles.modelTextInput, { zIndex: 13 }]}>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                props.setStartDate(onDateChange(date));
              }}
              style={{ width: "95%" }}
              wrapperClassName="datePicker"
            />
          </View>

          <Text
            style={{
              textAlign: "left",
              color: "blue",
              marginBottom: -10,
              marginLeft: 20,
              width: "100%",
              fontWeight: "bold",
            }}
          >
            {"END DATE"}
          </Text>
          <View style={[Styles.modelTextInput, { zIndex: 10 }]}>
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                setEndDate(date);
                props.setEndDate(onDateChange(date));
              }}
              style={{ width: "95%" }}
              wrapperClassName="datePicker"
            />
          </View>

          <>
            <TextInput
              onChangeText={(k) => {}}
              style={Styles.modelTextInput}
              placeholder="Any Note"
              ref={noteRef}
              onSubmitEditing={() => {
                setRefresh(!refresh);
              }}
              // onKeyPress={(data) => data.key == "Escape" && props.close()}
            />
            <View
              style={{
                flexDirection: "row",
                width: "90%",
                justifyContent: "center",
              }}
            >
              <Pressable
                style={[Styles.button, { width: "50%", backgroundColor: "green" }]}
                onPress={() => {
                  props.close();
                }}
              >
                <Text style={[Styles.buttonText, { color: "white" }]}>OK/CLOSE</Text>
              </Pressable>
              <Pressable
                style={[Styles.button, { width: "50%", marginLeft: "5%" }]}
                onPress={() => {
                  props.setStartDate(onDateChange(new Date()));
                  props.setEndDate(false);
                  props.close();
                }}
              >
                <Text style={[Styles.buttonText]}>CLEAR/RESET</Text>
              </Pressable>
            </View>
          </>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}
