import moment from "moment";

var userDataGlobal;
var root = {};
var productSearchList = [];

export function updateVeriableUserdataInFU({ temproot, userData, tempProductSearch }) {
  userDataGlobal = userData;
  root = temproot;
  productSearchList = Object.values(tempProductSearch);
  return true;
}

export function TotalBill(billItems) {
  let total = 0;
  Object.keys(billItems).map((cat, index) =>
    Object.keys(billItems[cat]).map((make, index2) =>
      Object.keys(billItems[cat][make]).map((item, index3) => {
        total += billItems[cat][make][item].unitPrice * billItems[cat][make][item].quantity;
      })
    )
  );
  return total;
}
export function DeleteBillItem(billItems, data) {
  let dubBill = { ...billItems };
  if (Object.keys(dubBill[data.cat]).length == 1) {
    delete dubBill[data.cat];
    return dubBill;
  } else if (Object.keys(dubBill[data.cat][data.make]).length == 1) {
    delete dubBill[data.cat][data.make];
    return dubBill;
  } else {
    delete dubBill[data.cat][data.make][data.item];
    return dubBill;
  }
}

export function AddBillItem(billItems, data) {
  let items;
  if (billItems[data.cat]) {
    items = {
      ...billItems[data.cat][data.make],
      [data.item]: {
        item: data.item,
        unitPrice: data.unitPrice,
        quantity: data.quantity,
        units: data.units,
      },
    };
  } else {
    items = {
      [data.item]: {
        item: data.item,
        unitPrice: data.unitPrice,
        quantity: data.quantity,
        units: data.units,
      },
    };
  }

  let make = { ...billItems[data.cat], [data.make]: items };
  let bill = { ...billItems, [data.cat]: make };
  return bill;
}

export function AddBill(billItems, data) {
  let bill = { ...billItems };
  let child = Object.keys(data)[0];

  if (bill.hasOwnProperty(child) && !bill[child].hasOwnProperty("item")) {
    return { ...billItems, [child]: AddBill(billItems[child], data[child]) };
  }
  return { ...bill, ...data };
}

export function ThousandView(number) {
  if (Number(number)) {
    let thousand = number.toString().split(".");
    let strng = thousand[0];
    let result = strng.slice(-3);
    strng = strng.slice(0, -3);
    while (strng.length > 0) {
      result = strng.slice(-3) + "," + result;
      strng = strng.slice(0, -3);
    }
    if (thousand.length > 1) return result + "." + thousand[1];
    return result;
  }
  return number;
}

export function StackFinder(root, stack) {
  let sampleRoot = root;
  for (let i = 0; i < stack.length; i++) {
    if (sampleRoot[stack[i]]) sampleRoot = sampleRoot[stack[i]];
    else return false;
  }
  return sampleRoot;
}

export function ObjToTree(obj) {
  let tree = {};
  Object.values(obj).map((data, index) => {
    let branch = {
      [data.cat]: {
        [data.make]: {
          [data.item]: {
            item: data.item,
            unitPrice: data.unitPrice,
            quantity: data.quantity,
            id: data.id,
          },
        },
      },
    };
    tree = AddBill(tree, branch);
  });
  return tree;
}

export function TotalBillList(obj) {
  let total = 0;
  Object.values(obj).map((data, index) => {
    total = total + Number(data.unitPrice);
  });
  return total;
}

export function TotalStockReduct(obj) {
  let total = 0;
  Object.values(obj).map((data, index) => {
    total = total + data.quantity * data.purchaseAvg;
  });
  return total;
}

export function InsertToBillList(obj, data) {
  return { ...obj, [data.id]: data };
}

export function DeleteFromBill(obj, id) {
  let temp = { ...obj };
  delete temp[id];
  return temp;
}

export function FormatingReceipt(data, editBill) {
  let total = data.items ? TotalBillList(data.items) : data.total || data.payAmount || false;
  let stockValueReduction =
    data.items && data.type.includes("Sales") ? TotalStockReduct(data.items) : false;

  return {
    timeStamp: editBill ? editBill.timeStamp : moment().format("YYYY-MM-DD HH:mm:ss_SSS"),
    date: data.preInfo ? data.preInfo.date : moment().format("YYYY-MM-DD"),
    time: editBill ? editBill.time : moment().format("hh:mm:ss a"),
    invoiceSN: editBill
      ? editBill.invoiceSN
      : userDataGlobal.uid.slice(0, 6) +
        data.type.toUpperCase().slice(0, 2) +
        ("000" + userDataGlobal.invoiceSN).slice(-4),

    name: data.stakeholderDetails
      ? data.stakeholderDetails.name || data.stakeholderDetails
      : data.name || "GOOD NAME",
    nameID: data.stakeholderDetails ? data.stakeholderDetails.id || false : false,
    namePhone: data.stakeholderDetails ? data.stakeholderDetails.phone || false : false,
    nameAdress: data.stakeholderDetails ? data.stakeholderDetails.adress || false : false,
    nameNIC: data.stakeholderDetails ? data.stakeholderDetails.NIC || false : false,

    type: data.type,

    itemNames: data.items
      ? [
          Object.values(data.items).map(
            (data) => data.label || data.make + " " + data.item || data.des
          ),
        ].join(", ")
      : data.comment || "Nothing",
    itemList: data.items || false,

    total: total,
    stockValueReduction: stockValueReduction,
    payAmount: data.payAmount || 0,
    paidfor: data.paidfor || false,
    balance: total - (data.payAmount || 0),

    supType: data.stakeholderDetails ? data.stakeholderDetails.type : data.supType || false,
    payMethod: data.payMethod || false,
    payMethod1: data.payMethod1 || false,
    payMethod2: data.payMethod2 || false,
    payMethod2Amount: data.payMethod2Amount || false,
    purchase: data.purchase || false,

    issuedby: editBill ? editBill.issuedby : userDataGlobal.userName,
    issuedUID: editBill ? editBill.issuedUID : userDataGlobal.uid,

    puchMethord: data.puchMethord || false,

    linkStamps: editBill ? editBill.linkStamps : data.linkStamps || false,
  };
}

export function LebelingFromID(id) {
  let path = id.split("_");

  let baseName = ProductNameDecode(path[path.length - 2]);

  if (baseName.length > 20) return baseName.slice(0, 20) + "...";
  else if (baseName.toLowerCase().includes(ProductNameDecode(path[path.length - 3]).toLowerCase()))
    return baseName;

  baseName = baseName + " " + ProductNameDecode(path[path.length - 3]);

  if (baseName.length > 20) return baseName.slice(0, 20) + "...";

  return baseName;
}

export function FilterReceipts(data, type) {
  if (Array.isArray(type))
    return data.filter(function (x) {
      return type.includes(x.type);
    });

  switch (type) {
    case "Trash":
      return data;
    case "Purchase":
      return data.filter(function (x) {
        return ["Purchase"].includes(x.type) && Number(x.balance) > 0;
      });
    case "Sales":
      return data.filter(function (x) {
        return ["Sales", "Sales_Saved"].includes(x.type) && Number(x.balance) == 0;
      });
    case "Paid":
      return data.filter(function (x) {
        return ["Paid_Sales", "Paid_Order", "Paid_SMS"].includes(x.type);
      });

    default:
      return data.filter(function (x) {
        return type == x.type;
      });
  }
  // if (type == "Trash") return data;
  // else if (Array.isArray(type))
  //   return data.filter(function (x) {
  //     return type.includes(x.type);
  //   });
  // return data.filter(function (x) {
  //   return x.type == type;
  // });
}

export function RandomNumberInRange(min, max) {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function NameWithInitial(name2) {
  let name = name2.trim();
  if (name && typeof name == "string") {
    let nameList = name.split(/[ .:;?!~,`"&|()<>{}\[\]\r\n/\\]+/);
    if (nameList.length > 1) {
      let finName = "";
      nameList.map((tempName, index) => {
        if (index + 1 == nameList.length)
          finName = finName + (tempName[0] || "").toUpperCase() + tempName.slice(1).toLowerCase();
        else finName = finName + (tempName[0] || "").toUpperCase() + ". ";
      });
      return finName;
    } else return name[0].toUpperCase() + name.slice(1).toLowerCase();
  }
  return false;
}

export function CapitalizeWords(str) {
  let strList = str.trim().split(" ");
  let capitalList = [];
  strList.map((data) => {
    if (data.length > 0)
      ["AND", "OF", "FROM"].includes(data.toUpperCase())
        ? capitalList.push(data.toLowerCase())
        : capitalList.push(data[0].toUpperCase() + data.slice(1).toLowerCase());
  });
  return capitalList.join(" ");
}

export function ProductNameDecode(str) {
  return str.replace(/&&/g, ".").replace(/--/g, "/");
}

export function ProductNameEncode(str) {
  return CapitalizeWords(str.replace(/\./g, "&&").replace(/\//g, "--"));
}

export function ReceiptPrintformating({ billItems, timeStamp, name, type }) {
  let formattedRecipients = {
    header:
      moment(timeStamp, "YYYY-MM-DD HH:mm:ss_SSS").format("YYYY-MM-DD, hh:mm:ss a") +
      "- " +
      type +
      "- " +
      name,
  };
  let len = Object.keys(billItems).length;
  Object.values(billItems).map((data, index) => {
    formattedRecipients = {
      ...formattedRecipients,
      ["nl" + (index + 11)]:
        data.make +
        " " +
        data.item +
        "- " +
        data.quantity +
        " x Rs. " +
        ThousandView(data.unitPrice) +
        "- Rs. " +
        ThousandView(data.unitPrice * data.quantity) +
        ".00",
      ...(index == len - 1 && {
        ["nl" + (len + 11)]: "TOTAL- Rs. " + ThousandView(TotalBillList(billItems)) + ".00",
      }),
    };
  });

  return formattedRecipients;
}

export function SearchInList(str, SearchList) {
  let sortList = SearchList.filter((el) => el.toUpperCase().indexOf(str.toUpperCase()) > -1);
  let sortobj = {};

  sortList.map((data) => {
    let pathItem = data.split("#")[0].split("_");
    let path = pathItem.slice(-3, -1).join("-");
    sortobj = {
      ...sortobj,
      [path]: sortobj[path] ? [...sortobj[path], pathItem] : [pathItem],
    };
  });

  return [sortList, sortobj];
}

export function SearchInProductObj(str, SearchObj) {
  let tempOut = {};
  let i = 0;

  for (const property in SearchObj) {
    let data = SearchObj[property];
    let path = data.id.split("_");
    if (
      Number(StackFinder(root, path)) &&
      ProductNameDecode(data.id)
        .toUpperCase()
        .replace(/_/g, "")
        .replace(/ /g, "")
        .includes(str.toUpperCase().replace(/ /g, ""))
    ) {
      i = i + 1;
      let idSample = data.id.split("_");
      let searchpath = idSample.slice(1, idSample.length - 1).join(" -");
      let searchItem = idSample[idSample.length - 1];
      tempOut[searchpath] = { ...(tempOut[searchpath] || {}), [searchItem]: data };
    }
    if (i > 15) break;
  }

  return tempOut;
}
