import React, { useEffect, useState, useRef } from "react";
import { Text, View, TouchableOpacity, TextInput, Modal } from "react-native";

import Select from "react-select"; //https://medium.com/@lahiru0561/react-select-with-custom-label-and-custom-search-122bfe06b6d7

import { ThousandView } from "../../Functions/Functions";
import Styles from "./Styles";
import { useAlert } from "react-alert";
import {PayMethord} from './Common'

const SelectStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles) => ({ ...styles, paddingTop: "6px", paddingBottom: "6px" }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "green",

    zIndex: 10,
  }),
  container: () => ({
    width: "100%",
    fontSize: "3vmin",
    padding: "1.5vmin",

    marginLeft: -10,
  }),
};
export default function AddExpenseModal(props) {
  const [discriptions, setDiscriptions] = useState(null);
  const [payAmount, setPayAmount] = useState();
  const [payMethod, setPayMethod] = useState("Cash");
  const [comment, setComment] = useState();

  const payMethodRef = useRef();
  const commentRef = useRef();
  const payAmountRef = useRef();

  const ref = useRef();

  const [vKeyboard, setVKeyboard] = useState(false);

  const alert = useAlert();

  function EventHandler(type) {
    if (payAmount && payMethod && discriptions) {
      props.onSubmit({
        total: payAmount,
        payAmount: payAmount,
        payMethod: payMethod,
        name: discriptions ,
        itemNames: 'Its an Expense - ' + props.supType,
        des: discriptions,
        comments: comment ? comment : "",
      });
    } else alert.error("Any of required * feild is missing");

    return;
  }
  return (
    <Modal transparent={true} visible={true} useNativeDriver={true} onRequestClose={props.close}>
      <TouchableOpacity style={Styles.modalCenterdView} activeOpacity={1} onPress={props.close}>
        <TouchableOpacity style={Styles.modalView} activeOpacity={1} ref={ref}>
          <Text style={[Styles.buttonText, { color: "black" }]}>
            ADD AN EXPENSE - {props.supType.toUpperCase()}
          </Text>
          <TextInput
            value={discriptions}
            onChangeText={setDiscriptions}
            paddingLeft={12}
            editable={!props.submitStatus}
            autoFocus={true}
            placeholderTextColor="black"
            style={Styles.modelTextInput}
            placeholder={props.subType == "Salary" ? "Name *" : "Description*"}
            onSubmitEditing={() => discriptions && payMethodRef.current.focus()}
            onKeyPress={(data) => data.key == "Escape" && props.close()}
          />
          <Select
            menuPortalTarget={document.body}
            styles={SelectStyles}
            classNamePrefix="select"
            defaultValue={PayMethord[0]}
            isDisabled={props.submitStatus}
            isLoading={false}
            isClearable={true}
            style={{ width: 100 }}
            isRtl={false}
            isSearchable={true}
            name="color"
            options={PayMethord}
            onChange={(data) => {
              data && setPayMethod(data.value);
              data && payAmountRef.current.focus();
            }}
            ref={payMethodRef}
            returnKeyType="next"
            onKeyDown={(data) => {
              data.key == "Enter" && payAmountRef.current.focus();
              data.key == "Escape" && props.close();
            }}
          />
          <TextInput
            value={ThousandView(payAmount)}
            onChangeText={(data) => {
              let value = data.replace(/,/g, "");
              if (Number(value) || !value) setPayAmount(value);
              else alert.error("Input should be a number");
            }}
            style={Styles.modelTextInput}
            paddingLeft={12}
            editable={!props.submitStatus}
            keyboardType={"decimal-pad"}
            placeholder="Amount *"
            ref={payAmountRef}
            onSubmitEditing={() => {
              if (payAmount) commentRef.current.focus();
            }}
            onKeyPress={(data) => data.key == "Escape" && props.close()}
          />
          <TextInput
            value={comment}
            onChangeText={setComment}
            paddingLeft={12}
            editable={!props.submitStatus}
            placeholderTextColor="black"
            style={Styles.modelTextInput}
            placeholder="Comments"
            onSubmitEditing={() => {
              EventHandler();
            }}
            ref={commentRef}
            onKeyPress={(data) => data.key == "Escape" && props.close()}
          />
          <View
            style={{
              flexDirection: "row",
              width: "90%",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity
              style={[Styles.button, { width: "50%", backgroundColor: "green" }]}
              onPress={() => (props.submitStatus ? props.close() : EventHandler())}
            >
              <Text style={[Styles.buttonText, { color: "white" }]}>
                {props.submitStatus ? "NEW" : "SUBMIT"}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[Styles.button, { width: "50%", backgroundColor: "red", marginLeft: "5%" }]}
              onPress={props.close}
            >
              <Text style={[Styles.buttonText, { color: "white" }]}>CLOSE (Esc)</Text>
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}
