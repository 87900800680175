import TeamReducer from './keys'

import { combineReducers } from 'redux';


const allReducers = combineReducers({
    data: TeamReducer,

})

export default allReducers;