import { FundTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Modal, Pressable, Text, View, TouchableOpacity } from "react-native";
import { SetPrintJob } from "../../Firebase/Main";

import Shortcutkey from "../../Shortcutkey/shortcutkey";

import Styles from "./Styles";

export default function App(props) {
  // useEffect(() => {
  //   SetPrintJob({
  //     printerType: "thermalPrint",
  //     printReceipt: {
  //       ...props.receipt,
  //       type: props.receipt.type.split("_")[0],
  //     },
  //     typeUpdate: props.receipt.type.split("_").includes("Saved"),
  //   });
  //   props.onPrintSubmit()
  // }, []);

  // useEffect(() => {
  //   document.addEventListener("keydown", KeyFunction);
  //   return () => document.removeEventListener("keydown", KeyFunction);
  // }, []);

  // function KeyFunction(event) {
  //   switch (event.key) {
  //     case "1":
  //       return onPrinterSelection("thermalPrint");
  //     case "2":
  //       onPrinterSelection("formPrint");
  //   }
  // }

  function onPrinterSelection(printerType) {
    SetPrintJob({
      printerType: printerType,
      printReceipt: {
        ...props.receipt,
        type: props.receipt.type.split("_")[0],
      },
      typeUpdate: props.receipt.type.split("_").includes("Saved"),
    });

    setTimeout(() => props.onPrintSubmit(), 100);
  }

  return (
    <Modal
      //   animationType="slide"
      transparent={true}
      visible={true}
      useNativeDriver={true}
      onRequestClose={props.close}
    >
      <Pressable style={Styles.modalCenterdView} activeOpacity={1} onPress={props.close}>
        <Pressable style={Styles.modalView} activeOpacity={1}>
          <Shortcutkey
            type={["Print"]}
            onSubmit={(type) => {
              type.includes("Print") && props.close();
            }}
            onClean={(type) => {
              type.includes("Print") && props.close();
            }}
            onOption1={(type) => {
              type.includes("Print") && onPrinterSelection("thermalPrint");
            }}
            onOption2={(type) => {
              type.includes("Print") && onPrinterSelection("formPrint");
            }}
          />

          <Text style={[Styles.buttonText, { color: "black" }]}>SELECT YOUR PRINTER</Text>

          <TouchableOpacity
            style={[Styles.button, { width: "90%", margin: "5%", marginBottom: "2.5%" }]}
            onPress={() => onPrinterSelection("thermalPrint")}
          >
            <Text style={[Styles.buttonText]}>{"RECEIPT PRINTER (1)"}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[Styles.button, { width: "90%", margin: "5%", marginTop: "2.5%" }]}
            onPress={() => onPrinterSelection("formPrint")}
          >
            <Text style={[Styles.buttonText]}>{"FORM PRINTER (2)"}</Text>
          </TouchableOpacity>

          <View
            style={{
              flexDirection: "row",
              width: "90%",
              justifyContent: "center",
            }}
          >
            <Pressable
              style={[Styles.button, { width: "50%", backgroundColor: "red" }]}
              onPress={props.close}
            >
              <Text style={[Styles.buttonText, { color: "white" }]}>CLOSE</Text>
            </Pressable>
          </View>
        </Pressable>
      </Pressable>
    </Modal>
  );
}
