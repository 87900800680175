import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

export default function App(props) {
  const dispatch = useDispatch();
  const shortcutStack = useSelector((state) => state.data.shortcutStack);

  const currentRef = useRef();

  useEffect(() => (currentRef.current = shortcutStack[shortcutStack.length - 1]), [shortcutStack]);

  // console.log(currentRef.current);
  useEffect(() => {
    document.addEventListener("keydown", KeyFunction);
    dispatch({ type: "SHORTCUT_PUSH", payLoad: props.type });

    return () => {
      document.removeEventListener("keydown", KeyFunction);
      dispatch({ type: "SHORTCUT_POP" });
    };
  }, []);

  function KeyFunction(event) {
    switch (event.key) {
      case "F12":
        props.onSubmit && props.onSubmit(currentRef.current);
        return;
      case "Escape":
        props.onClean && props.onClean(currentRef.current);
        return;
      case "1":
        props.onOption1 && props.onOption1(currentRef.current);
        return;
      case "2":
        props.onOption2 && props.onOption2(currentRef.current);
        return;
      case "F1":
        props.onPopUpModal && props.onPopUpModal(currentRef.current);
        return;
    }
  }

  return <></>;
}
