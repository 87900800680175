import Firebase from "firebase";
import moment from "moment";
import { db } from "./Config";

var firebaseConfig = {
  apiKey: "AIzaSyAHiNXjCfRz_aQefCYoFglXo4ramCMcyIE",
  authDomain: "smart-pos-plus-secondary.firebaseapp.com",
  projectId: "smart-pos-plus-secondary",
  storageBucket: "smart-pos-plus-secondary.appspot.com",
  databaseURL: "https://smart-pos-plus-secondary-default-rtdb.firebaseio.com/",
  messagingSenderId: "764497036938",
  appId: "1:764497036938:web:fc3e74b4424606cb810f73",
};

var print;
try {
  print = Firebase.app("Print");
} catch (err) {
  print = Firebase.initializeApp(firebaseConfig, "Print");
}

var userDataGlobal;
const dbPrint = print.database();

export function UpdaeVariable_Print({ userData }) {
  userDataGlobal = userData;
}

export async function SetPrintJob({ printReceipt, printerType, typeUpdate }) {
  dbPrint
    .ref(`/${userDataGlobal.firmID}/`)
    .child(printerType)
    .set({
      ...printReceipt,
      printedOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
      firmID: userDataGlobal.firmID,
    });

  if (typeUpdate) {
    const docSnapshot = db
      .collection(`/${userDataGlobal.firmID}/Receipts/AllReceipts/`)
      .doc(printReceipt.timeStamp);

    await docSnapshot.set(
      {
        type: printReceipt.type,
      },
      { merge: true }
    );
  }
}
