import React, { Component, useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import {
  Button,
  Image,
  FlatList,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ScrollView,
} from "react-native";

import { useSelector, useDispatch } from "react-redux";

import { FilterReceipts } from "../../Functions/Functions";

import {
  LoadReceiptsMetaData,
  RetrieveReceipt,
  DeleteReceipt,
  DeleteTrashReceipt,
} from "../../Firebase/Main";
import ReceiptTag from "./ReceiptTag";

import FilterHeader from "../../Componets/FilterBar/FilterBar";

import { useAlert } from "react-alert";

function App(props) {
  const alert = useAlert();
  const [itemDetails, setItemDetails] = useState();

  const [receipts, setReceipts] = useState([]);
  const realtimeReceipts = useSelector((state) => state.data.realtimeReceipts);
  const dispatch = useDispatch();
  const [newDataLength, setNewDataLength] = useState(7);
  const userData = useSelector((state) => state.data.userData);

  const types = props.types || ["VendorPurchase"];

  const [isReceiptAsd, setIsReceiptAsd] = useState(false);
  const [date, setDate] = useState(false);
  const [showDataPick, setShowDataPick] = useState(false);

  const [stakeholderDetails, setStakeholderDetails] = useState(
    props.customerDetails ? props.customerDetails : false
  );

  const [showType, setShowType] = useState(types);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    dispatch({ type: "REALTIME_ACTIVATORS_PUSH", payLoad: "REALTIMERECEIPT" });

    setDate(false);
    if (props.receipts) setTimeout(() => setReceipts(props.receipts), 10);
    stakeholderDetails &&
      stakeholderDetails.id &&
      LoadReceiptsMetaData({
        limit: 10,
        nameID: stakeholderDetails ? stakeholderDetails.id : false,
        asd: isReceiptAsd,
        type: showType,
        date: moment().format("YYYY-MM-DD HH:mm:ss_SSS"),
      }).then((data) => {
        setReceipts(data);
        setNewDataLength(data.length);
      });
  }, [stakeholderDetails]);

  function loadMore(type) {
    return LoadReceiptsMetaData({
      limit: 10,
      nameID: stakeholderDetails ? stakeholderDetails.id : false,
      asd: isReceiptAsd,
      type: type || showType,
      date:
        filteredReceipts.length > 0
          ? filteredReceipts[filteredReceipts.length - 1].timeStamp
          : moment().format("YYYY-MM-DD HH:mm:ss_SSS"),
    }).then((data) => {
      setReceipts([...receipts, ...data.slice(1)]);
      setNewDataLength(data.length);
    });
  }

  const onDateChange = async function (date) {
    const selectDate =
      date.getFullYear() +
        "-" +
        ("0" + Number(date.getMonth() + 1).toLocaleString()).slice(-2) +
        "-" +
        ("0" + date.getDate().toLocaleString()).slice(-2) || null;
    setDate(selectDate);
    setIsReceiptAsd(true);
    return LoadReceiptsMetaData({
      limit: 10,
      nameID: stakeholderDetails ? stakeholderDetails.id : false,
      asd: true,
      type: showType,
      date: selectDate,
    }).then((data) => {
      setReceipts(data);
      setNewDataLength(data.length);
    });
  };

  function reset() {
    setReceipts([]);
    setStakeholderDetails();
    setShowType(types);
  }

  const renderItem = ({ item, index }) => (
    <ReceiptTag
      onEdit={() => props.onEdit(item)}
      showType={showType}
      data={item}
      trash={types == "Trash"}
      index={index}
      editDisabled={showType == "Trash"}
      isModalDisabled={!(typeof item.itemList == "object") || props.isModalDisabled}
      delete={() => {
        if (showType == "Trash") {
          DeleteTrashReceipt(item).then(() => alert.success("Deleted successfully"));
          setReceipts([...receipts.slice(0, index), ...receipts.slice(index + 1)]);
          return true;
        }
        let delIndex = receipts.indexOf(item);
        DeleteReceipt(item).then(() => alert.success("Deleted successfully"));
        setReceipts([...receipts.slice(0, delIndex), ...receipts.slice(delIndex + 1)]);
        props.refresh && props.refresh();
      }}
      onRefresh={() => setRefresh(!refresh)}
      restore={() => {
        RetrieveReceipt(item).then(() => alert.success("Retrieve successfully"));
        setReceipts([...receipts.slice(0, index), ...receipts.slice(index + 1)]);
        return true;
      }}
      onEditUpdate={(data) =>
        setReceipts([...receipts.slice(0, index), data, ...receipts.slice(index + 1)])
      }
    />
  );

  const filteredReceipts = useCallback(
    FilterReceipts(
      stakeholderDetails || date || showType == "Trash"
        ? receipts
        : [...realtimeReceipts, ...receipts],
      showType
    ),
    [receipts, realtimeReceipts, showType, stakeholderDetails]
  );
  return (
    <>
      <FilterHeader
        setShowType={(data) => {
          setShowType(data);
          setNewDataLength(10);
          data == "Trash" &&
            alert.show("HERE YOU CAN SEE THE DELETED ITEMS") &&
            LoadReceiptsMetaData({
              limit: 10,
              type: "Trash",
              date: moment().format("YYYY-MM-DD HH:mm:ss_SSS"),
            }).then((data) => {
              setReceipts(data);
              setNewDataLength(data.length);
            });
          showType == "Trash" ? reset() : setShowType(data);
        }}
        setStakeholderDetails={(data) => {
          setStakeholderDetails(data);
          // LoadReceiptsMetaData({
          //   limit: 10,
          //   nameID: data ? data.id : false,
          //   asd: isReceiptAsd,
          //   type: showType,
          //   date: date || moment().format("YYYY-MM-DD HH:mm:ss_SSS"),
          // }).then((data) => {
          //   setReceipts(data);
          //   setNewDataLength(data.length);
          // });
        }}
        onDateChange={onDateChange}
      />
      <View
        style={[
          Styles.viewTag,
          {
            backgroundColor: "trabsparent",
            width: "90%",
            alignItems: "center",
            justifyContent: "center",
            zIndex: -1,
          },
        ]}
      >
        <ScrollView
          style={{ width: "100%", zIndex: -1, maxHeight: "74.5vh" }}
          showsVerticalScrollIndicator={false}
        >
          <FlatList
            removeClippedSubviews={false}
            data={filteredReceipts}
            style={{ width: "100%", alignSelf: "center" }}
            renderItem={renderItem}
          />

          {newDataLength > 5 && ["admin", "standard"].includes(userData.type) && (
            <TouchableOpacity
              style={Styles.buttonView2}
              onPress={() => {
                loadMore();
              }}
            >
              <Text style={Styles.buttonText}>Load More</Text>
            </TouchableOpacity>
          )}
        </ScrollView>
      </View>
    </>
  );
}

const Styles = StyleSheet.create({
  viewTag: {
    width: "95%",
    // marginTop: 10,
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
    maxWidth: "1400px",
    borderRadius: "1vmin",
    backgroundColor: "#6AC0FF",
  },
  receiptTagView: {
    width: "95%",
    marginTop: "1%",
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
    maxWidth: "1400px",
    borderRadius: 5,
    backgroundColor: "#B3E5FC",
    borderBottomWidth: 2,
    borderColor: "#0000AF",
    flexDirection: "row",
  },
  receiptDate: {
    fontSize: "2vmin",
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: "1vmin",
  },
  buttonView2: {
    width: "100%",
    marginTop: "1%",
    marginBottom: "2%",
    fontSize: "max(16px,1.7vmin)",
    padding: "1vmin",
    borderRadius: 10,
    backgroundColor: "#FFAC30",
    textAlign: "center",
    fontWeight: "bold",
  },

  buttonView: {
    width: "100%",
    margin: "1vmin",
    fontSize: "2vmin",
    padding: "0.8vmin",
    borderRadius: 10,
    backgroundColor: "#FFAC30",
    textAlign: "center",
    fontWeight: "bold",
  },
  buttonText: {
    color: "white",
    fontSize: "2vmin",
    textAlign: "center",
    fontWeight: "bold",
  },
});

export default App;
