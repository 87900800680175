import React, { useEffect, useState, useRef } from "react";
import { Modal, Pressable, Text, View, TouchableOpacity, TextInput, Keyboard } from "react-native";
import moment from "moment";
import { ThousandView, CapitalizeWords, FormatingReceipt } from "../../Functions/Functions";
import Shortcutkey from "../../Shortcutkey/shortcutkey";

import { UserUpdate } from "../../Functions/ApiFunctions";
import { useSelector } from "react-redux";
import Styles from "./Styles";
import { useAlert } from "react-alert";

export function NewStakeholderModal(props) {
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  const [comments, setComments] = useState();
  const [outstanding, setOutstanding] = useState();

  const phoneRef = useRef();
  const outstandingRef = useRef();
  const addressRef = useRef();
  const commentsRef = useRef();
  const alert = useAlert();

  function formVerify(event) {
    let data = null;
    if (name && Number(phone) && outstanding) {
      data = {
        name: CapitalizeWords(name.trim()), //supplier or customer
        type: props.type,
        phone: phone,
        address: address ? address : "unknown",
        comments: comments ? comments : "unknown",
        id: moment().format("YYYY-MM-DD_HH-mm_") + phone.replace(" ", ""),
        timeStamp: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        outstanding: Number(outstanding) ? Number(outstanding) : 0,
        totPurchase: 0,
      };
      props.onSubmit(data);
      props.close();
    } else {
      alert.error("one/more required * feild is empty");
    }
  }
  return (
    <Modal transparent={true} visible={true} useNativeDriver={true} onRequestClose={props.close}>
      <Shortcutkey
        type="AddNewStakeHolder"
        onPopUpModal={(type) => {
          if (type == "AddNewStakeHolder") {
            props.close();
          }
        }}
      />
      <TouchableOpacity style={Styles.modalCenterdView} activeOpacity={1} onPress={props.close}>
        <TouchableOpacity style={Styles.modalView} activeOpacity={1}>
          <TextInput
            value={name}
            autoFocus={true}
            onChangeText={setName}
            paddingLeft={12}
            style={Styles.modelTextInput}
            placeholder={props.type[0].toUpperCase() + props.type.slice(1) + " Name *"}
            onSubmitEditing={() => {
              if (name) phoneRef.current.focus();
            }}
            onKeyPress={(data) => !name && data.key == "Backspace" && props.close()}
          />

          <TextInput
            value={phone}
            onChangeText={setPhone}
            style={Styles.modelTextInput}
            paddingLeft={12}
            keyboardType={"decimal-pad"}
            placeholder="Phone No. *"
            ref={phoneRef}
            onSubmitEditing={() => {
              if (phone) outstandingRef.current.focus();
            }}
            // onKeyPress={(data) => data.key == "Escape" && props.close()}
          />

          <TextInput
            value={ThousandView(outstanding)}
            onChangeText={(data) => {
              let value = data.replace(/,/g, "");
              if (Number(value) >= 0) setOutstanding(value);
              else alert.error("Input should be a number");
            }}
            style={Styles.modelTextInput}
            paddingLeft={12}
            keyboardType={"decimal-pad"}
            placeholder="Initial Outstanding *"
            ref={outstandingRef}
            onSubmitEditing={() => {
              if (outstandingRef) addressRef.current.focus();
            }}
            // onKeyPress={(data) => data.key == "Escape" && props.close()}
          />

          <TextInput
            value={address}
            onChangeText={setAddress}
            style={Styles.modelTextInput}
            paddingLeft={12}
            placeholder="Address"
            ref={addressRef}
            onSubmitEditing={() => {
              commentsRef.current.focus();
            }}
            // onKeyPress={(data) => data.key == "Escape" && props.close()}
          />

          <TextInput
            value={comments}
            onChangeText={setComments}
            style={Styles.modelTextInput}
            placeholder="Any Comments"
            ref={commentsRef}
            onSubmitEditing={() => {
              formVerify("ADD");
            }}
            // onKeyPress={(data) => data.key == "Escape" && props.close()}
          />

          <View
            style={{
              flexDirection: "row",
              width: "90%",
              justifyContent: "center",
            }}
          >
            <Pressable
              style={[Styles.button, { width: "50%", backgroundColor: "green" }]}
              onPress={() => formVerify("ADD")}
            >
              <Text style={[Styles.buttonText, { color: "white" }]}>ADD</Text>
            </Pressable>
            <Pressable
              style={[Styles.button, { width: "50%", backgroundColor: "red", marginLeft: "5%" }]}
              onPress={props.close}
            >
              <Text style={[Styles.buttonText, { color: "white" }]}>CLOSE (Esc)</Text>
            </Pressable>
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}
