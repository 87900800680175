import React, { Component, useState, useEffect, useTheme, useRef, useCallback } from "react";
import {} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { DeleteFromBill, InsertToBillList, FormatingReceipt } from "../../Functions/Functions";
import {
  inventoryUpdate,
  inventoryItemSet,
  UpdateReceipt,
  StakeholderDetailsLoad,
} from "../../Firebase/Main";

import Shortcukey from "../../Shortcutkey/shortcutkey";

import InvoiceAdder from "../../Componets/InvoiceItemAdder/InvoiceItemAdder";
import InvBody from "../../Componets/InvBody/InvBody";
import InvFooter from "../../Componets/InvFooter/InvFooter";
import PrintModal from "../../Componets/Modals/PrintingModal";

import AddExpenseModal from "../../Componets/Modals/AddExpenses";

import AddBankModal from "../../Componets/Modals/AddBankModal";

export default function App(props) {
  const [billItems, setBillItems] = useState({});
  const preInfo = useSelector((state) => state.data.preInfo);
  const dispatch = useDispatch();
  const invType = useRef("VendorPurchase");

  const [formattedRecipient, setFormattedRecipient] = useState();

  const stakeholder = useRef();
  const [submitted, setSubmitted] = useState(props.viewStatus == "ViewOnly" || false);
  const [viewStatus, setViewStatus] = useState(props.viewStatus || false);

  const [printerOption, setPrinterOption] = useState(false);
  const reset = useRef(false);
  const expenseSubType = useRef(false);
  const alert = useAlert();
  const currentBillsRef = useRef();
  const alreadyBillsRef = useRef();
  const submittedRef = useRef(props.viewStatus == "ViewOnly" || false);

  const isInventoryUpdateEnabled = useRef(false);
  isInventoryUpdateEnabled.current = ["Sales_Saved", "Expense"].includes(invType.current);
  const hasStakeHolder = useCallback(["VendorPurchase", "Sales_Saved"].includes(invType.current), [
    invType.current,
  ]);
  useEffect(() => {
    if (!submitted.current) currentBillsRef.current = { ...billItems };
  }, [billItems]);

  useEffect(() => {
    
    dispatch({ type: "REALTIME_ACTIVATORS_PUSH", payLoad: "SUPPLIERLIST" });


    if (props.viewStatus == "ViewOnly") editAction(props.editReceipt);
    else alreadyBillsRef.current = false;
    return () => {
      currentBillsRef.current &&
        inventoryUpdate({
          billItems: currentBillsRef.current,
          increment: true,
          alreadyBilled: alreadyBillsRef.current,
        });
    };
  }, []);

  const editAction = (data) => {
    setBillItems(data.itemList);
  };

  function Submit(data) {
    if (invType.current && preInfo) {
      let formatReceipt = FormatingReceipt(
        {
          ...data,
          items: currentBillsRef.current,
          stakeholderDetails: stakeholder.current,
          preInfo: preInfo,
          type: invType.current,
          ...(expenseSubType.current && {
            supType: expenseSubType.current,
            name: expenseSubType.current,
          }),
        },
        alreadyBillsRef.current
      );

      UpdateReceipt(formatReceipt, alreadyBillsRef.current).then(() => {
        alert.success("Successfully Proceeded");
        setSubmitted(1);
        currentBillsRef.current = false;
        submittedRef.current = true;
        props.onEditUpdate && props.onEditUpdate(formatReceipt);
      });
      if (["VendorPurchase", "Sales_Saved"].includes(invType.current) && stakeholder.current)
        stakeholder.current = {
          ...stakeholder.current,
          outstanding: alreadyBillsRef.current
            ? stakeholder.current.outstanding +
              formatReceipt.balance -
              alreadyBillsRef.current.balance
            : stakeholder.current.outstanding + formatReceipt.balance,
        };
      setFormattedRecipient(formatReceipt);
    }
  }

  const resetState = () => {
    if (props.viewStatus) return props.close();
    reset.current = !reset.current;
    stakeholder.current = null;
    setSubmitted(false);
    expenseSubType.current = false;
    setFormattedRecipient(false);
    invType.current = "VendorPurchase";

    submittedRef.current = false;
    setBillItems({});
  };

  const deleteAction = (data) => {
    setBillItems(DeleteFromBill(billItems, data.id));
    isInventoryUpdateEnabled.current && inventoryItemSet({ item: data, increment: true });
  };
  console.log(props);
  return (
    <>
      {printerOption && (
        <PrintModal
          close={() => setPrinterOption(false)}
          onPrintSubmit={() => {
            setPrinterOption(false);
            alert.success("Receipt Printing Submitted Successfully");
          }}
          receipt={formattedRecipient}
        />
      )}
      {expenseSubType.current && expenseSubType.current != "Damage" && (
        <AddExpenseModal
          supType={expenseSubType.current}
          close={resetState}
          onSubmit={(data) => {
            let formatReceipt = FormatingReceipt({
              ...data,
              preInfo: preInfo,
              type: "Expense",
              supType: expenseSubType.current,
            });
            UpdateReceipt(formatReceipt).then(() => {
              alert.success("Successfully Proceeded");
              setSubmitted(1);
            });
          }}
          submitStatus={submitted}
        />
      )}
      {invType.current == "Bank" && (
        <AddBankModal
          close={resetState}
          onSubmit={(data) => {
            let formatReceipt = FormatingReceipt({
              ...data,
              preInfo: preInfo,
              type: "Bank",
              name: "Deposited",
            });
            UpdateReceipt(formatReceipt).then(() => {
              alert.success("Successfully Proceeded");
              setSubmitted(1);
            });
          }}
          submitStatus={submitted}
        />
      )}
      <InvoiceAdder
        onAdd={(data) => {
          setBillItems(InsertToBillList(billItems, data));
          isInventoryUpdateEnabled.current && inventoryItemSet({ item: data, increment: false }); //decrement
        }}
        setInvoiceType={(data) => (invType.current = data)}
        stakeholderDetails={stakeholder.current}
        invType={invType.current}
        setStakeholderDetails={(data) => (stakeholder.current = data)}
        quantityCheckDisabled={!isInventoryUpdateEnabled.current}
        submitted={submitted}
        reset={reset.current}
        viewStatus={viewStatus}
        setViewStatus={(data) => {
          setViewStatus(data);
          submittedRef.current = false;
          setSubmitted(false);
        }}
        isViewOnly={props.isViewOnly}
        hasStakeHolder={hasStakeHolder}
        editReceipt={props.editReceipt}
        setExpenseSubtype={(data) => (expenseSubType.current = data)}
        expenseSubType={expenseSubType.current}
        billItems={billItems}
        editDisabled={props.editDisabled}
      />

      <InvBody
        submitted={submitted}
        billItems={billItems}
        viewStatus={viewStatus}
        onDelete={(data) => deleteAction(data)}
        onUpdate={(data) => {
          setBillItems(InsertToBillList(billItems, data));
          isInventoryUpdateEnabled.current &&
            data.quantityChange &&
            inventoryItemSet({
              item: { ...data, quantity: data.quantityChange },
              increment: false,
            }); //decrement
        }}
      />
      <InvFooter
        submitStatus={submitted}
        stakeholderDetails={stakeholder.current}
        billItems={billItems}
        isInvActive={
          !submittedRef.current &&
          currentBillsRef.current &&
          Object.keys(currentBillsRef.current).length > 0
        }
        invType={invType.current}
        onSubmit={Submit}
        onPrint={() => setPrinterOption(true)}
        onClean={resetState}
        alreadyBill={alreadyBillsRef.current}
        isEditEnabled={props.isEditEnabled}
      />
    </>
  );
}
