import { StyleSheet, useWindowDimensions, Dimensions } from "react-native";

export const windowWidth = Dimensions.get("window").width;
export const windowHeight = Dimensions.get("window").height;

function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num;
}

// fontSizes
export const headingLarge = windowWidth * 0.05;
export const tagLine = windowWidth * 0.03;
export const textInput = windowWidth * 0.035;
export const label = windowWidth * 0.035;
export const normal = clamp(windowWidth * 0.031, 8, 14);
export const button = windowWidth * 0.04;
export const tree = clamp(windowWidth * 0.041, 10, 18);
export const modelText = windowWidth * 0.041;
export const smallText = windowWidth * 0.025;
