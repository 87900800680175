import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { useAlert } from "react-alert";

import RealtimeLoader from "./RealtimeLoader";
import { UserLogoutApi } from "../Functions/ApiFunctions";

import BillBody from "../Taps/Billing/Main";
import ReceiptLive from "../Taps/Receipts/ReceiptLives";
// import StockTap from "../Taps/StockTap/Main";
import Cashflow from "../Taps/Cashflow/Main";
import ProductTree from "../Taps/Invoice Tree/InvTree";
import VendorProduct from "../Taps/Unlabeled Tree/InvTree";
import GoldManger from "../Taps/Gold Manager/Main";

function App() {
  const [value, setValue] = useState(3);
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment().format("Do MMM YYYY, h:mm:ss a"));

  let headings = [
    "VENDOR PURCHASE",
    "VENDOR INVOICES",
    "UNLABELED STOCK",
    "LABEL STOCK",
    "GOLD REPORTER",
  ];

  const userData = useSelector((state) => state.data.userData);
  const bulkHandler = useSelector((state) => state.data.firmInfo.bulkHandler);

  const alert = useAlert();

  function rendering() {
    switch (value) {
      case 2:
        return <VendorProduct key={value} isInventoryUpdateEnabled={true} />;
      case 1:
        return <ReceiptLive key={value} isInventoryUpdateEnabled={true} />;

      case 3:
        return <ProductTree key={value} isInventoryUpdateEnabled={true} />;

      case 4:
        return <GoldManger key={value} isInventoryUpdateEnabled={true} />;

      case 0:
        return <BillBody key={value} isInventoryUpdateEnabled={false} />; //<Cashflow key={value} backAction={() => setValue(1)} />;
    }
  }

  const logOut = async () => {
    dispatch({ type: "REALTIME_ACTIVATORS_CLEAN", payLoad: "REALTIMERECEIPT" });
    dispatch({ type: "USER_DATA_LOADING", payLoad: false });

    UserLogoutApi({ uid: userData.uid });
  };

  useEffect(
    () =>
      setInterval(() => {
        setDate(moment().format("Do MMM YYYY, h:mm:ss a"));
      }, 1000),
    []
  );

  return (
    <View style={Styles.app}>
      <RealtimeLoader />
      <View style={Styles.header}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            maxWidth: "1400px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={Styles.dateText}>{date.toUpperCase()}</Text>
          <Text style={Styles.firmTitle}>{userData.firmName.toUpperCase()}</Text>
          <TouchableOpacity style={Styles.buttonView} onPress={() => logOut()}>
            <Text style={Styles.buttonText}>SIGN OUT</Text>
          </TouchableOpacity>
        </View>
        <View style={Styles.taps}>
          {headings.map((data, index) => (
            <TouchableOpacity
              key={index}
              style={[
                { width: 100 / headings.length + "%" },
                Styles.tap,
                value == index && { backgroundColor: "#F1F2FB" },
              ]}
              onPress={() => {
                if (!bulkHandler && index < 3) return alert.info("Sorry! you are not permitted");
                if (index == 0) {
                  if (userData.type == "admin") return setValue(index);
                  else return alert.error("Sorry you are not Authorized");
                }
                return setValue(index);
              }}
            >
              <Text
                style={[Styles.tapText, value == index && { color: "black", fontWeight: "bold" }]}
              >
                {data.toLocaleUpperCase()}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>

      <View style={{ flex: 1, alignItems: "center" }}>{rendering()}</View>
    </View>
  );
}

const Styles = StyleSheet.create({
  app: {
    backgroundColor: "#F1F2FB",
    height: "97vh",
    flex: 1,
  },

  header: {
    paddingTop: "0.7%",
    width: "100%",
    backgroundColor: "#0000AF",
    alignItems: "center",
  },
  firmTitle: {
    fontSize: "calc(10px + 3vmin)",

    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    margin: 10,
  },
  dateText: {
    position: "absolute",
    left: 10,
    fontSize: "max(14px ,2.5vmin)",
    textAlign: "left",
    color: "white",
    margin: 10,
  },
  taps: {
    width: "100%",
    maxWidth: "1500px",
    flexDirection: "row",
  },
  tap: {
    // backgroundColor: "red",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
  },
  tapText: {
    color: "white",
    fontSize: "max(2vmin, 15px)",
    padding: "1vmin",
    textAlign: "center",
  },
  footer: {
    paddingTop: "0.3%",
    paddingBottom: "0.3%",
    width: "100%",
    backgroundColor: "#0000AF",
    position: "absolute",
    bottom: 0,
    //backgroundColor: "#6E2BCD",
  },

  buttonView: {
    width: "20%",
    position: "absolute",
    right: 10,
    margin: "1vmin",
    padding: "8px",
    borderRadius: 10,
    color: "white",
    borderWidth: 1,
    borderColor: "white",
  },
  buttonText: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "max(2vmin, 16px)",
    color: "white",
  },
});

export default App;
