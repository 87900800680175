// import "./styles.css";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import {
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Keyboard,
  StyleSheet,
  ScrollView,
} from "react-native";
const data = [
  {
    name: "APR-2021",
    CASH: 4000,
    CREDIT: 2400,
    EXPENSE: 2400,
  },
  {
    name: "MAY-2021",
    CASH: 3000,
    CREDIT: 1398,
    EXPENSE: 2210,
  },
  {
    name: "JUN-2021",
    CASH: 2000,
    CREDIT: 9800,
    EXPENSE: 1500,
  },
  {
    name: "JUL-2021",
    CASH: 2780,
    CREDIT: 3908,
    EXPENSE: 2000,
  },
  {
    name: "AUG-2021",
    CASH: 1890,
    CREDIT: 4800,
    EXPENSE: 2181,
  },
  {
    name: "SEP-2021",
    CASH: 2390,
    CREDIT: 3800,
    EXPENSE: 2500,
  },
  {
    name: "OCT-2021",
    CASH: 3490,
    CREDIT: 4300,
    EXPENSE: 2100,
  },
];

export function Examplechart(props) {
  return (
    <View style={Styles.cardView}>
      <Text style={[Styles.cardTextTitle, props.title && props.title.fontStyle]}>
        {props.title.name}{" "}
      </Text>
      <AreaChart
        width={1000}
        height={450}
        data={data}
        margin={{
          top: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorCash" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorExpense" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FF8080" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#FD5353" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="CREDIT"
          stackId="2"
          stroke="#5100B8"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Area
          type="monotone"
          dataKey="CASH"
          stackId="2"
          stroke="green"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorCash)"
        />
        <Area
          type="monotone"
          dataKey="EXPENSE"
          stackId="1"
          stroke="red"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorExpense)"
        />

        {/* <Area type="monotone" dataKey="CREDIT" stackId="2" stroke="#5100B8" fill="#7E29EA" />
      <Area type="monotone" dataKey="CASH" stackId="2" stroke="green" fill="#CAF2CB" /> */}
        {/* <Area type="monotone" dataKey="EXPENSE" stackId="1" stroke="#FF0000" fill="#FE2A2A" /> */}
      </AreaChart>
    </View>
  );
}

const Styles = StyleSheet.create({
  cardView: {
    backgroundColor: "white",
    minWidth: 500,
    padding: 15,
    borderRadius: 5,
    margin: 5,
    alignSelf:'center'
  },
  cardTextTitle: {
    marginBottom: 4,
    marginTop: 4,
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "max(2.7vmin, 14px)",
  },
  cardText: {
    width: "60%",
    paddingLeft: 10,
    marginBottom: 2,
    marginTop: 2,
    fontWeight: "bold",
    fontSize: "max(2.2vmin, 12px)",
  },
  cardTextValue: {
    width: "40%",
    paddingLeft: 10,
    marginBottom: 2,
    marginTop: 2,
    fontWeight: "bold",
    fontSize: "max(2.2vmin, 12px)",
  },
});
