import { db } from "../Firebase/Main";
import moment from "moment";

export async function UserUpdateDB({ data, uid }) {
  let userRef = db.collection(`/userAccounts`).doc(uid);
  let avaiable = await userRef.get();
  if (avaiable.data()) {
    await userRef.set(data, { merge: true });
    return { suc: true, userNameExist: true };
  } else return { err: true, detail: "Invaluid UID" };
}

export async function UserAuth({ pwd, userName, timeStamp }) {
  console.log("docSnapshot");
  let docSnapshot = await db
    .collection(`/userAccounts`)
    .where("userName", "==", userName.toUpperCase())
    .where("pwd", "==", pwd)
    .get();

  if (docSnapshot.docs[0]) {
    let data = docSnapshot.docs[0].data();
    UserUpdateDB({
      data: {
        login: data.status == "active" ? true : false,
        lastLogin: data.status == "active" ? timeStamp : data.lastLogin,
      },
      uid: data.uid,
    });
    return { ...data, login: data.status == "active" ? true : false };
  }

  return { status: "unknown" };
}

export async function UserCreate({ pwd, userName, type, firmID, firmName, created, pin }) {
  let docSnapshot = await db
    .collection(`/userAccounts`)
    .where("userName", "==", userName.toUpperCase().trim())
    .get();
  let comapnyinforef = db.collection(firmID).doc("GeneralInfo");
  const companyInfo = await comapnyinforef.get();

  if (docSnapshot.docs[0] || Number(companyInfo.noOfUsers) > 98) {
    return { suc: false, userNameExist: true };
  } else {
    let userRef = db.collection(`/userAccounts`);

    let data = {
      name: userName,
      userName: userName.toUpperCase().trim(),
      firmName: firmName,
      pwd: pwd,
      pin: pin,
      login: false,
      status: "active",
      type: type,
      created: created || "unknown",
      firmID: firmID,
      invoiceSN: 1,
      uid:
        firmID.slice(0, 2) +
        "-" +
        ("0" + ((companyInfo.data() ? Number(companyInfo.data().noOfUsers) : 0) + 1)).slice(-2) +
        "-" +
        moment().format("YYYY-MM-DD-") +
        userName.toUpperCase().replace(" ", ""),
    };
    await userRef.doc(data.uid).set(data, { merge: true });
    await comapnyinforef.set(
      {
        noOfUsers: (companyInfo.data() ? Number(companyInfo.data().noOfUsers) : 0) + 1,
        id: firmID,
        name: firmName,
      },
      { merge: true }
    );
    return { suc: true, userNameExist: false };
  }
}

export async function GetUsersOfFirmID({ firmID }) {
  let listUsers = [];
  let docSnapshot = await db.collection(`/userAccounts`).where("firmID", "==", firmID).get();

  docSnapshot.docs.map((doc) => {
    let data = doc.data();
    listUsers.push(data);
    return;
  });
  return { userList: listUsers };
}

export async function UserDelete(data) {
  let userRef = db.collection(`/userAccounts`).doc(data.uid);
  await userRef.delete();
  let deltedref = db.collection(`/deletedUserAccounts`).doc(data.uid);
  await deltedref.set(data, { merge: true });
  return { suc: true, userNameExist: true };
}

export async function LoadOutstandingTotal({ type, firmID }) {
  let total = 0;
  const cusRecord = await db.collection(`/${firmID}/Stakeholders/` + type).get();

  cusRecord.docs.map((doc) => {
    let data = doc.data();

    total = data.outstanding ? Number(data.outstanding) + total : total;
    return;
  });

  return { total: total };
}

export async function LoadReceiptFrom(dates, firmID) {
  var docSnapshot = null;
  let output = {
    InitialCashBalance: 0,

    cash: {
      total: 0,
      payMethord: {},
      advance: 0,
      customers: {},
    },

    ["A/C"]: {
      total: 0,
      advance: {},
      outstanding: 0,
      customers: {},
    },

    salesItems: {},

    purchase: {
      total: 0,
      advance: {
        total: 0,
        cash: 0,
        bankDeposit: 0,
        checkDeposit: 0,
        other: 0,
      },
      outstanding: 0,
      suppliers: {},
    },

    stockReductionValues: {
      total: 0,
      items: {},
      advance: 0,
    },

    paid: {
      Customer: {
        total: 0,
        payMethord: {},
        nameList: {},
      },
      Supplier: {
        total: 0,
        payMethord: {},
        nameList: {},
      },
    },
    return: {
      total: 0,
      items: {},
    },
    bank: {
      total: 0,
      items: {},
    },
    expense: {
      total: 0,
      payMethord: {},
      supTypes: {},
      DamageList: {},
      list: {},
    },
  };
  if (dates.endDate)
    docSnapshot = await db
      .collection(`/${firmID}/Receipts/AllReceipts/`)
      .orderBy("timeStamp", "asc")
      .startAt(dates.startDate)
      .endAt(dates.endDate)
      .get();
  else
    docSnapshot = await db
      .collection(`/${firmID}/Receipts/AllReceipts/`)
      .orderBy("timeStamp", "asc")
      .startAt(dates.startDate)
      .get();
  docSnapshot.docs.map((doc) => {
    let data = doc.data();

    switch (data.type.split("_")[0]) {
      case "InitialCashBalance":
        output.InitialCashBalance = output.InitialCashBalance + data.total;

        return;
      case "Purchase":
        output.purchase.total = output.purchase.total + data.total;
        output.purchase.advance = {
          ...output.purchase.advance,
          [data.payMethod]: (output.purchase.advance[data.payMethod] || 0) + data.payAmount,
        };
        output.purchase.outstanding = output.purchase.outstanding + data.balance;

        output.purchase.suppliers[data.nameID + "_" + data.name] =
          (output.purchase.suppliers[data.nameID + "_" + data.name] || 0) + data.total;
        return;

      case "Sales":
        output.stockReductionValues.total =
          output.stockReductionValues.total + (Number(data.stockValueReduction) || 0);

        Object.values(data.itemList).map((item) => {
          output.stockReductionValues.items = {
            ...output.stockReductionValues.items,
            [item.label]:
              (output.stockReductionValues.items[item.label] || 0) +
              item.quantity * item.purchaseAvg,
          };
          output.salesItems = {
            ...output.salesItems,
            [item.label]: (output.salesItems[item.label] || 0) + item.quantity * item.unitPrice,
          };
        });
        if (data.balance) {
          output["A/C"].total = output["A/C"].total + data.total;
          output["A/C"].advance = {
            ...output["A/C"].advance,
            [data.payMethod]: (output["A/C"].advance[data.payMethod] || 0) + data.payAmount,
          };

          output["A/C"].customers[data.nameID + "_" + data.name] =
            (output["A/C"].customers[data.nameID + "_" + data.name] || 0) + data.total;
          output["A/C"].outstanding = output["A/C"].outstanding + data.balance;
        } else {
          output.cash.total = output.cash.total + data.total;
          output.cash.payMethord = {
            ...output.cash.payMethord,
            [data.payMethod]: (output.cash.payMethord[data.payMethod] || 0) + data.payAmount,
          };

          output.cash.customers[data.nameID + "_" + data.name] =
            (output.cash.customers[data.nameID + "_" + data.name] || 0) + data.total;
        }
        return;

      case "Return":
        output.return.total = output["return"].total + data.total;
        Object.values(data.itemList).map((item) => {
          output.return.items[item.label] = (output.return.items[item.label] || 0) + item.quantity;
        });
        return;

      case "Bank":
        output.bank.total = output["bank"].total + data.total;
        return;

      case "Paid":
        output.paid[data.supType] = {
          total: (output.paid[data.supType].total || 0) + Number(data.total),

          payMethord: {
            ...output.paid[data.supType].payMethord,
            [data.payMethod]:
              (output.paid[data.supType].payMethord[data.payMethod] || 0) + Number(data.total),
          },
          nameList: {
            ...output.paid[data.supType].nameList,
            [data.nameID + "_" + data.name]:
              (output.paid[data.supType].nameList[data.nameID + "_" + data.name] || 0) +
              Number(data.total),
          },
        };
        return;
      case "Expense":
        output.expense.total = (output.expense.total || 0) + Number(data.total);

        output.expense.payMethord[data.payMethod] =
          (output.expense.payMethord[data.payMethod] || 0) + Number(data.total);

        output.expense.supTypes[data.supType] =
          (output.expense.supTypes[data.supType] || 0) + Number(data.total);

        output.expense.list[data.name] = (output.expense.list[data.name] || 0) + Number(data.total);

        return;
    }

    return;
  });
  return output;
}
