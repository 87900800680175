import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  Pressable,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Keyboard,
  StyleSheet,
  ScrollView,
} from "react-native";
import moment, { max, min } from "moment";
import { ThousandView, CapitalizeWords, FormatingReceipt } from "../../Functions/Functions";
import { useSelector, useDispatch } from "react-redux";

import Select from "react-select"; //https://medium.com/@lahiru0561/react-select-with-custom-label-and-custom-search-122bfe06b6d7
import { width } from "dom-helpers";

import ContentView from "../../Componets/Other/Content";
import { PincodeVerfication } from "../../Componets/Modals/Modals";
import {
  GetTodayBalances,
  GetSalesOutstanding,
  GetThisMonthBalances,
  GetPurchaseOutstanding,
  GetLastMonthBalances,
} from "../../Functions/ApiFunctions";
import { Examplechart } from "./Charts";
import PieChart from "./PieChart";

export default function App(props) {
  const extraTapIndex = useSelector((state) => state.data.extraTapIndex);
  const preInfo = useSelector((state) => state.data.preInfo);
  const userData = useSelector((state) => state.data.userData);

  //const roott = useSelector((state) => state.data.productTree) //the tree

  const [todayBal, setTodayBal] = useState();
  const [thisMonthBal, setThisMonthBal] = useState();
  const [prevMonthBal, setPrevMonthBal] = useState();
  const [payReceived, setPayReceived] = useState();
  const [paidPurchase, setPaidPurchase] = useState();

  const [showtype, setShowType] = useState("Sales");
  const [pwdVerification, setPwdVerification] = useState(true);

  // useEffect(() => {
  //   LoadingBalances();
  //   return;
  // }, []);

  function LoadingBalances() {
    GetTodayBalances({ firmID: userData.firmID }).then((data) => setTodayBal(data));

    GetSalesOutstanding({ firmID: userData.firmID }).then((data) => setPayReceived(data));
    GetPurchaseOutstanding({ firmID: userData.firmID }).then((data) => setPaidPurchase(data));

    GetThisMonthBalances({ firmID: userData.firmID }).then((data) => setThisMonthBal(data));

    GetLastMonthBalances({ firmID: userData.firmID }).then((data) => setPrevMonthBal(data));
  }

  const todayEarn = todayBal
    ? todayBal.cash.total +
      todayBal["A/C"].total -
      (todayBal["expense"].total + todayBal["stockReductionValues"].total)
    : 0; //todayBal ? todayBal.Cash + todayBal["A/C"] - todayBal["Expense"] : 0; //cashFlow.initialBalance+cashFlow.cashSales+cashFlow.cashPaymentReceived-cashFlow.expenses

  const monthEarn = thisMonthBal
    ? thisMonthBal.cash.total +
      thisMonthBal["A/C"].total -
      (thisMonthBal["expense"].total + thisMonthBal["stockReductionValues"].total)
    : 0;

  const cashbalance = todayBal
    ? todayBal.cash.total +
      (todayBal["A/C"].advance.Cash || 0) +
      (todayBal.paid.Customer.payMethord.Cash || 0) -
      ((todayBal.paid.Supplier.payMethord.Cash || 0) +
        (todayBal["expense"].payMethord.Cash || 0) +
        (todayBal.bank.total || 0))
    : 0;

  const thisMonthCashBalance = thisMonthBal
    ? thisMonthBal.cash.total +
      (thisMonthBal["A/C"].advance.Cash || 0) +
      (thisMonthBal.paid.Customer.payMethord.Cash || 0) -
      ((thisMonthBal.paid.Supplier.payMethord.Cash || 0) +
        (thisMonthBal["expense"].payMethord.Cash || 0) +
        (thisMonthBal.bank.total || 0))
    : 0;

  const prevMonthEarn = prevMonthBal
    ? prevMonthBal.cash + prevMonthBal["A/C"] - prevMonthBal["Expense"]
    : 0;

  return (
    <>
      {pwdVerification && (
        <PincodeVerfication
          close={() => setPwdVerification(false)}
          onVerified={LoadingBalances}
          onFaild={() => {
            setPwdVerification(false);
            props.backAction();
          }}
        />
      )}
      <ScrollView
        style={{ width: "100%", zIndex: -1, maxHeight: "83vh" }}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ alignItems: "center" }}>
          <Text style={Styles.titleText}>TRADE SUMMARY</Text>
          <View style={{ flexDirection: "row" }}>
            <ContentView
              style={{ marginTop: 10 }}
              title={{
                name: moment().format("dddd") + " (TODAY) ...",
                fontStyle: { color: "#2F2EE1", marginLeft: 0, textAlign: "center" },
              }}
              items={[
                {
                  name: "Cash Sales",
                  value: todayBal
                    ? ": " + ThousandView(todayBal.cash.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Credit Sales",
                  value: todayBal
                    ? ": " + ThousandView(todayBal["A/C"].total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },

                {
                  name: "TOTAL SALES",
                  value: todayBal
                    ? ": " + ThousandView(todayBal["A/C"].total + todayBal.cash.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: {
                    marginTop: 5,
                    color: "black",
                    paddingTop: 5,
                    marginBottom: 10,
                    borderTopWidth: 2,
                    borderColor: "black",
                  },
                },

                {
                  name: "Sold Items",
                  value: todayBal
                    ? ": (" + ThousandView(todayBal["stockReductionValues"].total) + ".00)"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Expense",
                  value: todayBal
                    ? ": (" + ThousandView(todayBal["expense"].total) + ".00)"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "SALSES PROFIT",
                  value: ": " + ThousandView(todayEarn) + ".00",
                  fontStyle: {
                    marginTop: 5,
                    color: todayEarn > 0 ? "green" : "#E40303",
                    fontSize: "max(20px, 2.8vmin)",

                    paddingTop: 5,
                    paddingBottom: 5,
                    marginBottom: 5,
                    borderTopWidth: 2,
                    borderBottomWidth: 2,
                    borderColor: "red",
                  },
                },
                {
                  name: "",
                  value: "",
                  fontStyle: {
                    color: "black",
                    marginTop: 0,
                    marginBottom: 5,
                    borderTopWidth: 2,
                    paddingTop: 10,
                    borderColor: "red",
                  },
                },
              ]}
            />
            {/* <ContentView
              style={{ marginTop: 10 }}
              title={{
                name: "TODAY ...",
              }}
              items={[
                {
                  name: "Cash Sales",
                  value: todayBal
                    ? ": " + ThousandView(todayBal.Cash) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Credit Sales",
                  value: todayBal
                    ? ": " + ThousandView(todayBal["A/C"]) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },

                {
                  name: "Expense",
                  value: todayBal
                    ? ": " + ThousandView(todayBal["Expense"]) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "THE EARNING",
                  value: ": " + ThousandView(todayEarn) + ".00",
                  fontStyle: {
                    marginTop: 5,
                    paddingTop: 5,
                    marginBottom: 10,
                    borderTopWidth: 2,
                    borderColor: "red",
                  },
                },
                {
                  name: "Total Return",
                  value: todayBal
                    ? ": " + ThousandView(todayBal.Return) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Payment Received",
                  value: todayBal
                    ? ": " + ThousandView(todayBal["Paid_A/C_Paid"]) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },
              ]}
            /> */}
            <View style={{ width: "5%" }} />
            <ContentView
              style={{ marginTop: 10 }}
              title={{
                name: moment().format("MMMM") + " (THIS) MONTH",
                fontStyle: { color: "#2F2EE1", marginLeft: 0, textAlign: "center" },
              }}
              items={[
                {
                  name: "Cash Sales",
                  value: thisMonthBal
                    ? ": " + ThousandView(thisMonthBal.cash.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Credit Sales",
                  value: thisMonthBal
                    ? ": " + ThousandView(thisMonthBal["A/C"].total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },

                {
                  name: "TOTAL SALES",
                  value: thisMonthBal
                    ? ": " +
                      ThousandView(thisMonthBal["A/C"].total + thisMonthBal.cash.total) +
                      ".00"
                    : ": " + "Loading...",
                  fontStyle: {
                    marginTop: 5,
                    color: "black",
                    paddingTop: 5,
                    marginBottom: 10,
                    borderTopWidth: 2,
                    borderColor: "black",
                  },
                },

                {
                  name: "Sold Items",
                  value: thisMonthBal
                    ? ": (" + ThousandView(thisMonthBal["stockReductionValues"].total) + ".00)"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Expense",
                  value: thisMonthBal
                    ? ": (" + ThousandView(thisMonthBal["expense"].total) + ".00)"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "SALES PROFIT",
                  value: ": " + ThousandView(monthEarn) + ".00",
                  fontStyle: {
                    marginTop: 5,
                    fontSize: "max(20px, 2.7vmin)",
                    color: monthEarn > 0 ? "green" : "#E40303",
                    paddingTop: 5,
                    paddingBottom: 5,
                    marginBottom: 5,
                    borderTopWidth: 2,
                    borderBottomWidth: 2,
                    borderColor: "red",
                  },
                },
                {
                  name: "",
                  value: "",
                  fontStyle: {
                    color: "black",
                    marginTop: 0,
                    marginBottom: 5,
                    borderTopWidth: 2,
                    paddingTop: 10,
                    borderColor: "red",
                  },
                },
              ]}
            />
          </View>
          <View style={{ flexDirection: "row", marginTop: "1%" }}>
            <ContentView
              style={{ marginTop: 10 }}
              title={{
                name: "ALERT !",
              }}
              items={[
                {
                  name: "TOTAL OUTSTANDING",
                  value: payReceived
                    ? ": " + ThousandView(payReceived.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "red", marginTop: 5, marginBottom: 5, fontSize: "2.5vmin" },
                },
                {
                  name: "TODAY BANK DEPOSIT",
                  value: todayBal
                    ? ": " + ThousandView(todayBal.bank.total || 0) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },
                // {
                //   name: "TODAY",
                //   value: "",
                //   fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                // },
                // {
                //   name: "Added Outstanding",
                //   value: todayBal
                //     ? ": " + ThousandView(todayBal["A/C"].outstanding) + ".00"
                //     : ": " + "Loading...",
                //   fontStyle: { color: "black", marginTop: 5, marginBottom: 5, marginLeft: 4 },
                // },
                // {
                //   name: "Payment Received",
                //   value: todayBal
                //     ? ": " + ThousandView(todayBal.paid.Customer.total) + ".00"
                //     : ": " + "Loading...",
                //   fontStyle: { color: "green", marginTop: 5, marginBottom: 5, marginLeft: 4 },
                // },
                // {
                //   name: moment().format("MMMM").toUpperCase() + " (THIS) MONTH",
                //   value: "",
                //   fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                // },
                // {
                //   name: "Added Outstanding",
                //   value: thisMonthBal
                //     ? ": " + ThousandView(thisMonthBal["A/C"].outstanding) + ".00"
                //     : ": " + "Loading...",
                //   fontStyle: { color: "black", marginTop: 5, marginBottom: 5, marginLeft: 4 },
                // },
                // {
                //   name: "Payment Received",
                //   value: thisMonthBal
                //     ? ": " + ThousandView(thisMonthBal.paid.Customer.total) + ".00"
                //     : ": " + "Loading...",
                //   fontStyle: { color: "green", marginTop: 5, marginBottom: 5, marginLeft: 4 },
                // },
              ]}
            />
            <View style={{ width: "5%" }} />
          </View>
          <Text style={[Styles.titleText, { marginTop: "3%" }]}>ANALYTICS PERFORMANCE</Text>

          {payReceived && (
            <Examplechart
              title={{
                name: "YOUR BUSINESS GLANCE",
                fontStyle: {
                  color: "#2F2EE1",
                  marginLeft: 0,
                  textAlign: "center",
                  borderBottomWidth: 1,
                  borderColor: "#888888",
                },
              }}
            />
          )}
          <View style={{ flexDirection: "row", marginTop: "3%" }}>
            <PieChart
              title={{
                name: "MOST SELLING PRODUCTS",
                fontStyle: { color: "#2F2EE1", marginLeft: 0, textAlign: "center" },
              }}
            />
            <PieChart
              title={{
                name: "MOST PROFITABLE PRODUCTS",
                fontStyle: { color: "green", marginLeft: 0, textAlign: "center" },
              }}
            />
          </View>
          <Text style={[Styles.titleText, { marginTop: "3%" }]}>CASH FLOW SUMMARY</Text>
          <View style={{ flexDirection: "row", marginTop: "1%" }}>
            <ContentView
              style={{ marginTop: 0 }}
              title={{
                name: "Today Cash Flow",
                fontStyle: { color: "#2F2EE1", marginLeft: 0, textAlign: "center" },
              }}
              items={[
                {
                  name: "Cash Sales",
                  value: todayBal
                    ? ": " + ThousandView(todayBal.cash.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Advance by Cash",
                  value: todayBal
                    ? ": " + ThousandView(todayBal["A/C"].advance.Cash || 0) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },

                {
                  name: "Payment Received Cash",
                  value: todayBal
                    ? ": " + ThousandView(todayBal.paid.Customer.payMethord.Cash || 0) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },

                {
                  name: "Cash Flow In",
                  value: todayBal
                    ? ": " +
                      ThousandView(
                        todayBal.cash.total +
                          (todayBal["A/C"].advance.Cash || 0) +
                          (todayBal.paid.Customer.payMethord.Cash || 0)
                      ) +
                      ".00"
                    : ": " + "Loading...",
                  fontStyle: {
                    marginTop: 5,
                    color: "black",
                    paddingTop: 5,
                    marginBottom: 10,
                    borderTopWidth: 2,
                    borderColor: "black",
                  },
                },

                {
                  name: "Paid for Purchase",
                  value: todayBal
                    ? ": (" + ThousandView(todayBal.paid.Supplier.payMethord.Cash || 0) + ".00)"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Expense by Cash",
                  value: todayBal
                    ? ": (" + ThousandView(todayBal["expense"].payMethord.Cash || 0) + ".00)"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Bank Deposited",
                  value: todayBal
                    ? ": (" + ThousandView(todayBal.bank.total || 0) + ".00)"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Cash Balance",
                  value: ": " + ThousandView(cashbalance) + ".00",
                  fontStyle: {
                    marginTop: 5,
                    fontSize: "max(20px, 2.7vmin)",
                    color: monthEarn > 0 ? "green" : "#E40303",
                    paddingTop: 5,
                    paddingBottom: 5,
                    marginBottom: 5,
                    borderTopWidth: 2,
                    borderBottomWidth: 2,
                    borderColor: "red",
                  },
                },
                {
                  name: "",
                  value: "",
                  fontStyle: {
                    color: "black",
                    marginTop: 0,
                    marginBottom: 5,
                    borderTopWidth: 2,
                    paddingTop: 10,
                    borderColor: "red",
                  },
                },
              ]}
            />
            <View style={{ width: "5%" }} />
            <ContentView
              style={{ marginTop: 0 }}
              title={{
                name: "This Month",
                fontStyle: { color: "#2F2EE1", marginLeft: 0, textAlign: "center" },
              }}
              items={[
                {
                  name: "Cash Sales",
                  value: thisMonthBal
                    ? ": " + ThousandView(thisMonthBal.cash.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Advance by Cash",
                  value: thisMonthBal
                    ? ": " + ThousandView(thisMonthBal["A/C"].advance.Cash || 0) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },

                {
                  name: "Payment Received Cash",
                  value: thisMonthBal
                    ? ": " + ThousandView(thisMonthBal.paid.Customer.payMethord.Cash || 0) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },

                {
                  name: "Cash Flow In",
                  value: thisMonthBal
                    ? ": " +
                      ThousandView(
                        thisMonthBal.cash.total +
                          (thisMonthBal["A/C"].advance.Cash || 0) +
                          (thisMonthBal.paid.Customer.payMethord.Cash || 0)
                      ) +
                      ".00"
                    : ": " + "Loading...",
                  fontStyle: {
                    marginTop: 5,
                    color: "black",
                    paddingTop: 5,
                    marginBottom: 10,
                    borderTopWidth: 2,
                    borderColor: "black",
                  },
                },

                {
                  name: "Paid for Purchase",
                  value: thisMonthBal
                    ? ": (" + ThousandView(thisMonthBal.paid.Supplier.payMethord.Cash || 0) + ".00)"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Expense by Cash",
                  value: thisMonthBal
                    ? ": (" + ThousandView(thisMonthBal["expense"].payMethord.Cash || 0) + ".00)"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Bank Deposited",
                  value: thisMonthBal
                    ? ": (" + ThousandView(thisMonthBal.bank.total || 0) + ".00)"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },
              ]}
            />
          </View>

          <Text style={[Styles.titleText, { marginTop: "3%" }]}>PURCHASE PERFORMANCE</Text>
          <View style={{ flexDirection: "row" }}>
            <ContentView
              style={{ marginTop: 10 }}
              title={{
                name: moment().format("dddd") + " (TODAY) ...",
              }}
              items={[
                {
                  name: "Total Purchase",
                  value: todayBal
                    ? ": " + ThousandView(todayBal.purchase.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Total Paid Debits",
                  value: todayBal
                    ? ": " + ThousandView(todayBal.paid.Supplier.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },

                {
                  name: "OUTSTANDING \nDEBIT",
                  value: todayBal
                    ? ": " + ThousandView(todayBal.purchase.outstanding) + ".00"
                    : ": " + "Loading...",
                  fontStyle: {
                    marginTop: 5,
                    paddingTop: 5,
                    marginBottom: 10,
                    borderTopWidth: 2,
                    borderColor: "red",
                  },
                },
              ]}
            />
            <View style={{ width: "5%" }} />
            <ContentView
              style={{ marginTop: 10 }}
              title={{
                name: moment().format("MMMM") + " (THIS) MONTH",
                fontStyle: { color: "#2F2EE1" },
              }}
              items={[
                {
                  name: "Total Purchase",
                  value: thisMonthBal
                    ? ": " + ThousandView(thisMonthBal.purchase.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Total Paid Debits",
                  value: thisMonthBal
                    ? ": " + ThousandView(thisMonthBal.paid.Supplier.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },

                {
                  name: "OUTSTANDING \nDEBIT",
                  value: thisMonthBal
                    ? ": " + ThousandView(thisMonthBal.purchase.outstanding) + ".00"
                    : ": " + "Loading...",
                  fontStyle: {
                    marginTop: 5,
                    color: "#2F2EE1",
                    paddingTop: 5,
                    marginBottom: 10,
                    borderTopWidth: 2,
                    borderColor: "red",
                  },
                },
              ]}
            />
          </View>
          <View style={{ flexDirection: "row", marginTop: "1%" }}>
            <ContentView
              style={{ marginTop: 10 }}
              title={{
                name: "DEBIT ALERT !",
                fontStyle: { fontSize: "3vmin" },
              }}
              items={[
                {
                  name: "TOTAL OUTSTANDING DEBIT",
                  value: paidPurchase
                    ? ": " + ThousandView(paidPurchase.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "red", marginTop: 5, marginBottom: 5 },
                },
              ]}
            />
            <View style={{ width: "5%" }} />
            <ContentView
              style={{ marginTop: 10 }}
              title={{
                name: moment().subtract(30, "days").format("MMMM") + " (LAST) MONTH",
                fontStyle: { color: "#5300B4" },
              }}
              items={[
                {
                  name: "Total Purchase",
                  value: prevMonthBal
                    ? ": " + ThousandView(prevMonthBal.purchase.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "green", marginTop: 5, marginBottom: 5 },
                },
                {
                  name: "Total Paid Debits",
                  value: prevMonthBal
                    ? ": " + ThousandView(prevMonthBal.paid.Supplier.total) + ".00"
                    : ": " + "Loading...",
                  fontStyle: { color: "black", marginTop: 5, marginBottom: 5 },
                },

                {
                  name: "OUTSTANDING \nDEBIT",
                  value: prevMonthBal
                    ? ": " + ThousandView(prevMonthBal.purchase.outstanding) + ".00"
                    : ": " + "Loading...",
                  fontStyle: {
                    marginTop: 5,
                    color: "#2F2EE1",
                    paddingTop: 5,
                    marginBottom: 10,
                    borderTopWidth: 2,
                    borderColor: "red",
                  },
                },
              ]}
            />
          </View>
        </View>
      </ScrollView>
    </>
  );
}

const Styles = StyleSheet.create({
  titleText: {
    color: "#2F2EE1",
    borderColor: "#2F2EE1",
    borderBottomWidth: 2,
    marginLeft: -400,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontWeight: "bold",
    fontSize: "max(14px, 4vmin)",
    marginTop: "1%",
    marginBottom: "1%",
  },
});
